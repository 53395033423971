import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Remove } from '@mui/icons-material';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber } from 'components';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { enterpriseService, enterpriseSetupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { TrialConditionCreateType } from 'types/EnterpriseSetup';
import { formatNumber } from 'utils/common';

type PopupProps = PopupController & {
  id: number;
};

const BusinessTrialCondition = ({ id, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { data: proposal } = useQuery(['enterpriseService.getEnterpriseProposal', { id }], () =>
    enterpriseService.getEnterpriseProposal({ _id: '' + id }),
  );

  const { mutate: createTrialCondition, isLoading: isLoadingApprove } = useMutation(
    enterpriseSetupService.createTrialCondition,
    {
      onSuccess: (data) => {
        enqueueSnackbar(t`Successful`);
        onClose();
        queryClient.invalidateQueries('enterpriseSetupService.fetchConditionSetting');
        queryClient.invalidateQueries('enterpriseSetupService.getConditionLastest');
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = TrialConditionCreateType & { expireDate: DateTime };
      const { expireDate, ...others } = values as ValuesType;

      createTrialCondition({
        ...others,
        id,
        expireDate: expireDate.toISO(),
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Setup Trial Conditions For Enterprise</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={4}>
          <Grid item md={6}>
            <Typography variant='h4'>
              <Trans>Proposal Content</Trans>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='w-20'>
                      <Trans>No.</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Content</Trans>
                    </TableCell>
                    <TableCell className='max-w-xs'>
                      <Trans>CRM Department's Proposal</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <Trans>Total Enterprise Limit (VND)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>{formatNumber(proposal?.totalLimit)}</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>
                      <Trans>Advance Term (Day)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>
                        {proposal?.advanceTermFrom} - {proposal?.advanceTermTo}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>
                      <Trans>Advance Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>
                        {formatNumber(proposal?.advanceAmountFrom)} - {formatNumber(proposal?.advanceAmountTo)}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>4</TableCell>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>{proposal?.earnedDiscountRate}%</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>5</TableCell>
                    <TableCell>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell className='text-center'>
                      <div className='px-3 py-2'>{proposal?.futureDiscountRate}%</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item md={6}>
            <Typography variant='h4'>
              <Trans>Approval Decision of CA Department</Trans>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className='w-10'>
                      <Trans>No</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Content</Trans>
                    </TableCell>
                    <TableCell className='w-64'>
                      <Trans>Approval Decision</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className='h-12'>
                    <TableCell colSpan={3} className='font-bold'>
                      <Trans>Advance Conditions</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <Trans>Total Enterprise Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='totalLimit'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>
                      <Trans>Expire Date</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='expireDate'
                        defaultValue={DateTime.now()}
                        control={control}
                        rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            disablePast={true}
                            value={value}
                            onChange={onChange}
                            renderInput={(props) => <TextField {...props} error={invalid} />}
                            inputFormat='dd/MM/yyyy'
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>3</TableCell>
                    <TableCell>
                      <Trans>Advance Term (Day)</Trans>
                    </TableCell>
                    <TableCell className='flex items-center gap-2 -ml-20'>
                      <Controller
                        name='minAdvanceTerm'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { className: 'text-right' },
                            }}
                          />
                        )}
                      />
                      <Remove fontSize='small' />
                      <Controller
                        name='maxAdvanceTerm'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { className: 'text-right' },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>4</TableCell>
                    <TableCell>
                      <Trans>Advance Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className='flex items-center gap-2 -ml-20'>
                      <Controller
                        name='minAdvanceAmount'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                      <Remove fontSize='small' />
                      <Controller
                        name='maxAdvanceAmount'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>5</TableCell>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='earnedDiscountRate'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: {
                                maxLength: 5,
                                className: 'text-right',
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>6</TableCell>
                    <TableCell>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='futureDiscountRate'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: {
                                maxLength: 5,
                                className: 'text-right',
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>7</TableCell>
                    <TableCell>
                      <Trans>Promotion</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='promotion'
                        defaultValue=''
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => <TextField {...field} />}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow className='h-12'>
                    <TableCell colSpan={3} className='font-bold'>
                      <Trans>Employee Conditions</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>
                      <Trans>Labor Contract eligible for future advance</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='laborContract'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => <TextField {...field} error={invalid} />}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>
                      <Trans>Other Proposal</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='otherProposal'
                        defaultValue=''
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => <TextField {...field} />}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={onClose} className='w-28'>
          <Trans>Close</Trans>
        </LoadingButton>

        <LoadingButton variant='contained' className='w-28' loading={isLoadingApprove} onClick={handleClickSubmit}>
          <Trans>Request</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BusinessTrialCondition;
