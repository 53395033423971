import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { formatNumber } from 'utils/common';
import { Tooltip, ResponsiveContainer, Cell, Pie, PieChart } from 'recharts';
import { t } from '@lingui/macro';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { TableEmpty } from 'components';
import { Trans } from '@lingui/macro';

type TopBusinessProps = {
  type: string;
};

const TopBusiness = ({ type }: TopBusinessProps) => {
  const { data } = useQuery(
    ['enterpriseService.getTopClientTrans', { type, size: 3 }],
    () => enterpriseService.getTopClientTrans({ type, size: 3 }),
  );
  const items = data?.clients ?? [];

  const { data: onboard } = useQuery(
    ['enterpriseService.getClientOnboard', { type }],
    () => enterpriseService.getClientOnboard({ type }),
  );

  const pices = [
    { name: t`Đã Gia nhập`, value: onboard?.join },
    { name: t`Chưa Gia nhập`, value: onboard?.wait },
  ];

  return (
    <Grid container columnSpacing={2} rowSpacing={3}>
      <Grid item sm={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {items?.map((item, index) => (
                <TableRow key={item.id}>
                  {index === 0 ? (<TableCell className='font-bold text-red-600'>TOP {index + 1}</TableCell>) : (<TableCell className='font-bold'>TOP {index + 1}</TableCell>)}
                  <TableCell>{item.name}</TableCell>
                  <TableCell align='right'>{formatNumber(item.requestAmount)} (VND)</TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={12} className='text-center'>
        <Paper elevation={0} className='w-full' style={{ height: '250px' }}>
          <ResponsiveContainer>
            <PieChart width={600} height={250}>
              <Pie
                isAnimationActive={false}
                data={pices}
                innerRadius={60}
                outerRadius={100}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
                label
              >
                {type === 'ENTERPRISE' ? (<Cell key='join' fill="#25bec3" />) : (<Cell key='join' fill="#354155" />)}
                <Cell key='wait' fill="#a6a6a6" />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
      <Grid container className='text-center ml-2 mt-2'>
        <Grid item sm={6} textAlign='right'>
          {type === 'ENTERPRISE' ?
            (<Button style={{ backgroundColor: '#25bec3', minWidth: '10px' }} />)
            : (<Button style={{ backgroundColor: '#354155', minWidth: '10px' }} />)}  <Trans>Đã Gia nhập</Trans>
        </Grid>
        <Grid item sm={6} textAlign='left'>
          <Button className='mb-1 ml-5' style={{ backgroundColor: '#a6a6a6', minWidth: '10px' }} /> <Trans>Chưa Gia nhập</Trans>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TopBusiness;
