import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

import { useStyles } from './TransInfo';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Paper,
} from '@mui/material';
import { TransType, TransUpdateStatusType } from 'types/Transaction';
import { formatNumber } from 'utils/common';
import { useMutation } from 'react-query';
import { transactionService } from 'services';
import { usePermission } from 'hooks';
import {
  PayingStatus,
  PaidStatus,
  RejectedStatus,
  WaitSettlementStatus,
  DocTypePaymentSlip,
} from 'constants/transaction';
import { PaymentSlipPopup } from 'views/Transaction/TransView/popups';

export const useBankStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '13%',
  },
  cellValue: {
    width: '20%',
  },
});
const TransPayment = ({ transaction }: { transaction?: TransType }) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenReject, setOpenReject] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const classes = useStyles();
  const bankClasses = useBankStyles();
  const { isCaRoles, requreUserPermission, TRANSACTION_DUYETGIAODICH,TRANSACTION_CHUNGTUTHANHTOAN } = usePermission();
  const { mutate: moveTransStatus, isLoading } = useMutation(transactionService.moveStatus, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      setOpenReject(false);
      window.location.href = '/transaction-management/paying'
    },
  });

  const handleClickMoveStatus = (status: string) => {
    moveTransStatus({
      id: transaction?.id!,
      status: status,
    });
  };

  const handleClickReject = () => {
    handleSubmit((values: TransUpdateStatusType) => {
      moveTransStatus({
        ...values,
        status: RejectedStatus,
        id: transaction?.id!,
      });
    })();
  };

  function renderPayment() {
    if (transaction?.status === PayingStatus) {
      return (
        <div className='flex justify-end'>
          <LoadingButton
            variant='outlined'
            className='w-50 mr-4'
            loading={isLoading}
            onClick={() => setOpenReject(true)}
          >
            <Trans>Reject Payment</Trans>
          </LoadingButton>
          <LoadingButton
            variant='contained'
            className='w-50'
            loading={isLoading}
            onClick={() => handleClickMoveStatus(PaidStatus)}
          >
            <Trans>Make Transaction Payment</Trans>
          </LoadingButton>
        </div>
      );
    }
    if (transaction?.status === PaidStatus && isCaRoles() && requreUserPermission([TRANSACTION_DUYETGIAODICH])) {
      return (
        <div className='flex justify-end'>
          <LoadingButton
            variant='contained'
            className='w-50'
            loading={isLoading}
            onClick={() => handleClickMoveStatus(WaitSettlementStatus)}
          >
            <Trans>Approve Transaction</Trans>
          </LoadingButton>
        </div>
      );
    }
    return null;
  }

  return (
    <div className='mt-6'>
      <div className='flex items-center space-x-3'>
        <Typography variant='h4'>
          <Trans>Transaction Payment</Trans>
        </Typography>
        { requreUserPermission([TRANSACTION_CHUNGTUTHANHTOAN]) && 
          <Button variant='contained' onClick={() => setOpen(true)}>
            <Trans>Payment Slip</Trans>
          </Button>
        }
      </div>
      <TableContainer component={Paper}>
        <Table className={bankClasses.table}>
          <TableBody>
            <TableRow>
              <TableCell className={bankClasses.cellTitle}>
                <Trans>Bank Name</Trans>
              </TableCell>
              <TableCell className={bankClasses.cellValue}>{transaction?.bank?.name}</TableCell>
              <TableCell className={bankClasses.cellTitle}>
                <Trans>Branch Name</Trans>
              </TableCell>
              <TableCell className={bankClasses.cellValue}>{transaction?.bankBranch?.name}</TableCell>
              <TableCell className={bankClasses.cellTitle}>
                <Trans>Account Number</Trans>
              </TableCell>
              <TableCell className={bankClasses.cellValue}>{transaction?.bankAccountNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Payment Amount (VND)</Trans>
              </TableCell>
              <TableCell>{formatNumber(transaction?.paymentAmount)} VND</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {renderPayment()}
      <Dialog fullWidth maxWidth='md' open={isOpen} onClose={() => setOpen(false)}>
        <PaymentSlipPopup docType={DocTypePaymentSlip} transaction={transaction!} onClose={() => setOpen(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <DialogTitle>
          <Trans>Reject Transaction Revision</Trans>
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item sm={12}>
              <Controller
                name='reason'
                defaultValue=''
                control={control}
                rules={{
                  required: t`Field is required`,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    multiline
                    minRows={2}
                    label={t`Reject Reason`}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant='outlined' onClick={() => setOpenReject(false)}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' onClick={handleClickReject} loading={isLoading}>
            <Trans>Reject</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TransPayment;
