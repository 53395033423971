import { Trans } from '@lingui/macro';
import { Container, Typography, Button, Grid, Dialog } from '@mui/material';
import { StaticLayout } from 'layouts';
import ContactPopup from './ContactPopup';
import { Fragment, useState, useEffect } from 'react';

const Investment = () => {
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  function submitContact() {
    setOpenSelectBank(true)
  }
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <StaticLayout>
      {/* <Container className='py-20'>
        <Typography className='font-black text-[3rem]'><Trans>Investment</Trans></Typography>
      </Container> */}
      <div style={{ backgroundColor: '#DFEDED' }}>
        <Container style={{ paddingTop: "5rem" }}>
          <div style={{ display: 'flex' }}>
            <div data-aos="fade-right" style={{ width: '45%' }}>
              <div style={{ fontSize: 39, fontWeight: 700, color: '#354155', marginTop: 50, marginBottom: 30 }}><Trans>FLEXIBLE SAVESTING</Trans></div>
              <div style={{ fontSize: 15, color: "#2E4C60" }}><Trans>Build the habit of accumulating finances today with SCP!</Trans></div>
              <div style={{ fontSize: 17, fontWeight: 600, color: "#2E4C60", marginTop: 30, marginBottom: 40 }}><Trans>Available on SCP & SCP Pro</Trans></div>
              <div style={{ display: 'flex', marginTop: 30 }}>
                <img
                  src={require('assets/icons/btn-app-store-black.jpg')}
                  className='cursor-pointer hover:opacity-70 w-[160px]'
                />
                <img style={{ marginLeft: 20 }}
                  src={require('assets/icons/btn-google-play-black.jpg')}
                  className='cursor-pointer hover:opacity-70 w-[160px]'
                />
              </div>
            </div>
            <div data-aos="fade-left" style={{ width: '55%' }}>
              <img src={require('assets/images/dt1.png')} />
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container className='py-20'>
          <div style={{ textAlign: 'center', fontSize: 40, fontWeight: 700, color: '#354155' }}><Trans>SCP’S INVESTMENT PRODUCTS</Trans></div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: "69%", color: "#3B3838", margin: "auto", marginBottom: 40 }}><Trans>This is a brand new and <span style={{ fontWeight: 700 }}>exclusive product</span>. Customers will buy, sell and own Receivables that have been acquired by SCP from reputable supply chains in the market.</Trans></div>
          </div>
          <div style={{ display: 'flex', marginTop: 25 }}>
            <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" style={{ width: '70%', borderWidth: 1, borderColor: '#0FB7AD', borderRadius: 10, marginRight: 15 }}>
              <div style={{ fontSize: 22, fontWeight: 700, color: "#26465A", margin: 20 }}><Trans>Unlimited Transferable Savesting</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Receivables trading with option transferring them to SCP at any time.</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Short term from <b style={{ padding: "0px 5px" }}>15</b> to <b style={{ padding: "0px 5px" }}>60 days</b> depending on each package.</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans><b style={{ padding: "0px 5px" }}>Attractive</b>discount rate based on actual holding time.</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Receivables are listed and transparently announced by each product.</Trans></div>
            </div>
            <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" style={{ width: '30%', borderWidth: 1, borderColor: '#0FB7AD', borderRadius: 10, marginLeft: 15, display: 'flex', justifyContent: 'center' }}>
              <img style={{ marginTop: 15 }} src={require('assets/images/dt2.png')} />
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: 25 }}>
            <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" style={{ width: '30%', borderWidth: 1, borderColor: '#BF9000', borderRadius: 10, marginRight: 15, display: 'flex', justifyContent: 'center' }}>
              <img style={{ marginTop: 15, marginBottom: 15 }} src={require('assets/images/dt3.png')} />
            </div>
            <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" style={{ width: '70%', borderWidth: 1, borderColor: '#BF9000', borderRadius: 10, marginLeft: 15 }}>
              <div style={{ fontSize: 22, fontWeight: 700, margin: 20, color: '#BF9000' }}><Trans>Limited Transferable Savesting</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Holding receivables to maturity or re-transferring at the 15th, 30th, 45th, … of the package</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Short term from <b style={{ padding: "0px 5px" }}>30</b> to <b style={{ padding: "0px 5px" }}>90 days</b> depending on each package.</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans><b style={{ padding: " 0px 5px" }}>Fixed</b> discount rate based on actual holding time.</Trans></div>
              <div style={{ color: "#3B3838", marginLeft: 20, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Receivables are listed and transparently announced by each product.</Trans></div>
            </div>
          </div>
          <div style={{ color: "#3B3838", textAlign: "right", fontSize: 14, marginRight: 80, marginTop: 10 }}><Trans>* Receivable is a debt arising between the buyer and the seller, with a payment term of 45 - 90 days</Trans></div>
        </Container>
      </div>
      <div >
        <div style={{ fontSize: 45, textAlign: 'center', color: '26465A' }}><Trans>ADVANTAGES OF <span style={{ color: '#28A0D4' }}>SCP</span>’S PRODUCTS</Trans></div>
        <div style={{ display: 'flex' }}>
          <div data-aos="fade-left" data-aos-anchor="#example-anchor" data-aos-offset="500" data-aos-duration="500" style={{ width: "45%" }}>
            <img style={{ width: "90%" }} src={require('assets/images/dt4.jpg')} />
          </div>
          <div style={{ width: "55%" }}>
            <div data-aos="fade-up" style={{ color: "#3B3838", fontSize: 21, fontWeight: 700, margin: "50px 0px" }}><Trans>Legal guarantee</Trans></div>
            <div data-aos="fade-up" style={{ marginBottom: 80, paddingRight: 200, textAlign: "justify" }}><Trans>Sign digital contract 100% for asset transfer (receivables) at a discounted price (customer income) when investing on the application</Trans></div>
            <div data-aos="fade-up" style={{ color: "#3B3838", fontSize: 21, fontWeight: 700, margin: "50px 0px" }}><Trans>Transparent</Trans></div>
            <div data-aos="fade-up" style={{ marginBottom: 80, paddingRight: 200, textAlign: "justify" }}><Trans>Divide the property owned in the name and belonging to the customer. When customers withdraw the investment, they sell the Receivables to SCP and collect the money with the income from the discount.</Trans></div>
            <div data-aos="fade-up" style={{ color: "#3B3838", fontSize: 21, fontWeight: 700, margin: "50px 0px" }}><Trans>Prestigious investment</Trans></div>
            <div data-aos="fade-up" style={{ marginBottom: 80, paddingRight: 200, textAlign: "justify" }}><Trans>Receivables are all acquired by SCP from reputable supply chains in the Vietnamese market such as FPT Long Chau, Nafoods, ... and ensure the investment time is in accordance with the due time of the Receivables.</Trans></div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#EDEDED' }}>
        <Container className='py-20'>
          <div style={{ textAlign: 'center', fontSize: 40, fontWeight: 700, color: "#1C2B4D" }}><Trans>HOW TO INVEST ON SCP ?</Trans></div>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <div style={{ width: '33%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: "10%" }}></div>
                <div style={{ width: "10%", color: "#767171", fontSize: 45, fontWeight: 700, marginRight: 40 }}>01</div>
                <div style={{ width: "60%" }}>
                  <img style={{ width: '100%' }} src={require('assets/images/dt5.png')} />
                </div>
                <div style={{ width: "10%" }}></div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: "10%" }}></div>
                <div style={{ width: "80%", marginTop: 15 }}>
                  <div style={{ color: "#3B3838", fontSize: 20, fontWeight: 700, marginBottom: 15 }}><Trans>Download the app & register account</Trans></div>
                  <div style={{ marginBottom: 15, textAlign: "justify" }}><Trans>Service is available on 2 free SCP – 1 touch, 1 payment & SCP Pro on App Store / Google Play.</Trans></div>
                </div>
                <div style={{ width: "10%" }}></div>
              </div>
            </div>
            <div style={{ width: '33%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: "10%" }}></div>
                <div style={{ width: "10%", color: "#767171", fontSize: 45, fontWeight: 700, marginRight: 40 }}>02</div>
                <div style={{ width: "60%" }}>
                  <img style={{ width: '100%' }} src={require('assets/images/dt6.png')} />
                </div>
                <div style={{ width: "10%" }}></div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: "10%" }}></div>
                <div style={{ width: "80%", marginTop: 15 }}>
                  <div style={{ color: "#3B3838", fontSize: 20, fontWeight: 700, marginBottom: 15 }}><Trans>Select investment package</Trans></div>
                  <div style={{ marginBottom: 15, textAlign: "justify" }}><Trans>Select the unlimited and limited transferring package which are listed on app.</Trans></div>
                </div>
                <div style={{ width: "10%" }}></div>
              </div>
            </div>
            <div style={{ width: '33%' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: "10%" }}></div>
                <div style={{ width: "10%", color: "#767171", fontSize: 45, fontWeight: 700, marginRight: 40 }}>03</div>
                <div style={{ width: "47%" }}>
                  <img style={{ width: '100%' }} src={require('assets/images/dt7.png')} />
                </div>
                <div style={{ width: "10%" }}></div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: "10%" }}></div>
                <div style={{ width: "80%", marginTop: 15 }}>
                  <div style={{ color: "#3B3838", fontSize: 20, fontWeight: 700, marginBottom: 15 }}><Trans>Invest & get discount</Trans></div>
                  <div style={{ marginBottom: 15, textAlign: "justify" }}><Trans>Invest to SCP and hold assets (receivables) with discount to maturity or sell back to SCP at any time.</Trans></div>
                </div>
                <div style={{ width: "10%" }}></div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ background: `url(${require('assets/images/bg-investment1.png')}) no-repeat` }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 50, marginTop: 20 }}>
          <Button onClick={submitContact} variant='contained' size='large' style={{ backgroundColor: '#0FB7AD' }} className='h-14 rounded-lg mx-auto'>
            <Trans>Join to savesting with SCP !</Trans>
          </Button>
        </div>
        <Container className='py-20'>
          <Grid container columnSpacing={8}>
            <Grid item md={7}>
              <img style={{ width: "80%" }} src={require('assets/images/dt8.png')} data-aos='fade-right' />
            </Grid>
            <Grid item md={5} className='flex flex-col items-start justify-end pb-10'>
              <Typography className='font-black text-[3rem] text-white mb-4'>
                <Trans>Savesting with SCP</Trans>
              </Typography>
              <Typography className='text-white mb-10'>
                <Trans>Contact SCP immediately to become a changemaker and financially proactive</Trans>
              </Typography>
              <Button onClick={submitContact} variant='contained' size='large' color='info' className='h-14 rounded-lg mx-auto'>
                <Trans>Contact SCP</Trans>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Dialog fullWidth maxWidth='xl' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <ContactPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
    </StaticLayout>
  );
};

export default Investment;
