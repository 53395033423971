import React from 'react';
import { Trans } from '@lingui/macro';
import { Grid, Typography, Paper } from '@mui/material';
import { TransSummaryType } from 'types/Transaction';
import { formatNumber } from 'utils/common';

const TransHisListSummary = ({ summary }: { summary?: TransSummaryType }) => {
  return (
    <Paper className='bg-[#f2f2f2] p-4'>
      <Grid container columnSpacing={3}>
        <Grid item md={4} lg={2} xl={2} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>No. of Transaction</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.noOfTransaction)}</Typography>
          </Paper>
        </Grid>
        <Grid item md={4} lg={2} xl={2} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Request Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalRequestAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={4} lg={2} xl={2} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Payment Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalPaymentAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={4} lg={2} xl={2} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Payment At Maturity</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalCollectionAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={4} lg={2} xl={2} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>No. of Overdue</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.noOfOverdue)}</Typography>
          </Paper>
        </Grid>
        <Grid item md={4} lg={2} xl={2} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Overdue Unpaid Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalOverdueUnpaidAmount)} VND</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TransHisListSummary;
