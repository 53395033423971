import { Trans } from '@lingui/macro';
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEnterpriseApplyFeeType, getEnterpriseServiceFeeHistoryStatusType } from 'constants/enterprise';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { formatNumber } from 'utils/common';
import { useState } from 'react';
import { EnterpriseSettingFeePopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import SelectPageSize from 'components/SelectPageSize';

const EnterpriseHistorySettingFee = ({ id }: { id: string }) => {
  const [dataSearch, onSearchChange] = useSearch();
  const { requireRole } = usePermission();

  const { data } = useQuery(
    ['enterpriseSetupService.fetchEnterpriseServiceFeeHistories', { id, ...dataSearch }],
    () => enterpriseSetupService.fetchEnterpriseServiceFeeHistories({ id, ...dataSearch }),
    {
      keepPreviousData: true,
    },
  );

  const [openPopup, setOpenPopup] = useState(false);
  const [stepCurrent, setStepCurrent] = useState<number>();
  const [dataItem, setDataItem] = useState<any>();

  const { data: items = [], paginate } = data ?? {};

  return (
    <div>
      <Typography variant='h4'>
        <Trans>Setting History</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Execution Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Executor</Trans>
              </TableCell>
              <TableCell>
                <Trans>Applied Fee</Trans>
              </TableCell>
              <TableCell>
                <Trans>Fixed Service Fee after tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Expire Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Reason</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.createdAt ? DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{item.createdName}</TableCell>
                <TableCell>{getEnterpriseApplyFeeType(item.appliedType)}</TableCell>
                <TableCell align='right'>{formatNumber(item.totalFeeAfterTax)}</TableCell>
                <TableCell>
                  {item.endValidityTime ? DateTime.fromISO(item.endValidityTime).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{getEnterpriseServiceFeeHistoryStatusType(item.status)}</TableCell>
                <TableCell>{item.reason}</TableCell>
                <TableCell>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenPopup(true);
                      setDataItem(item);
                      if (item.appliedType === 'FIXED_SERVICE_FEE') {
                        if (item.status === 'WAITING_FOR_APPROVE') setStepCurrent(4);
                        else if (item.status === 'WAITING_FOR_SIGNING') setStepCurrent(5);
                        else if (item.status === 'WAITING_FOR_ENT_CONFIRMATION')
                          requireRole('MASTER', false) ? setStepCurrent(6) : setStepCurrent(5);
                        else if (item.status === 'WAITING_FOR_PC_CONFIRMATION')
                          requireRole('CA_SENIOR', false) ? setStepCurrent(5) : setStepCurrent(6);
                        else if (
                          item.status === 'COMPLETED' ||
                          item.status === 'REJECTED' ||
                          item.status === 'CANCELED'
                        )
                          setStepCurrent(7);
                        else setStepCurrent(item.appendixType === 'DIGITAL' ? 1 : 4);
                      } else {
                        if (item.status === 'WAITING_FOR_ENT_CONFIRMATION')
                          requireRole('ENTERPRISE', false) ? setStepCurrent(3) : setStepCurrent(4);
                        else if (item.status === 'COMPLETED' || item.status === 'REJECTED') setStepCurrent(7);
                      }
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='lg' open={openPopup} onClose={() => setOpenPopup(false)}>
        <EnterpriseSettingFeePopup
          dataItem={dataItem}
          stepCurrent={stepCurrent}
          enterpriseId={id}
          onClose={() => setOpenPopup(false)}
        />
      </Dialog>
    </div>
  );
};

export default EnterpriseHistorySettingFee;
