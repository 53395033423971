import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { InputNumber } from 'components';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { CreditScoreType, RatingCategoryType, RatingCreateType } from 'types/CreditScore';

type PopupProps = PopupController & {
  item?: RatingCategoryType;
  creditScore: CreditScoreType;
};

const RatingCreatePopup = ({ item, creditScore, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const isCreate = !item;

  useEffect(() => {
    Object.entries(item ?? {}).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [item, setValue]);

  const { mutate: createRatingScore, isLoading } = useMutation(
    isCreate ? creditScoreService.createRatingScore : creditScoreService.updateRatingScore,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('creditScoreService.getCreditScoreById');
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values: RatingCreateType) => {
      createRatingScore({
        ...values,
        csId: creditScore.id,
        id: item?.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>{item ? t`Add` : t`Update`} Rating Score</DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={4}>
            <Controller
              name='ratingType'
              defaultValue='A'
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField {...field} fullWidth select required label={t`Rating`} InputProps={{ readOnly: !isCreate }}>
                  {['A', 'B', 'C', 'D', 'E'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item sm={8}></Grid>
          <Grid item sm={6}>
            <Controller
              name='minScore'
              defaultValue=''
              control={control}
              rules={{ required: t`Minimun Score is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Minimun Score`}
                  InputProps={{ inputComponent: InputNumber as any }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='maxScore'
              defaultValue=''
              control={control}
              rules={{ required: t`Maximun Score is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Maximun Score`}
                  InputProps={{ inputComponent: InputNumber as any }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='adjustmentRatio'
              defaultValue=''
              control={control}
              rules={{ required: t`Limit Adjustment is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Limit Adjustment Ratio by Industry (%)`}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { allowNegative: true },
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RatingCreatePopup;
