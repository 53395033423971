import { Trans, t } from '@lingui/macro';
import { Container } from '@mui/material';
import { StaticLayout } from 'layouts';
import { useState, useEffect } from 'react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FAQS = () => {
    const [cauHoiChung, setCauHoiChung] = useState(true);
    const [ungLuong, setUngLuong] = useState(false);
    const [dauTu, setDauTu] = useState(false);
    const [cauHoiChung1, setCauHoiChung1] = useState(true);
    const [cauHoiChung2, setCauHoiChung2] = useState(false);
    const [cauHoiChung3, setCauHoiChung3] = useState(false);
    const [cauHoiChung4, setCauHoiChung4] = useState(false);
    const [ungLuong1, setUngLuong1] = useState(false);
    const [ungLuong2, setUngLuong2] = useState(false);
    const [ungLuong3, setUngLuong3] = useState(false);
    const [ungLuong4, setUngLuong4] = useState(false);
    const [ungLuong5, setUngLuong5] = useState(false);
    const [ungLuong6, setUngLuong6] = useState(false);
    const [ungLuong7, setUngLuong7] = useState(false);
    const [ungLuong8, setUngLuong8] = useState(false);
    const [dauTu1, setDauTu1] = useState(false);
    const [dauTu2, setDauTu2] = useState(false);
    const [dauTu3, setDauTu3] = useState(false);
    const [dauTu4, setDauTu4] = useState(false);
    const [dauTu5, setDauTu5] = useState(false);
    const clickCauHoiChung = () => {
        setCauHoiChung(true);
        setCauHoiChung1(true);
        setCauHoiChung2(false);
        setCauHoiChung3(false);
        setCauHoiChung4(false);
        setUngLuong(false);
        setDauTu(false);
    }
    const clickDauTu = () => {
        setCauHoiChung(false);
        setUngLuong(false);
        setDauTu(true);
        setDauTu1(true);
        setDauTu2(false);
        setDauTu3(false);
        setDauTu4(false);
        setDauTu5(false);
    }
    const clickUngLuong = () => {
        setCauHoiChung(false);
        setUngLuong(true);
        setUngLuong1(true);
        setUngLuong2(false);
        setUngLuong3(false);
        setUngLuong4(false);
        setUngLuong5(false);
        setUngLuong6(false);
        setUngLuong7(false);
        setUngLuong8(false);
        setDauTu(false);
    }
    const itemFaq = [
        { id: 1, label: "Who is SCP?", Faq: "SCP Technology Salary Advance Platform Co. " },
        { id: 2, label: "What services SCP offers?", Faq: "SCP Technology Salary Advance Platform Co. " }
    ]
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }, []);
    return (
        <StaticLayout>
            <div>
                <Container className='py-20'>
                    <div style={{ textAlign: 'center', fontSize: 35, fontWeight: 600 }}><Trans>FREQUENTLY ASKED QUESTIONS</Trans></div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                        <div onClick={clickCauHoiChung} style={{ width: '300px', backgroundColor: cauHoiChung ? '#ADB9CA' : '#EAEAEA', height: "180px", margin: 10, cursor: 'pointer' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 32 }}><img src={require('assets/images/fa1.png')} /></div>
                            <div style={{ textAlign: 'center', fontSize: 24 }}><Trans>General</Trans></div>
                        </div>
                        <div onClick={clickUngLuong} style={{ width: '300px', backgroundColor: ungLuong ? '#ADB9CA' : '#EAEAEA', height: "180px", margin: 10, cursor: 'pointer' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 30 }}><img src={require('assets/images/fa2.png')} /></div>
                            <div style={{ textAlign: 'center', fontSize: 24 }}><Trans>Salary Advance</Trans></div>
                        </div>
                        <div onClick={clickDauTu} style={{ width: '300px', backgroundColor: dauTu ? '#ADB9CA' : '#EAEAEA', height: "180px", margin: 10, cursor: 'pointer' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', margin: 31 }}><img src={require('assets/images/fa3.png')} /></div>
                            <div style={{ textAlign: 'center', fontSize: 24 }}><Trans>Savesting</Trans></div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: 50 }}>
                        <div data-aos="fade-up" style={{ width: '23%', backgroundColor: '#EAEAEA', padding: 15, lineHeight: "35px", height: "200px" }}>
                            <div style={{ fontSize: '18px', fontWeight: 700, color: '#7F7F7F' }}><Trans>CONTACT US</Trans></div>
                            <div style={{ width: '100%', height: "2px", backgroundColor: '#7F7F7F', marginBottom: 5 }}></div>
                            <div>Hotline: <b>1900 5656 16</b></div>
                            <div>Zalo: <b>0888 20 17 17</b></div>
                            <div>Email: <b>support.ul@scp.com.vn</b></div>
                        </div>
                        <div style={{ width: '2%' }}></div>
                        <div style={{ width: '75%' }}>
                            {
                                cauHoiChung === true ?
                                    <div>
                                        <div style={{ fontSize: 34, fontWeight: 700, color: "#354155", letterSpacing: 10, textTransform: 'uppercase', borderBottom: "1px solid #EAEAEA" }}><Trans>General</Trans></div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { cauHoiChung1 === false ? setCauHoiChung1(true) : setCauHoiChung1(false) }}>
                                                {cauHoiChung1 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>Who is SCP?</Trans></div>
                                            </div>
                                            {cauHoiChung1 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>SCP Technology Salary Advance Platform Co. Ltd, one member of SCP Group, is a company leading the trend of salary advances for employees based on debt trading license – called Income transfer. Founded in December 2021, SCP EWA has affirmed our position and offerred salary advance services to employees working at companies such as Viet Hung Corporation, DK Company, …</Trans></div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { cauHoiChung2 === false ? setCauHoiChung2(true) : setCauHoiChung2(false) }}>
                                                {cauHoiChung2 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>What services SCP offers?</Trans></div>
                                            </div>
                                            {cauHoiChung2 === true ?
                                                <div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Now, we’re providing 2 main services: Salary Advance & Savesting</Trans></div>
                                                    <div style={{ marginLeft: 40, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Salary advance is a service supporting the employees to receive their salary counted on earned working days or even not-earned-yet days before fixed payday every month.</Trans></div>
                                                    <div style={{ marginLeft: 40, marginBottom: 10, display: 'flex' }}><img style={{ width: 14, height: 14, marginTop: 4, marginRight: 15 }} src={require('assets/images/cham.png')} /><Trans>Savesting is a utility supporting the employees to save and invest their finance through trading Account Receivable which SCP purchased from reputable supply chains in Vietnamese market.</Trans></div>
                                                </div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { cauHoiChung3 === false ? setCauHoiChung3(true) : setCauHoiChung3(false) }}>
                                                {cauHoiChung3 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>Who is SCP’s target customers?</Trans></div>
                                            </div>
                                            {cauHoiChung3 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Our target customers are Employees working at the companies located in Vietnam and having more than 50 workers.</Trans></div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { cauHoiChung4 === false ? setCauHoiChung4(true) : setCauHoiChung4(false) }}>
                                                {cauHoiChung4 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>How can I onboard SCP?</Trans></div>
                                            </div>
                                            {cauHoiChung4 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>After SCP signing coordination agreement with the Enterprise, Employee will be granted an advance account through their email & message registered with the enterprise.
                                                Employees free download SCP App on App Store or Google Play and login with provided account information, they have to change password for the first access.
                                            </Trans></div> : <div></div>}
                                        </div>
                                    </div>
                                    : <div></div>
                            }
                            {
                                ungLuong === true ?
                                    <div>
                                        <div style={{ fontSize: 34, fontWeight: 700, color: "#354155", letterSpacing: 10, textTransform: 'uppercase', borderBottom: "1px solid #EAEAEA" }}><Trans>Salary Advance</Trans></div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong1 === false ? setUngLuong1(true) : setUngLuong1(false) }}>
                                                {ungLuong1 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>What is SCP’s salary advance?</Trans></div>
                                            </div>
                                            {ungLuong1 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>SCP’s Salary advance is a service supporting the employees to receive their salary counted on earned working days or even not-earned-yet days before fixed payday every month.</Trans></div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong2 === false ? setUngLuong2(true) : setUngLuong2(false) }}>
                                                {ungLuong2 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>How’s different between SCP and others having same services?</Trans></div>
                                            </div>
                                            {ungLuong2 === true ?
                                                <div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>The first thing we need to confirm: SCP's Salary Advance service is <b>NOT a loan</b>, so it won't affect the customer's CIC when requesting Salary Advance on SCP's application.
                                                        Secondly, we don’t assess the customer credit, don't require minimum wages and collateral when applying.
                                                    </Trans></div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Thirdly, SCP’s Salary Advance service is suitable for all employees from officers, workers to part-time staff.
                                                    </Trans></div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>As long as you need, we always ready to help!
                                                    </Trans></div>
                                                </div>
                                                : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong3 === false ? setUngLuong3(true) : setUngLuong3(false) }}>
                                                {ungLuong3 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>What are the conditions of salary advance?</Trans></div>
                                            </div>
                                            {ungLuong3 === true ?
                                                <div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>If you are a Vietnamese, an employee working for a company that cooperates with SCP and having salary tranferred via bank, you can apply for SCP's Salary Advance Service.</Trans></div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>To find out if your company is using SCP's Services, please contact your Human Resources Department or call our hotline <b>1900 5757 16</b> / Zalo <b>0888 20 17 17.</b></Trans></div>
                                                </div>
                                                : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong4 === false ? setUngLuong4(true) : setUngLuong4(false) }}>
                                                {ungLuong4 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>How much I can advance?</Trans></div>
                                            </div>
                                            {ungLuong4 === true ?
                                                <div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Because SCP's Salary Advance service is not a loan so the maximum amount you can advance is the salary based on the number of earned working days or even not-earned-yet days in ONE timesheet.</Trans></div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>When you login SCP app, your maximum advance amount and labours will be shown on app. The maximum advance amount has been based on the categories such as type of labor contract, senority, your current level matched with the cooperation conditions between SCP & your Enterprise.</Trans></div>
                                                </div>
                                                : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong5 === false ? setUngLuong5(true) : setUngLuong5(false) }}>
                                                {ungLuong5 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>How much is advance fee?</Trans></div>
                                            </div>
                                            {ungLuong5 === true ?
                                                <div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>You will need to pay only one transaction cost that is displayed on app when you apply for advance. Your transaction cost has not been fixed, it is calculated based on the advance term and the advance amount. In addition, the price also depends on the credit rating of your company or the welfare program that your company applies specifically for their employees.</Trans></div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>SCP commits not to incur interest or any hidden fees.</Trans></div>
                                                </div>
                                                : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong6 === false ? setUngLuong6(true) : setUngLuong6(false) }}>
                                                {ungLuong6 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>Does SCP assess anything when employees onboard?</Trans></div>
                                            </div>
                                            {ungLuong6 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>The answer is NO. Employees only need to verify electronic identity (eKYC) and confirm salary bank account with working enterprise to be able to apply for salary advance on SCP app.</Trans></div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong7 === false ? setUngLuong7(true) : setUngLuong7(false) }}>
                                                {ungLuong7 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>What’s happened if enterprise pay salary late?</Trans></div>
                                            </div>
                                            {ungLuong7 === true ?
                                                <div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Late payment may be due to: company delay in paying salary, errors in withholding income, errors by company or bank system resulting SCP not receiving the advanced amount on time. We’re going to comply with the current law on penalties for late payment in economic transactions. If you don not want to get penalties, you can transfer directly to SCP the amount of late payment to stop charging.</Trans></div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}>{t`The transfer content: <Your name> <Your company> <Your phone number/ID Card>. Please transfer the correct content so we can check your amount as soon as possible. The late payment penalty is not what we want, but we will also evaluate the reason of late payment to implement the best solution.`}</div>
                                                </div>
                                                : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { ungLuong8 === false ? setUngLuong8(true) : setUngLuong8(false) }}>
                                                {ungLuong8 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>Does advance salary via SCP's app affect to any personal income tax or salary-related taxes/fees that need to be paid to the State?</Trans></div>
                                            </div>
                                            {ungLuong8 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Personal income tax or all taxes/fees related to wages which must be paid to the State that shall be calculated according to the actual salary arising. It is based on the Labor Contract signed between employee and enterprise. Therefore, joining the Salay Advance Service does not affect and change these taxes and fees.</Trans></div> : <div></div>}
                                        </div>
                                    </div>
                                    : <div></div>
                            }
                            {
                                dauTu === true ?
                                    <div>
                                        <div style={{ fontSize: 34, fontWeight: 700, color: "#354155", letterSpacing: 10, textTransform: 'uppercase', borderBottom: "1px solid #EAEAEA" }}><Trans>Savesting</Trans></div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { dauTu1 === false ? setDauTu1(true) : setDauTu1(true) }}>
                                                {dauTu1 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>What is SCP’s savesting?</Trans></div>
                                            </div>
                                            {dauTu1 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Savesting is a new and exclusive product of SCP. It supports employees save and invest their finance through trading Account Receivables which SCP purchased from reputable supply chains in Vietnamese market. The user’s income is the difference between the debt purchase price and the debt sale price as listed discount rate.</Trans></div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { dauTu2 === false ? setDauTu2(true) : setDauTu2(true) }}>
                                                {dauTu2 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>How’s different between SCP’s savesting and Investment Cooperation BCC?</Trans></div>
                                            </div>
                                            {dauTu2 === true ?
                                                <div style={{ paddingLeft: 40, paddingTop: 15 }}>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", display: 'flex' }}>
                                                        <div style={{ width: '30%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}><Trans>Investment Cooperation BCC</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, padding: 15, borderBottom: 'none' }}><Trans>Savesting by Account Receivable trading</Trans></div>
                                                    </div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", display: 'flex' }}>
                                                        <div style={{ width: '30%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}><Trans>Purpose</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}><Trans>The parties jointly contribute assets to carry out the economic transactions</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, padding: 15, borderBottom: 'none' }}><Trans>The Debt Seller transfers the assets (Account Receivable) to the Debt Purchaser</Trans></div>
                                                    </div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", display: 'flex' }}>
                                                        <div style={{ width: '30%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}><Trans>Income</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}><Trans>Divided by contribution or agreement</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, padding: 15, borderBottom: 'none' }}><Trans>Difference between the debt purchase price and the debt sale price as listed discount rate</Trans></div>
                                                    </div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", display: 'flex' }}>
                                                        <div style={{ width: '30%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}><Trans>Legal</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, borderRight: 'none', padding: 15, borderBottom: 'none' }}><Trans>Unclear</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, padding: 15, borderBottom: 'none' }}><Trans>Debt trading license</Trans></div>
                                                    </div>
                                                    <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", display: 'flex' }}>
                                                        <div style={{ width: '30%', border: '1px solid', height: 100, borderRight: 'none', padding: 15 }}><Trans>Ownership</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, borderRight: 'none', padding: 15 }}><Trans>Assets formed from economic transaction will be named and managed by Platform party.</Trans></div>
                                                        <div style={{ width: '40%', border: '1px solid', height: 100, padding: 15 }}><Trans>Assets (Account Receivable) will be named and owned by purchaser</Trans></div>
                                                    </div>
                                                </div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { dauTu3 === false ? setDauTu3(true) : setDauTu3(true) }}>
                                                {dauTu3 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>What is Account Receivable?</Trans></div>
                                            </div>
                                            {dauTu3 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>Account Receivable is an asset of enterprise, which is calculated on the debt of goods/services that the supplier has not paid yet to the buyer in supply chain.</Trans></div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { dauTu4 === false ? setDauTu4(true) : setDauTu4(true) }}>
                                                {dauTu4 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>How is my income?</Trans></div>
                                            </div>
                                            {dauTu4 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>The discount income is the difference between the debt purchase price and the debt sale price calculated on holding time and listed discount rate of each investing package.</Trans></div> : <div></div>}
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => { dauTu5 === false ? setDauTu5(true) : setDauTu5(true) }}>
                                                {dauTu5 === true ? <ArrowDropDownIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowDropDownIcon> : <ArrowRightIcon style={{ fontSize: 30, marginRight: 10 }}></ArrowRightIcon>}
                                                <div style={{ fontSize: 21, fontWeight: 600, color: "#354155" }}><Trans>What if Account Receivable is late payment?</Trans></div>
                                            </div>
                                            {dauTu5 === true ? <div style={{ fontSize: 15, color: "#3B3838", textAlign: "justify", paddingLeft: 40, paddingTop: 15 }}><Trans>SCP always guarantees income for users. If the account receivable is late, user’s receivable account will be resold to SCP automatically at maturity date and the user still receive full income from the discount of receivable trading.</Trans></div> : <div></div>}
                                        </div>
                                    </div>
                                    : <div></div>
                            }
                            {/* <div>
                                <div style={{ fontSize: 35, textTransform: 'uppercase', borderBottom: "1px solid #EAEAEA" }}><Trans>General</Trans></div>
                            </div>
                            <div>
                                <div style={{ fontSize: 35, textTransform: 'uppercase', borderBottom: "1px solid #EAEAEA" }}><Trans>Salary Advance</Trans></div>
                            </div>
                            <div>
                                <div style={{ fontSize: 35, textTransform: 'uppercase', borderBottom: "1px solid #EAEAEA" }}><Trans>Savesting</Trans></div>
                            </div> */}
                        </div>
                    </div>
                </Container>
            </div>
        </StaticLayout>
    );
}

export default FAQS;