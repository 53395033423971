import { Trans } from '@lingui/macro';
import { Grid, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { employeeService } from 'services';
import { getEnterpriseStatus } from 'constants/enterprise';

const EmployeeMeetingSummary = () => {
  const { data: summary } = useQuery(
    ['employeeService.getEmployeeMeeting'],
    () => employeeService.getEmployeeMeeting(),
    {},
  );

  return (
    <div className='max-w-xs'>
      <Typography variant='h4' className='mb-2'>
        <Trans>Report Summary</Trans>
      </Typography>
      <Grid container className='border py-1'>
        {summary?.map((item, index) => (
          <Grid item key={index} xs={12} className='min-w-fit text-center px-3 py-1'>
            <div className='flex justify-between'>
              <Typography variant='body2'>{getEnterpriseStatus(item.status)}</Typography>
              <Typography variant='subtitle1'>{item.total}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default EmployeeMeetingSummary;
