import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'reducers';
import { SupportedLocale } from 'constants/i18n';

export type UserSettingsState = {
  userLocale: SupportedLocale | null;
};

export const userSettingsSlice = createSlice({
  name: 'user-settings',
  initialState: {} as UserSettingsState,
  reducers: {
    setUserLocale: (_state, { payload: userLocale }) => {
      localStorage.setItem('locale', userLocale);
      return { userLocale: userLocale };
    },
  },
});

export const { setUserLocale } = userSettingsSlice.actions;

export const userSettingsSelector = ({ userSettings }: RootState) => userSettings;

export default userSettingsSlice.reducer;
