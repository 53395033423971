import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { ContractListEmployee } from 'views/Employee/EmployeeContract/components';

const EmployeeContractManagement = () => {
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Contract Management</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <ContractListEmployee />
      </div>
    </Paper>
  );
};

export default EmployeeContractManagement;
