import { Paper, Typography, Box } from '@mui/material';
import { Trans } from '@lingui/macro';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { formatNumber } from 'utils/common';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';

function LinearProgressWithLabel(props: LinearProgressProps & { value?: number; limit?: number; available?: number }) {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', my: 2, mr: 1 }}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant='body2' color='text-primary'>{`${formatNumber(props.value)}%`}</Typography>
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <Typography variant='body2' color='text-primary'>
            {formatNumber(props.available)}
          </Typography>
          <Typography variant='body2' color='text-primary'>
            <Trans>Available Limit</Trans> (VND)
          </Typography>
        </div>

        <div>
          <Typography variant='body2' color='text-primary'>
            {formatNumber(props.limit)}
          </Typography>
          <Typography variant='body2' color='text-primary'>
            <Trans>Total Limit</Trans> (VND)
          </Typography>
        </div>
      </Box>
    </>
  );
}

const UsingLimit = () => {
  const { data } = useQuery(['enterpriseService.fetchEnterprises', {}], () =>
    enterpriseService.fetchEnterprises({ sum: '1' }),
  );
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>SITUATION OF USING LIMIT</Trans>
        </Typography>
      </div>
      <div className='p-4'>
        <div className='rounded-lg bg-[#ccc]/40 p-8'>
          <LinearProgressWithLabel
            value={data && data?.data.length > 0 ? data?.data[0].availablePercent : 0}
            limit={data && data?.data.length > 0 ? data?.data[0].totalLimit : 0}
            available={data && data?.data.length > 0 ? data?.data[0].totalAvailableLimit : 0}
          />
        </div>
      </div>
    </Paper>
  );
};

export default UsingLimit;
