import { Trans, t } from '@lingui/macro';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { AdvanceLaborCreateType, AdvanceLaborType } from 'types/EnterpriseSetupAdvance';

type PopupProps = PopupController & {
  id: number;
  advanceLabor: AdvanceLaborType;
};

const AdvanceLaborCreatePopup = ({ id, advanceLabor, onClose }: PopupProps) => {
  const userLocale = useActiveLocale();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: createAdvanceLabor, isLoading } = useMutation(enterpriseSetupService.createAdvanceLabor, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.fetchAdvanceLabors');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: AdvanceLaborCreateType) => {
      createAdvanceLabor({
        ...values,
        id,
        laborTermId: advanceLabor.laborTermId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Advance Labor</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={8}>
            <TextField
              fullWidth
              label={t`Term`}
              InputProps={{ readOnly: true }}
              value={userLocale === 'vi-VN' ? advanceLabor.laborTerm.viContent : advanceLabor.laborTerm.enContent}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='rate'
              defaultValue={advanceLabor.rate ?? ''}
              control={control}
              rules={{ required: true, min: 0 }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Advance Ratio`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: {
                      maxLength: 5,
                      className: 'text-right',
                    },
                    endAdornment: '%',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AdvanceLaborCreatePopup;
