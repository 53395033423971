import { Trans } from '@lingui/macro';
import { Container, Typography, Button, Grid, Dialog } from '@mui/material';
import { StaticLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';
import ContactPopup from './ContactPopup';
import { Fragment, useState, useEffect } from 'react';

const Employee = () => {
  const userLocale = useActiveLocale();
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  function submitContact() {
    setOpenSelectBank(true)
  }
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);
  return (
    <StaticLayout>
      {/* <Container className='py-20'>
        <Typography className='font-black text-[3rem]'><Trans>Employee</Trans></Typography>
      </Container> */}
      <div style={{ background: `url(${require('assets/images/pic9.png')})`, backgroundRepeat: "no-repeat", backgroundPosition: 'bottom', backgroundSize: "contain", paddingBottom: "170px" }}>
        <Container >
          <div className='py-20' style={{ display: "flex", paddingRight: 35 }}>
            <div style={{ width: "50%", paddingRight: 35 }}>
              <div style={{ fontSize: 30, fontWeight: 600, color: "#1C2B4D", marginBottom: 25 }}><Trans>Free spending with SCP !</Trans></div>
              <div style={{ fontSize: 16, lineHeight: "30px", marginBottom: 15, textAlign: "justify" }}><Trans>Unplanned payment, smart savings, essential expenses or emergencies all require flexibility in your finances. With SCP's service, you are free to choose how and when using your money. Take the initiative to receive salary when needed, so that you can work and take care of your family with peace of mind.</Trans></div>
              <div style={{ fontSize: 16, lineHeight: "30px", textAlign: "justify" }}><Trans>SCP's application is easy to access, secure, and it makes you first. SCP has signed a cooperation agreement with your working Enterprise to get advance immediately with a reasonable fee. Once you are in the list of salary advance, you will be given an account and get payment right on SCP's application.</Trans></div>
              <div style={{ display: 'flex', marginTop: 30 }}>
                <img
                  src={require('assets/icons/btn-app-store-black.jpg')}
                  className='cursor-pointer hover:opacity-70 w-[160px]'
                />
                <img style={{ marginLeft: 20 }}
                  src={require('assets/icons/btn-google-play-black.jpg')}
                  className='cursor-pointer hover:opacity-70 w-[160px]'
                />
              </div>
              {/* <div style={{ background: `url(${require('assets/images/pic6.png')})`, backgroundSize: 'cover' }}></div> */}
            </div>
            <div style={{ width: "50%" }}>
              <img data-aos="fade-left" style={{ width: "100%" }} src={require('assets/images/pic8.png')} />
            </div>
          </div>
          {/* <div>
            <img style={{ width: "100%" }} src={require('assets/images/pic9.png')} />
          </div> */}
        </Container>
      </div>
      <div>
        <Container className='py-20'>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 35, fontWeight: 700, color: "#1C2B4D" }}><Trans>BENEFITS OF EMPLOYEES</Trans></div>
            <div style={{ marginBottom: "20px" }}><Trans>One application. More utilities</Trans></div>
          </div>
          <div style={{ display: 'flex', height: 280, position: 'relative' }}>
            <div style={{ width: "30%", backgroundColor: '#E0ECF2', padding: 30, borderRadius: "15px", marginRight: 10 }}>
              <div style={{ fontSize: 25, color: "#1C2B4D", fontWeight: 500, marginBottom: 20 }}><Trans><span style={{ fontWeight: 700 }}>Low cost or free</span></Trans></div>
              <div style={{ fontSize: 17 }}><Trans>You always get the first 2 transactions for free when applying on SCP's app.</Trans></div>
            </div>
            <div style={{ width: "70%", backgroundColor: '#E0ECF2', padding: 30, borderRadius: "15px", marginLeft: 10, textAlign: 'right' }}>
              <div style={{ fontSize: 25, color: "#1C2B4D", fontWeight: 700, marginBottom: 20 }}><Trans>On-demand income</Trans></div>
              <div style={{ fontSize: 17, paddingLeft: 190 }}><Trans>You can instantly receive part of your monthly salary through our app whenever you want.</Trans></div>
            </div>
            <img style={{ position: 'absolute', left: "26%", bottom: 0, width: "265px" }} src={require('assets/images/pic10.png')} />
          </div>
          <div style={{ display: 'flex', height: 280, position: 'relative', marginTop: 20 }}>
            <div style={{ width: "70%", backgroundColor: '#E0ECF2', padding: 30, borderRadius: "15px", marginRight: 10, display: "flex" }}>
              <img style={{}} src={require('assets/images/pic12.png')} />
              <div style={{ alignSelf: 'center' }}>
                <div style={{ fontSize: 25, color: "#1C2B4D", fontWeight: 700, marginBottom: 45 }}>
                  <Trans>Savesting with high discount</Trans>
                </div>
                <div style={{ fontSize: 17, paddingRight: 150 }}>
                  <Trans>You can fully participate in savesting on SCP's application with a high discount when you have idle money.</Trans>
                </div>
              </div>
            </div>
            <div style={{ width: "30%", backgroundColor: '#E0ECF2', padding: 30, borderRadius: "15px", marginLeft: 10 }}>
              <img style={{ position: 'absolute', right: 0, bottom: 0, width: "250px" }} src={require('assets/images/pic11.png')} />
            </div>
          </div>
          <div style={{ display: 'flex', backgroundColor: '#E0ECF2', height: 280, position: 'relative', marginTop: 20, borderRadius: "15px" }}>
            <div style={{ width: "50%", display: 'flex' }}>
              <div style={{ width: "50%" }}>

              </div>
              <div style={{ width: "50%" }}>
                <div style={{ fontSize: 25, fontWeight: 700, margin: "30px 0px" }}><Trans>Quick and simple payment</Trans></div>
                <div style={{ fontSize: 17 }}><Trans>NO personal credit assessment</Trans></div>
                <div style={{ fontSize: 17 }}><Trans>NO collateral required</Trans></div>
              </div>
            </div>
            <div style={{ width: "50%", position: 'relative' }}>
              <img style={{ width: '330px', position: 'absolute', bottom: 10, left: "45%" }} src={require('assets/images/pic13.png')} />
            </div>
          </div>
        </Container>
      </div>
      <div>
        <div style={{ backgroundRepeat: "no-repeat", backgroundPosition: 'bottom', backgroundSize: "contain" }}>
          <Container className='py-20'>
            <div style={{ display: 'flex' }}>
              <div data-aos="fade-right" style={{ width: '50%' }}>
                <div style={{ fontSize: 25, fontWeight: 700, color: "#1C2B4D", marginBottom: 15 }}><Trans>Account registration guideline</Trans></div>
                <div><Trans>Just a few simple steps, you can actively use your salary.</Trans></div>
              </div>
              <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
                <iframe width="600" height="330" src="https://www.youtube.com/embed/6X6tToBYq_I" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            </div>
          </Container>
        </div>
        <div style={{ textAlign: 'center', fontSize: 40, fontWeight: 700, margin: 30, color: "#1C2B4D" }}>
          <Trans>HOW TO GET SALARY ADVANCE ?</Trans>
        </div>
        <div style={{ background: `url(${require('assets/images/pic15.png')})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover' }}>
          <Container className='py-20'>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "10%", color: "#767171", fontSize: 45, fontWeight: 700, marginRight: 40 }}>01</div>
                  <div style={{ width: "50%" }}>
                    <img style={{ width: '70%' }} src={require('assets/images/pic16.jpg')} />
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "60%", marginTop: 15 }}>
                    <div style={{ fontSize: 25, fontWeight: 700, marginBottom: 15, color: "#3B3838" }}><Trans>Get an account & login</Trans></div>
                    <div style={{ marginBottom: 15, textAlign: "justify" }}><Trans>After being on the list of salary advances, you will receive your login ID via email & password via SMS.</Trans></div>
                    <div style={{ textAlign: "justify" }}>-  <Trans>Download the app for free on the App Store / Google Play.</Trans></div>
                    <div style={{ textAlign: "justify" }}>-  <Trans>Login with registered account and change the password for the first time.</Trans></div>
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "10%", color: "#767171", fontSize: 45, fontWeight: 700, marginRight: 40 }}>02</div>
                  <div style={{ width: "50%" }}>
                    <img style={{ width: '70%' }} src={require('assets/images/pic17.jpg')} />
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "60%", marginTop: 15 }}>
                    <div style={{ fontSize: 25, fontWeight: 700, marginBottom: 15, color: "#3B3838" }}><Trans>Account verification</Trans></div>
                    <div style={{ textAlign: "justify" }}><Trans>You need to do electronic identification (eKYC) and confirm your salary account with working company for the first transaction. Credentials will be automatically applied to the following transactions.</Trans></div>
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: 30 }}>
              <div style={{ width: '50%' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "10%", color: "#767171", fontSize: 45, fontWeight: 700, marginRight: 40 }}>03</div>
                  <div style={{ width: "50%" }}>
                    <img style={{ width: '70%' }} src={require('assets/images/pic18.jpg')} />
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "60%", marginTop: 15 }}>
                    <div style={{ fontSize: 25, fontWeight: 700, marginBottom: 15, color: "#3B3838" }}><Trans>Apply for advance</Trans></div>
                    <div style={{ marginBottom: 15, textAlign: "justify" }}><Trans>Enter the advance amount, the application screen will display the fee and the actual amount you’re receiving to your bank account.</Trans></div>
                    <div style={{ textAlign: "justify" }}><Trans>Before finalizing the transaction, you need to check the information and confirm the transaction notice on the application.</Trans></div>
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
              </div>
              <div style={{ width: '50%' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "10%", color: "#767171", fontSize: 45, fontWeight: 700, marginRight: 40 }}>04</div>
                  <div style={{ width: "50%" }}>
                    <img style={{ width: '70%' }} src={require('assets/images/pic19.jpg')} />
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "60%", marginTop: 15 }}>
                    <div style={{ fontSize: 25, fontWeight: 700, marginBottom: 15, color: "#3B3838" }}><Trans>Complete transaction</Trans></div>
                    <div style={{ textAlign: "justify" }}><Trans>You enter the OTP code sent to the registered phone number to complete the transaction. The money will be transferred to your salary account within 15 minutes after the request was sent successfully.</Trans></div>
                  </div>
                  <div style={{ width: "20%" }}></div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div>
        <Container className='py-20'>
          <div data-aos="zoom-in" style={{ textAlign: 'center', fontSize: 30, fontWeight: 700, marginBottom: 30 }}><Trans>Guideline of Salary Advance in SCP</Trans></div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <iframe width="800" height="500" src="https://www.youtube.com/embed/jxy6IVO6Izg" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </Container>
      </div>
      <div style={{ background: `url(${require('assets/images/bg-investment.png')}) no-repeat` }}>
        <Container className='py-20'>
          <Grid container columnSpacing={8}>
            <Grid item md={7}>
              <img src={require('assets/images/static-investment-1.png')} data-aos='fade-right' />
            </Grid>
            <Grid item md={5} className='flex flex-col items-start justify-end pb-10'>
              <Typography className='font-black text-[3rem] text-white mb-4'>
                <Trans>Get your salary now!</Trans>
              </Typography>
              <Typography className='text-white mb-10'>
                <Trans>Contact SCP immediately to become a changemaker and financially proactive</Trans>
              </Typography>
              <Button onClick={submitContact} variant='contained' size='large' color='info' className='h-14 rounded-lg mx-auto'>
                <Trans>Contact SCP</Trans>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Dialog fullWidth maxWidth='xl' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <ContactPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
    </StaticLayout>
  );
};

export default Employee;
