import { Home } from '@mui/icons-material';
import { Breadcrumbs, IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { privateRoute } from 'routes';
const Breadcrumb = () => {
  const location = useLocation();
  const routes = (location.pathname.match(/\/[\w-]+/g) ?? [])
    .map((_, index, array) => array.slice(0, index + 1).join(''))
    .map((item) => Object.values(privateRoute).find((route) => item === route.path))
    .filter((item) => item?.name);

  return (
    <div className='flex items-center space-x-1 mb-3'>
      <Link to={privateRoute.home.path}>
        <IconButton size='small'>
          <Home fontSize='small' />
        </IconButton>
      </Link>
      <Breadcrumbs>
        {routes.map((item, index) => (
          <span key={index}>{item?.name}</span>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
