import { Trans } from '@lingui/macro';
import { DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { NoticeType } from 'types/EnterpriseSetup';

type PopupProps = {
  onBack: () => void;
  notice: NoticeType;
};

const GuaranteeConfirmationStep3 = ({ notice, onBack }: PopupProps) => {
  return (
    <>
      <DialogTitle>
        <Trans>GUARANTEE CONFIRMATION NOTICE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={notice.objectKey} />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={onBack}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
    </>
  );
};

export default GuaranteeConfirmationStep3;
