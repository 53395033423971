import { Trans } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { employeeService } from 'services';
import {
  EmployeeHistorySetting,
  EmployeeInfo,
  EmployeeOverdueDebt,
  EmployeeSupportProgram,
  EmployeeSupportProgramHis,
} from 'views/ClientSetup/Employee/components';
import { EmployeeSeniorUserTable } from 'views/Employee/EmployeeView/components';

const EmployeeSetupView = () => {
  const { id } = useParams() as { id: string };

  const { data: employee } = useQuery(['employeeService.getEmployeeById', { id }], () =>
    employeeService.getEmployeeById({ id }),
  );
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Setup Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid item sm={12}>
            <EmployeeInfo id={id} />
          </Grid>
          <Grid item sm={12}>
            <EmployeeHistorySetting id={id} />
          </Grid>
          <Grid item sm={12}>
            <EmployeeOverdueDebt id={id} />
          </Grid>
          <Grid item sm={12}>
            <EmployeeSupportProgram id={id} />
          </Grid>
          <Grid item sm={12}>
            <EmployeeSupportProgramHis id={id} />
          </Grid>
          <Grid item sm={12}>
            <EmployeeSeniorUserTable employee={employee} />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default EmployeeSetupView;
