import { Trans } from '@lingui/macro';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { TransType } from 'types/Transaction';

const TransAssignedUser = ({ transaction }: { transaction?: TransType }) => {
  const { seniorUser, caManagerUser } = transaction ?? {};

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <Typography variant='h4'>
          <Trans>Information of Assigned Account</Trans>
        </Typography>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Department</Trans>
              </TableCell>
              <TableCell>
                <Trans>Group</Trans>
              </TableCell>
              <TableCell>
                <Trans>Role</Trans>
              </TableCell>
              <TableCell>
                <Trans>Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Position</Trans>
              </TableCell>
              <TableCell>
                <Trans>Email</Trans>
              </TableCell>
              <TableCell>
                <Trans>Phone</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Trans>CRM Manger</Trans>
              </TableCell>
              <TableCell>{seniorUser?.group?.name}</TableCell>
              <TableCell>{seniorUser?.role}</TableCell>
              <TableCell>{seniorUser?.fullname}</TableCell>
              <TableCell>{seniorUser?.position}</TableCell>
              <TableCell>{seniorUser?.email}</TableCell>
              <TableCell>{seniorUser?.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Trans>CA Manger</Trans>
              </TableCell>
              <TableCell>{caManagerUser?.group?.name}</TableCell>
              <TableCell>{caManagerUser?.role}</TableCell>
              <TableCell>{caManagerUser?.fullname}</TableCell>
              <TableCell>{caManagerUser?.position}</TableCell>
              <TableCell>{caManagerUser?.email}</TableCell>
              <TableCell>{caManagerUser?.phone}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TransAssignedUser;
