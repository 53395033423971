import { useState, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import { DialogActions, DialogContent, Dialog, DialogTitle, Grid, Chip, TextField } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { enterpriseSetupService, fileService, queryClient } from 'services';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { RejectPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { DateTime } from 'luxon';
import { AppendixManualUploadType } from 'types/EnterpriseSetup';
import { ObjectType } from 'types/Object';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseId: string;
  appendixId: number;
  objectManualData: ObjectType[];
  setObjectManualData: (args: any) => void;
};

const ManualPopupStep4 = ({
  enterpriseId,
  appendixId,
  objectManualData,
  setObjectManualData,
  onBack,
  onNext,
}: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });

  const { appendixNo, signingDate } = watch();
  const [isOpenReject, setOpenReject] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>();

  const { mutate: uploadManualAppendix, isLoading: isLoadingUploadManual } = useMutation(
    enterpriseSetupService.uploadManualAppendix,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        onNext();
        queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
      },
    },
  );

  const {
    mutate: uploadFiles,
    isLoading: isLoadingUploadFile,
    isSuccess,
  } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjectManualData(data.objects);
    },
  });

  const handleUploadFile = () => {
    const formData = new FormData();
    if (fileSelected) {
      formData.append('files', fileSelected, fileSelected.name);
      uploadFiles(formData);
    }
  };

  const handleUploadManual = () => {
    handleSubmit((values) => {
      uploadManualAppendix({
        ...values,
        enterpriseId,
        appendixId,
        signingDate: signingDate.toISO(),
        objectId: objectManualData[0].id,
      } as AppendixManualUploadType);
    })();
  };

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };

  return (
    <>
      <DialogTitle>
        <Trans>MANUAL APPENDIX</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex justify-between mb-4'>
          <input ref={inputRef} type='file' accept='application/pdf' hidden onChange={handleChangeFiles} />
          <div className='space-x-3'>
            <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
              <Trans>Choose Files</Trans>
            </LoadingButton>
            {fileSelected && (
              <Chip
                key={fileSelected?.name}
                variant='outlined'
                label={fileSelected?.name}
                onDelete={() => {
                  setFileSelected(null);
                  if (inputRef.current?.value) {
                    inputRef.current.value = '';
                  }
                }}
              />
            )}
          </div>

          <LoadingButton
            variant='contained'
            size='small'
            disabled={!fileSelected}
            startIcon={<Upload />}
            loading={isLoadingUploadFile}
            onClick={handleUploadFile}
          >
            <Trans>Upload</Trans>
          </LoadingButton>
        </div>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={4}>
            <Controller
              name='appendixNo'
              defaultValue=''
              control={control}
              rules={{ required: t`Appendix No is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Appendix No`}
                  error={invalid}
                  helperText={error?.message}
                ></TextField>
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='signingDate'
              defaultValue={DateTime.now()}
              control={control}
              rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  disablePast={true}
                  label={t`Signing Date`}
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' color='info' onClick={() => setOpenReject(true)}>
            <Trans>Cancel Appendix</Trans>
          </LoadingButton>
          <LoadingButton
            variant='contained'
            loading={isLoadingUploadManual}
            disabled={!isSuccess || !appendixNo || !signingDate}
            onClick={handleUploadManual}
          >
            <Trans>Request</Trans>
          </LoadingButton>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          enterpriseId={enterpriseId}
          appendixId={appendixId}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
    </>
  );
};

export default ManualPopupStep4;
