import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { TextField, Grid, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { employeeSetupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { UpdateSupportProgramType } from 'types/EmployeeSetup';

type PopupProps = PopupController & {
  employeeId: number;
  enterpriseEmployeeId: number;
  programId: number;
};

const RejectEmployeeSupportProgramPopup = ({
  employeeId,
  enterpriseEmployeeId,
  programId,
  onSuccess: rejectSuccess,
  onClose,
}: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const { mutate: rejectEmployeeSupportProgram, isLoading } = useMutation(
    employeeSetupService.updateEmployeeSupportProgram,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('employeeSetupService.getEmployeeSupportPrograms');
        queryClient.invalidateQueries('employeeSetupService.getEmployeeHisSupportPrograms');
        rejectSuccess!();
        onClose();
      },
    },
  );

  const handleClickReject = () => {
    handleSubmit((values: UpdateSupportProgramType) => {
      rejectEmployeeSupportProgram({
        reason: values.reason,
        status: 'REJECT',
        id: programId,
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Reject</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='reason'
              defaultValue=''
              control={control}
              rules={{
                required: t`Field is required`,
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  multiline
                  minRows={2}
                  label={t`Reject Reason`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' onClick={handleClickReject} loading={isLoading}>
          <Trans>Reject</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default RejectEmployeeSupportProgramPopup;
