import { client } from './axios';
import { GroupCreateType, GroupPaginateType, GroupParamsType, GroupUpdateType } from 'types/Group';

const fetchGroups = (params: GroupParamsType): Promise<GroupPaginateType> => client.get(`/api/v1/groups`, { params });
const createGroup = (body: GroupCreateType) => client.post(`/api/v1/groups`, body);
const updateGroup = ({ id, ...body }: GroupUpdateType) => client.put(`/api/v1/groups/${id}`, body);
const removeGroup = ({ id }: { id: number }) => client.delete(`/api/v1/groups/${id}`);

export default {
  fetchGroups,
  createGroup,
  updateGroup,
  removeGroup,
};
