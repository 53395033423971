import { Trans } from '@lingui/macro';
import { Button, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { employeeService } from 'services';
import { usePermission } from 'hooks';
import {
  EmployeeBankAccount,
  EmployeeInfo,
  EmployeeReportTable,
  EmployeeSeniorUserTable,
  EmployeeWorking
} from 'views/Employee/EmployeeView/components';

const EmployeeView = () => {
  const { id: _id } = useParams() as { id: string };
  const { EMPLOYEE_APPRAISAL, EMPLOYEE_REPORT,EMPLOYEE_LIST_REPORT, EMPLOYEE_VIEW, requreUserPermission, EMPLOYEE_CONTRACT } = usePermission();
  const { data: employee } = useQuery(['employeeService.getEmployeeById', { id: _id }], () =>
    employeeService.getEmployeeById({ id: _id }),
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Employee Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex justify-between mb-6'>
          <div>
          {requreUserPermission([EMPLOYEE_CONTRACT]) &&<Link to={privateRoute.employeeContract.url?.({ id: _id })!}>
              <Button variant='contained'>
                <Trans>Contract Management</Trans>
              </Button>
            </Link>}
          </div>
          <div className='space-x-3'>
          {requreUserPermission([EMPLOYEE_APPRAISAL]) &&<Link to={privateRoute.employeeAssignmentChecklist.url?.({ id: _id })!}>
              <Button variant='contained'>
                <Trans>Assignment Checklist</Trans>
              </Button>
            </Link>}
          </div>
        </div>
        {requreUserPermission([EMPLOYEE_LIST_REPORT]) && <EmployeeReportTable employee={employee} />}
        {requreUserPermission([EMPLOYEE_VIEW]) && <EmployeeInfo employee={employee} />}
        {requreUserPermission([EMPLOYEE_VIEW]) && <EmployeeWorking />}
        {requreUserPermission([EMPLOYEE_VIEW]) && <EmployeeBankAccount employee={employee} />}
        {requreUserPermission([EMPLOYEE_VIEW]) && <EmployeeSeniorUserTable employee={employee} />}
      </div>
    </Paper>
  );
};

export default EmployeeView;
