import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearch, usePermission } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService } from 'services';
import { getEnterpriseStatus } from 'constants/enterprise';
import { EnterpriseType } from 'types/Enterprise';
import { ReportCreatePopup } from 'views/Enterprise/EnterpriseView/popups';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';

const EnterpriseReportTable = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { id: _id } = useParams() as { id: string };

  const [dataSearch, onSearchChange] = useSearch({ _id });
  const [isOpen, setOpen] = useState(false);
  const { ENTERPRISE_REPORT, requreUserPermission, showAddReport } = usePermission();
  const { data } = useQuery(
    ['enterpriseService.fetchEnterpriseReports', dataSearch],
    () => enterpriseService.fetchEnterpriseReports(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Client Report</Trans>
          </Typography>
          {requreUserPermission([ENTERPRISE_REPORT]) && showAddReport() && <Button variant='outlined' size='small' onClick={() => setOpen(true)}>
            <Trans>Add Report</Trans>
          </Button>}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Created At</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Meeting Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Note</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy HH:mm')}</TableCell>
                <TableCell>{getEnterpriseStatus(item.status)}</TableCell>
                <TableCell>
                  {item.meetingDate ? DateTime.fromISO(item.meetingDate).toFormat('dd/MM/yyyy hh:mm a') : ''}
                </TableCell>
                <TableCell>{item.note}</TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='xs' open={isOpen} onClose={() => setOpen(false)}>
        <ReportCreatePopup enterprise={enterprise!} onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default EnterpriseReportTable;
