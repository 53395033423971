import { Grid, Paper, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { TopBusiness } from '.';

const TopGroup = () => {
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>KHÁCH HÀNG & ĐỐI TÁC</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={6} className='text-center'>
            <Typography variant='h5'>
              <Trans>Doanh nghiệp có Giá trị Giao dịch cao nhất</Trans>
            </Typography>
            <TopBusiness type='ENTERPRISE' />
          </Grid>
          <Grid item sm={6} className='text-center'>
            <Typography variant='h5'>
              <Trans>Người Lao Động có Giá trị Giao dịch cao nhất</Trans>
            </Typography>
            <TopBusiness type='EMPLOYEE' />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default TopGroup;
