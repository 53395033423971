import React from 'react';
import { Trans, t } from '@lingui/macro';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  Paper,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
} from '@mui/material';
import {
  OverdueCompletedStatus,
  OverdueStatus,
  PaidStatus,
  PayingStatus,
  RevisedStatus,
  SettledStatus,
  WaitCollectionApprovalStatus,
  WaitSettlementApprovalStatus,
  WaitSettlementStatus,
} from 'constants/transaction';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { TransParamsType, TransType } from 'types/Transaction';
import { DateTime } from 'luxon';
import { transactionService } from 'services';
import { TableEmpty } from 'components';
import { formatNumber } from 'utils/common';
import TransHisListSummary from './TransHisListSummary';
import { getTransactionStatus } from 'constants/transaction';
import { TransIncomeNoticesPopup } from 'views/Transaction/TransView/popups';
import { TransReviseHisPopup } from 'views/Transaction/Employee/popups';
import SelectPageSize from 'components/SelectPageSize';

const TransHisList = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpenRevise, setOpenRevise] = useState(false);
  const [transaction, setTransaction] = useState<TransType>();
  const STATUSES = [
    { value: ' ', label: t`All` },
    {
      value: `${PayingStatus}`,
      label: t`Waiting for payment`,
    },
    {
      value: `${PaidStatus},${WaitSettlementStatus},${WaitSettlementApprovalStatus},${RevisedStatus}`,
      label: t`Waiting for settlement`,
    },
    { value: `${OverdueCompletedStatus}`, label: t`Overdue Completed` },
    { value: `${OverdueStatus},${WaitCollectionApprovalStatus}`, label: t`Overdue` },
    { value: `${SettledStatus}`, label: t`Settled` },
  ];
  const [dataSearch, onSearchChange] = useSearch({ sum: 1 });
  const [searchParams, setSearchParams] = useState({});

  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  const { control, handleSubmit } = useForm();
  const handleClickSearch = () => {
    handleSubmit((values: TransParamsType) => {
      type ValuesType = TransParamsType & {
        salaryDateFrom: DateTime;
        salaryDateTo: DateTime;
      };
      const { salaryDateFrom, salaryDateTo, ...others } = values as ValuesType;
      setSearchParams({
        ...others,
        salaryDateFrom: salaryDateFrom ? salaryDateFrom.toISO() : '',
        salaryDateTo: salaryDateTo ? salaryDateTo.toISO() : '',
      });
    })();
  };
  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item sm={3}>
        <Controller
          name='statuses'
          defaultValue=' '
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} fullWidth select label={t`Status`} error={invalid} helperText={error?.message}>
              {STATUSES.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Grid>

      <Grid item sm={3}>
        <Controller
          name='salaryDateFrom'
          defaultValue=''
          control={control}
          rules={{
            validate: { isValid: (value: DateTime) => !value || value.isValid },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <DesktopDatePicker
              value={value}
              onChange={onChange}
              renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
              label={t`Salary Date From`}
              inputFormat='dd/MM/yyyy'
            />
          )}
        />
      </Grid>
      <Grid item sm={3}>
        <Controller
          name='salaryDateTo'
          defaultValue=''
          control={control}
          rules={{
            validate: { isValid: (value: DateTime) => !value || value.isValid },
          }}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <DesktopDatePicker
              value={value}
              onChange={onChange}
              renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
              label={t`Salary Date To`}
              inputFormat='dd/MM/yyyy'
            />
          )}
        />
      </Grid>
      <Grid item sm={3}>
        <Controller
          name='transactionCode'
          defaultValue=''
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField {...field} fullWidth label={t`Transaction ID`} error={invalid} helperText={error?.message} />
          )}
        />
      </Grid>
      <Grid item sm={12} className='flex items-center space-x-3'>
        <LoadingButton variant='contained' onClick={handleClickSearch}>
          <Trans>Search</Trans>
        </LoadingButton>
      </Grid>
      <Grid item sm={12}>
        <TransHisListSummary summary={data?.summary} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Salary Payment Date</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Transaction ID</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Request Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Payment Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Payment at Maturity (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Overdue Unpaid Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Transaction Notice</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Revision Notice</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{getTransactionStatus(item.status)}</TableCell>
                  <TableCell align='center'>
                    {item.salaryPaymentDate ? DateTime.fromISO(item.salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                  </TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.paymentAmount)}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.maturityPaymentAmount)}</TableCell>
                  <TableCell className='text-right'>{formatNumber(item.overdueUnpaidAmount)}</TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setTransaction(item);
                        setOpen(true);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                  <TableCell align='center'>
                    {item.status === RevisedStatus && (
                      <Button
                        size='small'
                        color='info'
                        onClick={() => {
                          setTransaction(item);
                          setOpenRevise(true);
                        }}
                      >
                        <Trans>View</Trans>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>
        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </Grid>
      <Dialog fullWidth maxWidth='lg' open={isOpenRevise} onClose={() => setOpenRevise(false)}>
        <TransReviseHisPopup transaction={transaction!} onClose={() => setOpenRevise(false)} />
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpen} onClose={() => setOpen(false)}>
        <TransIncomeNoticesPopup transaction={transaction!} view='EMPLOYEE' onClose={() => setOpen(false)} />
      </Dialog>
    </Grid>
  );
};

export default TransHisList;
