import { Trans } from '@lingui/macro';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getEnterpriseApplyFeeType } from 'constants/enterprise';
import { useQuery } from 'react-query';
import { employeeSetupService } from 'services';
import { formatNumber } from 'utils/common';

type PopupProps = {
  onBack: () => void;
  enterpriseEmployeeId: number;
  employeeId: string;
  pasId: number;
};

const PartTimePopupStep4 = ({ pasId, enterpriseEmployeeId, employeeId, onBack }: PopupProps) => {
  const {
    data: {
      currentHourlyLabour,
      averageHourLabour,
      averageSalaryDay,
      totalEarnedLabour,
      earnedSalary,
      advanceRatio,
      appliedEnterpriseFeeEn,
      employeeSupportDiscountRate,
      advanceLimit,
      earnedDiscountRate,
    } = {},
  } = useQuery(
    ['employeeSetupService.getPayrollAdvanceSettingEachEnterprise', { employeeId, enterpriseEmployeeId, pasId }],
    () => employeeSetupService.getPayrollAdvanceSettingEachEnterprise({ employeeId, enterpriseEmployeeId, pasId }),
  );

  return (
    <>
      <DialogTitle>
        <Trans>CREDIT SETUP FOR PART-TIME EMPLOYEE</Trans>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h4'>
          <Trans>Payroll Advance Setting</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={6} xl={6}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Current Hourly Labour</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(currentHourlyLabour) ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>VND</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Average Hour/Labour</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={averageHourLabour ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Hours</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Average Salary/Day</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={averageSalaryDay ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>VND</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Total Earned Labour</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(totalEarnedLabour) ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Labour</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Earned Salary</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={formatNumber(earnedSalary) ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>VND</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={advanceRatio ?? 0} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Applied Enterprise's Fee</Trans>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={getEnterpriseApplyFeeType(appliedEnterpriseFeeEn)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Employee Support Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={employeeSupportDiscountRate ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>(% Year)</Trans>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} lg={6} xl={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Trans>APPROVAL DECISION</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Advance Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>{formatNumber(advanceLimit)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{earnedDiscountRate}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </Button>
        </div>
      </DialogActions>
    </>
  );
};

export default PartTimePopupStep4;
