import { Trans, t } from '@lingui/macro';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { employeeSetupService } from 'services';
import { SupportProgramType } from 'types/EmployeeSetup';
import { formatNumber } from 'utils/common';
import { UpsertEmployeeKeyProgramPopup, UpsertEmployeeRegularProgramPopup } from 'views/ClientSetup/Employee/popups';

const EmployeeSupportProgram = ({ id }: { id: string }) => {
  const [dataSearch, onSearchChange] = useSearch();
  const [isOpen, setOpen] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<SupportProgramType>();

  const { data } = useQuery(
    ['employeeSetupService.getEmployeeSupportPrograms', { employeeId: id, ...dataSearch }],
    () => employeeSetupService.getEmployeeSupportPrograms({ employeeId: id, ...dataSearch }),
    {
      keepPreviousData: true,
    },
  );

  const { data: items = [], paginate } = data ?? {};

  return (
    <div className='mt-6'>
      <Typography variant='h4'>
        <Trans>Employee Support Program</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Default Support Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Available Support Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>% of Enterprise support</Trans>
              </TableCell>
              <TableCell>
                <Trans>Edit</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.enterpriseEmployeeId}>
                <TableCell>{item.enterpriseName}</TableCell>
                <TableCell>{item.isKey === 1 ? t`Key Employee` : t`Regular Employee`}</TableCell>
                <TableCell align='right'>{formatNumber(item.defaultSupportLimit)}</TableCell>
                <TableCell align='right'>{formatNumber(item.availableSupportLimit)}</TableCell>
                <TableCell align='right'>
                  {formatNumber(item.supportRate)} {item.supportRate ? '%' : ''}
                </TableCell>
                <TableCell align='center'>
                  {item.editable && (
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpen(true);
                        setCurrentProgram(item);
                      }}
                    >
                      <Trans>Edit</Trans>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog maxWidth='md' open={isOpen} onClose={() => setOpen(false)}>
        {currentProgram?.isKey === 1 ? (
          <UpsertEmployeeKeyProgramPopup
            employeeId={parseInt(id)}
            enterpriseEmployeeId={currentProgram?.enterpriseEmployeeId!}
            programId={currentProgram?.id!}
            onClose={() => {
              setOpen(false);
            }}
          />
        ) : (
          <UpsertEmployeeRegularProgramPopup
            employeeId={parseInt(id)}
            enterpriseEmployeeId={currentProgram?.enterpriseEmployeeId!}
            programId={currentProgram?.id!}
            onClose={() => {
              setOpen(false);
            }}
          />
        )}
      </Dialog>
    </div>
  );
};

export default EmployeeSupportProgram;
