import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  id: string;
};

const CreditScoreRemovePopup = ({ id, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mutate: removeScore, isLoading } = useMutation(creditScoreService.removeCreditScore, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.fetchCreditScores');
      onClose();
      navigate(privateRoute.creditScoreSetup.path, { replace: true });
    },
  });

  const handleClickSubmit = () => {
    removeScore({ id });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Remove Credit Score</Trans>
      </DialogTitle>

      <DialogContent>
        <Typography>
          <Trans>Are your sure you want to remove this credit score?</Trans>
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' color='error' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Remove</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreditScoreRemovePopup;
