import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Dialog, Step, StepLabel, Stepper } from '@mui/material';
import { DigitalSign } from 'components';
import { PcTypeSignType } from 'constants/common';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { enterpriseService, fileService, queryClient } from 'services';
import { usePermission } from 'hooks';
import { PopupController } from 'types/Common';
import { SignedDataType } from 'types/DigitalSign';
import { ContractType } from 'types/EnterpriseContract';
import ContractRejectPopup from './ContractRejectPopup';
import PdfViewer from 'components/PdfViewer';

type PopupProps = PopupController & {
  contract: ContractType;
  enterpriseId: string;
};

const ContractSignPopup = ({ contract, enterpriseId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { signable } = usePermission();
  const [isOpenReject, setOpenReject] = useState(false);
  const [isOpenSign, setOpenSign] = useState(false);
  const { data: dataFile, isSuccess: isSuccessLoadFile } = useQuery(
    ['fileService.downloadSignatureFile', { key: contract?.object?.key! }],
    () => fileService.downloadSignatureFile(contract?.object?.key!),
  );

  const { mutate: approveContract, isLoading: isLoadingApprove } = useMutation(
    enterpriseService.approveEnterpriseContract,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.getEnterpriseLatest');
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseContract');
        onClose();
      },
    },
  );

  const handleSignSuccess = (signedObjects: SignedDataType[]) => {
    // console.log(signedObjects);
    approveContract({
      _id: enterpriseId,
      id: contract.id!,
      objectId: signedObjects[0].objectId,
      checkSignContract: true,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Enterprise Contract</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={1}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        <PdfViewer objectKey={contract?.object?.key} />
      </DialogContent>

      <DialogActions className='relative' style={{ height: '55px' }}>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>

        {signable() && isSuccessLoadFile && (
          <>
            <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
              <Trans>Reject</Trans>
            </LoadingButton>
            <LoadingButton
              variant='contained'
              className='w-28'
              loading={isLoadingApprove}
              onClick={() => setOpenSign(true)}
            >
              <Trans>Sign</Trans>
            </LoadingButton>
          </>
        )}
      </DialogActions>
      <Dialog fullWidth maxWidth='md' open={isOpenSign} onClose={() => setOpenSign(false)}>
        <DigitalSign
          onClose={() => setOpenSign(false)}
          documents={[{ ...dataFile!, key: 'contract' }]}
          signType={PcTypeSignType}
          onSigned={(signedObjects) => handleSignSuccess(signedObjects)}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <ContractRejectPopup
          contract={contract}
          enterpriseId={enterpriseId}
          onClose={() => {
            setOpenReject(false);
            onClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default ContractSignPopup;
