import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { employeeSetupService } from 'services';
import { SupportProgramHisType } from 'types/EmployeeSetup';
import { formatNumber } from 'utils/common';
import {
  UpsertEmployeeKeyProgramPopup,
  UpsertEmployeeRegularProgramPopup,
  ViewEmployeeProgramPopup,
} from 'views/ClientSetup/Employee/popups';

const EmployeeSupportProgramHis = ({ id }: { id: string }) => {
  const [dataSearch, onSearchChange] = useSearch();
  const [isOpen, setOpen] = useState(false);
  const [currentProgram, setCurrentProgram] = useState<SupportProgramHisType>();

  const { data } = useQuery(
    ['employeeSetupService.getEmployeeHisSupportPrograms', { employeeId: id, ...dataSearch }],
    () => employeeSetupService.getEmployeeHisSupportPrograms({ employeeId: id, ...dataSearch }),
    {
      keepPreviousData: true,
    },
  );

  const { data: items = [], paginate } = data ?? {};

  return (
    <div className='mt-6'>
      <Typography variant='h4'>
        <Trans>Setting History</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Execution Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Executor</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Support Limit (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>% of Enterprise support</Trans>
              </TableCell>
              <TableCell>
                <Trans>Approver</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center'>
                  {item.executedAt ? DateTime.fromISO(item.executedAt).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.executedUser}</TableCell>
                <TableCell>{item.enterpriseName}</TableCell>
                <TableCell align='right'>{formatNumber(item.defaultSupportLimit)}</TableCell>
                <TableCell align='right'>{formatNumber(item.supportRate)} %</TableCell>
                <TableCell>{item.approvedUser}</TableCell>
                <TableCell>
                  {item.status === 'WAITING_FOR_APPLY' ? (
                    <Trans>Waiting for approval</Trans>
                  ) : item.status === 'APPLIED' ? (
                    <Trans>Approved</Trans>
                  ) : (
                    <Trans>Rejected</Trans>
                  )}
                </TableCell>
                <TableCell align='center'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setCurrentProgram(item);
                    }}
                  >
                    {item.status === 'WAITING_FOR_APPLY' ? <Trans>Approve</Trans> : <Trans>View</Trans>}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={isOpen} onClose={() => setOpen(false)}>
        {currentProgram?.status === 'WAITING_FOR_APPLY' &&
          (currentProgram?.isKey === 1 ? (
            <UpsertEmployeeKeyProgramPopup
              employeeId={parseInt(id)}
              enterpriseEmployeeId={currentProgram?.enterpriseEmployeeId!}
              programId={currentProgram?.id!}
              onClose={() => {
                setOpen(false);
              }}
            />
          ) : (
            <UpsertEmployeeRegularProgramPopup
              employeeId={parseInt(id)}
              enterpriseEmployeeId={currentProgram?.enterpriseEmployeeId!}
              programId={currentProgram?.id!}
              onClose={() => {
                setOpen(false);
              }}
            />
          ))}
        {currentProgram?.status !== 'WAITING_FOR_APPLY' && (
          <ViewEmployeeProgramPopup
            employeeId={parseInt(id)}
            enterpriseEmployeeId={currentProgram?.enterpriseEmployeeId!}
            programId={currentProgram?.id!}
            onClose={() => {
              setOpen(false);
            }}
          />
        )}
      </Dialog>
    </div>
  );
};

export default EmployeeSupportProgramHis;
