import { t, Trans } from '@lingui/macro';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Button,
  TableBody,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import { formatNumber } from 'utils/common';

const PaymentSchedule = () => {
  return (
    <div className='mt-4'>
      <Typography variant='h4'>
        <Trans>Payment Schedule</Trans>
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid item sm={4}>
          <div className='mt-4 space-x-4 flex items-center'>
            <DesktopDatePicker
              value={DateTime.now()}
              onChange={() => {}}
              renderInput={(props) => <TextField {...props} />}
              label={t`From`}
              inputFormat='dd/MM/yyyy'
            />
            <DesktopDatePicker
              value={DateTime.now()}
              onChange={() => {}}
              renderInput={(props) => <TextField {...props} />}
              label={t`To`}
              inputFormat='dd/MM/yyyy'
            />
          </div>
        </Grid>
        <Grid item sm={8} className='flex space-x-4'>
          <div className='border rounded-lg p-4 flex flex-col justify-center items-center'>
            <Typography variant='subtitle2'>
              <Trans>Total settlement amount receivable (VND)</Trans>
            </Typography>
            <Typography>50</Typography>
          </div>
          <div className='border rounded-lg p-4 flex flex-col justify-center items-center'>
            <Typography variant='subtitle2'>
              <Trans>Total fees receivable (VND)</Trans>
            </Typography>
            <Typography>50</Typography>
          </div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>STT</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Amount Receivable</TableCell>
            <TableCell>Detail</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className='text-center'>1</TableCell>
              <TableCell className='text-center'>22/03/2022</TableCell>
              <TableCell className='text-center'>Full</TableCell>
              <TableCell className='text-center'>Company A</TableCell>
              <TableCell className='text-center'>{formatNumber(1000)}</TableCell>
              <TableCell className='text-center'>
                <Button size='small' color='info' onClick={() => {}}>
                  <Trans>View</Trans>
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PaymentSchedule;
