import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { AppendixDigitalRejectType } from 'types/EnterpriseSetup';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseId: string;
  appendixId: number;
  isCancel?: boolean;
};

const RejectPopup = ({ enterpriseId, appendixId, isCancel = false, onNext, onBack }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: skipDigitalAppendix, isLoading } = useMutation(
    isCancel ? enterpriseSetupService.cancelDigitalAppendix : enterpriseSetupService.rejectDigitalAppendix,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseServiceFeeHistories');
        onNext();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values: AppendixDigitalRejectType) => {
      skipDigitalAppendix({
        ...values,
        enterpriseId,
        appendixId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Reason Reject</Trans>
      </DialogTitle>

      <DialogContent>
        <Controller
          name='reason'
          defaultValue=''
          control={control}
          rules={{ required: t`Reason is required` }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              required
              label={t`Reason`}
              error={invalid}
              helperText={error?.message}
            ></TextField>
          )}
        />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' onClick={handleClickSubmit} loading={isLoading}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default RejectPopup;
