import { Trans } from '@lingui/macro';

import {
  Button,
  debounce,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { EnterpriseType } from 'types/Enterprise';
import { formatNumber } from 'utils/common';
import { ViewFile } from 'views/Transaction/TransPaying/TransNotify'
// import {
//   EnterpriseAssignmentPopup,
//   EnterpriseCreatePopup,
//   EnterpriseCreateSelectPopup,
// } from 'views/Enterprise/EnterpriseList/popups';
const FeePaymentNoticeList = () => {
  const [searchParams, setSearchParams] = useState({});

  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<EnterpriseType[]>([]);
  const [isOpenView, setOpenView] = useState(false);
  const [objectKey, changeobjectKey] = useState('');
  const { data } = useQuery(
    ['transactionService.listFeePaymentNotice', dataSearch],
    () => transactionService.listFeePaymentNotice(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Notice No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee before tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Service Fee after tax (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>DeadLine</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Account</Trans>
              </TableCell>
              <TableCell>
                <Trans>View</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, idx) => (

              <TableRow key={item.id}>
                <TableCell align='center' className='p-1'>
                  {(idx + 1) + ((paginate?.page ? paginate?.page : 1) - 1) * (paginate?.size ? paginate?.size : 10)}
                </TableCell>
                <TableCell>{item.signedAt ? DateTime.fromISO(item.signedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{formatNumber(item.feeBeforeTax)}</TableCell>
                <TableCell>{formatNumber(item.feeAfterTax)}</TableCell>
                <TableCell>{item.signedAt ? DateTime.fromISO(item.signedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{item.bankAccountNumber} - {item.bankName} - {item.bankBranchName}</TableCell>
                <TableCell className='text-right'>
                  <Button size='small' color='info' onClick={() => { changeobjectKey(item.object.key); setOpenView(true); }}>
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>
      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
            setDataSelect([]);
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => { onSearchChange({ ...dataSearch, page: value }); setDataSelect([]); }}
        />
      </div>
      <Dialog fullWidth maxWidth='md' open={isOpenView} onClose={() => setOpenView(false)}>
        {<ViewFile
          keykhac={objectKey}
          title='FEE PAYMENT NOTICE'
          onClose={() => setOpenView(false)}
        />}
      </Dialog>
    </div >
  );
};

export default FeePaymentNoticeList;
