import { LocalizationProvider } from '@mui/lab';
import { default as DateAdapter } from '@mui/lab/AdapterLuxon';
import { createTheme, ThemeProvider } from '@mui/material';
import { IS_EPAYZ } from 'env';
import { useNotification } from 'hooks';

export const appTheme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: 'div',
          body2: 'div',
          inherit: 'div',
        },
      },
      styleOverrides: {
        h1: { fontSize: 32, fontWeight: 600 },
        h2: { fontSize: 24, fontWeight: 600 },
        h3: { fontSize: 20, fontWeight: 600 },
        h4: { fontSize: 18, fontWeight: 600 },
        h5: { fontSize: 16, fontWeight: 600 },
        h6: { fontSize: 14, fontWeight: 600 },
        subtitle1: { fontSize: 16, fontWeight: 700, lineHeight: 1.5 },
        subtitle2: { fontSize: 14, fontWeight: 700, lineHeight: 1.43 },
        body1: { fontSize: 16 },
        body2: { fontSize: 14 },
        caption: { fontSize: 12 },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        containedPrimary: {
          background: IS_EPAYZ ? '#123456' : '#123456',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
        shape: 'rounded',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiModal: {
      defaultProps: {
        classes: {
          root: IS_EPAYZ ? 'epayz' : 'admin',
        },
      },
    },
  },
  typography: {
    fontFamily: IS_EPAYZ ? `Arial` : `Montserrat`,
  },
  palette: {
    primary: {
      main: IS_EPAYZ ? '#b91c1c' : '#1c2b4d',
    },
  },
});

const Theme = ({ children }: any) => {
  useNotification();
  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={DateAdapter}>{children}</LocalizationProvider>
    </ThemeProvider>
  );
};

export default Theme;
