import { Trans } from '@lingui/macro';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { LoadingButton } from '@mui/lab';
import { EnterpriseServiceFeeNoticeCreateOrGenerateParamsType } from 'types/EnterpriseSetup';

type PopupProps = {
  data: string;
  noticeCreate: EnterpriseServiceFeeNoticeCreateOrGenerateParamsType;
  onBack: () => void;
  onNext: () => void;
};

const NoticeCreatePopupStep2 = ({ data, noticeCreate, onBack, onNext }: PopupProps) => {
  const { mutate: createEnterpriseFeeNotice, isLoading } = useMutation(
    enterpriseSetupService.createEnterpriseFeeNotice,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseFeeNotices');
        onNext();
      },
      onError: () => {
        onNext();
      },
    },
  );

  const handleClickSubmit = () => {
    createEnterpriseFeeNotice(noticeCreate);
  };

  return (
    <>
      <DialogTitle>
        <Trans>NOTICE OF SINGLE FEE PAYMENT</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
          {data && <embed className='w-full h-full' src={`data:application/pdf;base64,${data}`} />}
        </div>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' color='inherit' onClick={onBack}>
            <Trans>Back</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' onClick={handleClickSubmit} loading={isLoading}>
            <Trans>Confirm</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default NoticeCreatePopupStep2;
