import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Chip,
  Dialog,
} from '@mui/material';
import { PopupController } from 'types/Common';
import { Controller, useForm } from 'react-hook-form';
import { enterpriseSetupService, queryClient, fileService } from 'services';
import { useMutation } from 'react-query';
import { EnterpriseServiceFeePaymentCollectionSaveType, EnterpriseWaitingPaymentType } from 'types/EnterpriseSetup';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { Upload } from '@mui/icons-material';
import { ObjectType } from 'types/Object';
import { ActionConfirmPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { InputNumber } from 'components';

type PopupProps = PopupController & {
  dataSelect: EnterpriseWaitingPaymentType[];
  enterpriseId: string;
  setDataSelect: (value: any) => void;
};
const WaitingPaymentCollectFeePopup = ({ enterpriseId, dataSelect, setDataSelect, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, getValues } = useForm({ mode: 'onChange' });
  const totalCollectionFee = dataSelect.reduce((sum, val) => sum + val.serviceFeeAfterTax, 0);

  const [objects, setObjects] = useState<ObjectType[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [actionConfirmPopup, setActionConfirmPopup] = useState(false);

  const { mutate: uploadFiles, isLoading: isLoadingUpload } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjects((items) => items.concat(data.objects));
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(event.target.files!).forEach((file) => {
      formData.append('files', file);
    });
    uploadFiles(formData);
  };

  const { mutate, isLoading } = useMutation(enterpriseSetupService.confirmFeePayment, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      setDataSelect([]);
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseWaitingPaymentData');
      queryClient.invalidateQueries('enterpriseSetupService.getEnterprisePaidPaymentData');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = EnterpriseServiceFeePaymentCollectionSaveType & { collectionDate: DateTime };
      const { collectionDate } = values as ValuesType;

      mutate({
        collectionDate: collectionDate.toISO(),
        objectIds: objects.map((item) => item.id),
        ids: dataSelect.map((item) => item.id),
        enterpriseId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>CONFIRM COLLECTION</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Total Collection Fee (VND)</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <TextField
                        fullWidth
                        value={totalCollectionFee}
                        InputProps={{
                          readOnly: true,
                          inputComponent: InputNumber as any,
                          inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Collection Date</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <Controller
                        name='collectionDate'
                        defaultValue={DateTime.now()}
                        control={control}
                        rules={{ required: t`Collection Date is required`, validate: { isValid: (value: DateTime) => value && value.isValid } }}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            value={value}
                            onChange={onChange}
                            renderInput={(props) => <TextField {...props} fullWidth error={invalid} />}
                            inputFormat='dd/MM/yyyy'
                            disablePast
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12}>
            <input ref={inputRef} type='file' hidden multiple onChange={handleChangeFiles} accept="image/jpg,image/jpeg,image/png,application/pdf" />
            <LoadingButton
              variant='outlined'
              size='small'
              startIcon={<Upload />}
              loading={isLoadingUpload}
              onClick={() => inputRef.current?.click()}
            >
              <Trans>Choose Files</Trans>
            </LoadingButton>

            <div className='flex flex-wrap gap-2 mt-2'>
              {objects.map((file) => (
                <Chip
                  key={file.id}
                  variant='outlined'
                  label={file.originalName}
                  onDelete={() => setObjects((items) => items.filter((item) => item.id !== file.id))}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' onClick={onClose}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton
            variant='contained'
            disabled={objects.length === 0 || isLoading || !getValues("collectionDate")}
            onClick={() => setActionConfirmPopup(true)}
          >
            <Trans>Confirm</Trans>
          </LoadingButton>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={actionConfirmPopup} onClose={() => setActionConfirmPopup(false)}>
        <ActionConfirmPopup
          isLoading={isLoading}
          handleAction={handleClickSubmit}
          onClose={() => setActionConfirmPopup(false)}
        />
      </Dialog>
    </>
  );
};

export default WaitingPaymentCollectFeePopup;
