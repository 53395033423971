import { Trans } from '@lingui/macro';
import { DialogActions, DialogContent, DialogTitle, Dialog, Button } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { useState } from 'react';
import { RejectPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { AppendixObjectDataType } from 'types/EnterpriseSetup';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseId: string;
  appendixId: number;
  objectData: AppendixObjectDataType;
};

const FixedServicePopupStep6 = ({ enterpriseId, appendixId, objectData, onBack }: PopupProps) => {
  const [isOpenReject, setOpenReject] = useState(false);

  return (
    <>
      <DialogTitle>
        <Trans>APPENDIX OF FIXED SERVICE FEE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={objectData.objectKey} />
      </DialogContent>

      <DialogActions>
        {/* <div className='flex items-center justify-center w-full'>
          <Button variant='outlined' color='error' onClick={() => setOpenReject(true)}>
            <Trans>Cancel</Trans>
          </Button>
        </div> */}
        <Button variant='outlined' color='info' onClick={onBack}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          enterpriseId={enterpriseId}
          appendixId={appendixId}
          isCancel={true}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
    </>
  );
};

export default FixedServicePopupStep6;
