import { useState, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
} from '@mui/material';
import { PopupController } from 'types/Common';
import { Upload } from '@mui/icons-material';
import { templateService, queryClient } from 'services';
import { downloadBase64 } from 'utils/common';

type PopupProps = PopupController & {};

const HRUpdatePopup = ({ onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const listTypeEmployee = [
    {
      title: t`Full-time Employee`,
      isChecked: false,
      templateUrl: 'import-full-time-employee',
      importUrl: 'full-time',
    },
    {
      title: t`Part-time Employee`,
      isChecked: false,
      templateUrl: 'import-part-time-employee',
      importUrl: 'part-time',
    },
    {
      title: t`Retired Employee`,
      isChecked: false,
      templateUrl: 'import-stop-working-employee',
      importUrl: 'stop-working',
    },
    {
      title: t`Key Employee`,
      isChecked: false,
      templateUrl: 'import-key-employee',
      importUrl: 'key',
    },
  ];

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [fileSelected, setFileSelected] = useState<File>();
  const [isLoading, changeisLoading] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleClickSubmit = () => {
    if (fileSelected && value) {
      changeisLoading(true);
      const formData = new FormData();
      formData.append('file', fileSelected, fileSelected.name);
      templateService.uploadFileTemplate(value, formData).then(() => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchEmployees');
        queryClient.invalidateQueries('enterpriseSetupService.fetchUpdatedEmployees');
        onClose();
      }).finally(() => {
        changeisLoading(false);
      })
    } else {
      enqueueSnackbar(t`Please select file or choose template`, { variant: 'warning' });
    }
  };

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    console.log(fileList[0]);
    if (fileList[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      enqueueSnackbar(`  File import không đúng định dạng`, { variant: 'warning' });
      return;
    }
    setFileSelected(fileList[0]);
  };
  return (
    <>
      <DialogTitle>
        <Trans>UPDATE EMPLOYEE INFORMATION</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-center justify-end space-x-3'>
          <Typography variant='h6'>
            <Trans>Download the samples</Trans>
          </Typography>
          {listTypeEmployee.map((item, index) => {
            return (
              <Button
                key={index}
                size='small'
                color='info'
                onClick={() => {
                  templateService.downloadFileBase64(item.templateUrl).then((res) => {
                    downloadBase64(res);
                  });
                }}
              >
                <Trans>{item.title}</Trans>
              </Button>
            );
          })}
        </div>
        <RadioGroup name='employee-type' value={value} onChange={handleChange}>
          <Grid container columnSpacing={2} className='my-6'>
            {listTypeEmployee.map((item, index) => {
              return (
                <Grid item md={6} lg={3} xl={3} key={index}>
                  <div className='border flex justify-center items-center py-4 rounded-lg'>
                    <FormControlLabel value={item.importUrl} control={<Radio />} label={t`${item.title}`} />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </RadioGroup>
        <div className='flex justify-between'>
          <input ref={inputRef} type='file' accept='.xlsx,.xls' hidden onChange={handleChangeFiles} />
          <div className='space-x-3'>
            <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
              <Trans>Choose Files</Trans>
            </LoadingButton>
            {fileSelected && (
              <Chip
                key={fileSelected?.name}
                variant='outlined'
                label={fileSelected?.name}
                onDelete={() => setFileSelected(undefined)}
              />
            )}
          </div>

          <LoadingButton
            variant='contained'
            size='small'
            disabled={!fileSelected}
            startIcon={<Upload />}
            onClick={handleClickSubmit}
            loading={isLoading}
          >
            <Trans>Upload</Trans>
          </LoadingButton>
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default HRUpdatePopup;
