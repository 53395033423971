import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { Typography, TextField } from '@mui/material';
import { useQuery, useMutation } from 'react-query';
import { employeeSetupService, queryClient } from 'services';
import { InputNumber } from 'components';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import { UpdateEmployeePenalRateType } from 'types/EmployeeSetup';

const EmployeeOverdueDebt = ({ id }: { id: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  useQuery(
    ['employeeSetupService.getExpectedPenalRate', { id }],
    () => employeeSetupService.getExpectedPenalRate({ id }),
    {
      onSuccess: (data) => {
        Object.entries(data ?? {}).forEach(([key, value]) => {
          if (key === 'penalRateType1') {
            setValue('penalRateType_1', value);
          }
        });
      },
    },
  );

  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  const { mutate: updateExpectedPenalRate, isLoading } = useMutation(employeeSetupService.updateExpectedPenalRate, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeSetupService.getExpectedPenalRate');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit(({ penalRateType_1 }: UpdateEmployeePenalRateType) => {
      updateExpectedPenalRate({ id, penalRateType_1 });
    })();
  };

  return (
    <div className='mt-6'>
      <div className='flex items-center space-x-3 mt-1'>
        <Typography variant='h4'><Trans>Penal Rate Type 1 (APR)</Trans></Typography>
        <Controller
          name='penalRateType_1'
          defaultValue=''
          control={control}
          rules={{ min: 0 }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              error={invalid}
              InputProps={{
                inputComponent: InputNumber as any,
              }}
            />
          )}
        />
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Apply</Trans>
        </LoadingButton>
      </div>
    </div>
  );
};

export default EmployeeOverdueDebt;
