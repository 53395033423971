import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { usePermission } from 'hooks';
import { useParams } from 'react-router-dom';
import { enterpriseService } from 'services';
import { EnterpriseBankAccount, EnterpriseViewInfo, EnterpriseInfo } from 'views/Enterprise/EnterpriseView/components';

const AccountEnterprise = () => {
  const { requireUserPCManager } = usePermission();
  const { id: _id = 'me' } = useParams() as { id: string };

  const { data: enterprise } = useQuery(['enterpriseService.getEnterpriseById', { id: _id }], () =>
    enterpriseService.getEnterpriseById({ id: _id }),
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Company Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='-mt-6'></div>
        {requireUserPCManager() ? (
          <EnterpriseInfo enterprise={enterprise} />
        ) : (
          <EnterpriseViewInfo enterprise={enterprise} />
        )}
        <EnterpriseBankAccount enterprise={enterprise} />
      </div>
    </Paper>
  );
};

export default AccountEnterprise;
