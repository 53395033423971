import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { PopupController } from 'types/Common';
import { formatNumber } from 'utils/common';
import { queryClient, fileService, transactionOverdueService, transactionService } from 'services';
import { ObjectType } from 'types/Object';
import { DateTime } from 'luxon';
import useDebounce from 'hooks/useDebounce';
import { TransCalculatedOverdueType, TransCreateOverdueRecordType } from 'types/TransactionOverdue';

type PopupProps = PopupController & {
  transactionId: number;
  totalUnpaidAmount: number;
};

const TransOverdueAddRecordPopup = ({ transactionId, totalUnpaidAmount, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const [objects, setObjects] = useState<ObjectType[]>([]);
  const [objectIds, setObjectIds] = useState<number[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const [calculcations, setCalculations] = useState<TransCalculatedOverdueType[]>([]);
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(DateTime.now().toISO());
  const [unpaidAmount, setUnpaidAmount] = useState(totalUnpaidAmount);
  const debouncedAmount = useDebounce(amount, 1000);
  const debouncedDate = useDebounce(date, 500);

  useEffect(() => {
    if (debouncedDate && debouncedAmount) {
      transactionOverdueService
        .calculateOverdueTransaction({
          transactionId: transactionId,
          collectionAmount: debouncedAmount,
          collectionDate: debouncedDate,
          allowBankFee: false,
        })
        .then((res : TransCalculatedOverdueType[])=>{
          setCalculations(res);
          var resNow = res.filter(function(x){
            return x.gDate.substring(0,10) == debouncedDate.substring(0,10)
          })
          setUnpaidAmount(resNow[0].hTotalUnpaidAmount);
        });
    }
    if (debouncedDate && !debouncedAmount) {
      transactionOverdueService
        .calculateOverdueTransaction({
          transactionId: transactionId,
          collectionAmount: 0,
          collectionDate: debouncedDate,
          allowBankFee: false,
        })
        .then(data => {
          if (data && data.length > 0) {
            setUnpaidAmount(data[0].hTotalUnpaidAmount);
            var resNow = data.filter(function(x){
              return x.gDate.substring(0,10) == debouncedDate.substring(0,10)
            })
            setUnpaidAmount(resNow[0].hTotalUnpaidAmount);
          }
        });
    }
  }, [debouncedAmount, debouncedDate, transactionId]);

  const { mutate: uploadFiles, isLoading: isLoadingUpload } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjects(data.objects);
      let _objectIds: number[] = [];
      Array.from(data.objects).forEach((o) => {
        _objectIds.push(o.id);
      });
      setObjectIds(_objectIds);
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(event.target.files!).forEach((file) => {
      formData.append('files', file);
    });
    uploadFiles(formData);
  };

  const { mutate: addRecord, isLoading } = useMutation(transactionOverdueService.createOverdueRecordTransaction, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionOverdueService.getOverdueTransaction');
      queryClient.invalidateQueries('transactionService.getTransactionById');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: TransCreateOverdueRecordType) => {
      type ValuesType = TransCreateOverdueRecordType & { collectionDate: DateTime };
      const { collectionDate, ...others } = values as ValuesType;
      addRecord({
        ...others,
        collectionDate: collectionDate.toISO(),
        id: transactionId,
        objectIds,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Collection Tracking of Overdue</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={4}>
            <TextField
              label={t`Total Unpaid Amount (VND)` }
              defaultValue={formatNumber(totalUnpaidAmount)}
              value={formatNumber(unpaidAmount)}
              fullWidth
              InputProps={{
                readOnly: true,
                inputComponent: InputNumber as any,
                inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
              }}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='collectionDate'
              defaultValue={DateTime.now()}
              control={control}
              rules={{
                validate: { isValid: (value: DateTime) => value && value.isValid },
                required: t`Collection Date is required`,
              }}
              render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={(value) => {
                    setDate(value ? value.toISO() : '');
                    setValue(value);
                  }}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Collection Date`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='collectionAmount'
              defaultValue=''
              control={control}
              rules={{ required: t`Collection Amount is required` }}
              render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                <TextField
                  {...value}
                  onChange={(event) => {
                    setAmount(Number(event.target.value));
                    setValue(event);
                  }}
                  label={t`Collection Amount`}
                  required
                  fullWidth
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Trans>Collected Principal Amount (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Collected Cost (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Trans>Collected Penalty (VND)</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculcations?.map((item) => (
                    <TableRow key={item.gDate}>
                      <TableCell className='text-right'>{formatNumber(item?.oCollectedPrincipalAmount)}</TableCell>
                      <TableCell className='text-right'>{formatNumber(item?.pCollectedCost)}</TableCell>
                      <TableCell className='text-right'>{formatNumber(item?.qCollectedPenalty)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  label={t`Note`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <div className='flex items-end space-x-3 h-10'>
              <input ref={inputRef} type='file' hidden multiple onChange={handleChangeFiles} />
              <LoadingButton
                variant='outlined'
                size='small'
                loading={isLoadingUpload}
                onClick={() => inputRef.current?.click()}
              >
                <Trans>Choose File</Trans>
              </LoadingButton>

              {objects.map((file) => (
                <Chip
                  key={file.id}
                  variant='outlined'
                  label={file.originalName}
                  onDelete={() => setObjects((items) => items.filter((item) => item.id !== file.id))}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' onClick={handleClickSubmit} loading={isLoading}>
          <Trans>Request to Approve</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransOverdueAddRecordPopup;
