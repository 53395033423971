import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { DateTime } from 'luxon';
import { TransType } from 'types/Transaction';
import { formatNumber } from 'utils/common';

export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '30%',
  },
  cellValue: {
    width: '20%',
  },
});

const TransSettlementInfo = ({ transaction }: { transaction?: TransType }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              <Trans>Salary Payment Date</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>
              {transaction?.settlement?.salaryPaymentDate
                ? DateTime.fromISO(transaction?.settlement?.salaryPaymentDate).toFormat('dd/MM/yyyy')
                : ''}
            </TableCell>
            <TableCell className={classes.cellTitle}>
              <Trans>Collection Amount from Employee (VND)</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>
              {formatNumber(transaction?.settlement?.collectionAmount)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cellTitle}>
              <Trans>Collected Amount (VND)</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>
              {formatNumber(transaction?.settlement?.collectedAmount)}
            </TableCell>
            <TableCell className={classes.cellTitle}>
              <Trans>Remaining Amount (VND)</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>
              {formatNumber(transaction?.settlement?.remainingAmount)}
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell className={classes.cellTitle}>
              <Trans>Enterprise's Fee</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>{formatNumber(transaction?.settlement?.enterpriseFee)}</TableCell>
            <TableCell className={classes.cellTitle}>
              <Trans>Bank Fee after tax</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>{formatNumber(transaction?.settlement?.bankFee)}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell className={classes.cellTitle}>
              <Trans>Overdue Transfering Amount (VND)</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>{formatNumber(transaction?.settlement?.overdueAmount)}</TableCell>
            <TableCell className={classes.cellTitle}>
              <Trans>Overdue Starting Date</Trans>
            </TableCell>
            <TableCell className={classes.cellValue}>
              {transaction?.settlement?.overdueDate
                ? DateTime.fromISO(transaction?.settlement?.overdueDate).toFormat('dd/MM/yyyy')
                : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransSettlementInfo;
