import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { PopupController } from 'types/Common';
import { TransAddOverdueType, TransType } from 'types/Transaction';
import { formatNumber } from 'utils/common';
import { useStyles } from 'views/Transaction/TransView/components/TransInfo';
import { transactionService, queryClient } from 'services';
import { DateTime } from 'luxon';

type PopupProps = PopupController & {
  transaction: TransType;
};

const TransTransferOverduePopup = ({ transaction, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const classes = useStyles();

  const { mutate: addRecord, isLoading } = useMutation(transactionService.addTransactionOverdue, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.fetchTransactionSettlementRecords');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: TransAddOverdueType) => {
      type ValuesType = TransAddOverdueType & { overdueDate: DateTime };
      const { overdueDate, ...others } = values as ValuesType;
      addRecord({
        ...others,
        overdueDate: overdueDate.toISO(),
        transId: transaction.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Transfer Overdue</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <Trans>Remaining Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue}>
                      {formatNumber(transaction?.settlement?.remainingAmount)}
                    </TableCell>
                    <TableCell className={classes.cellValue}>
                      {formatNumber(transaction?.settlement?.bankFee)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='overdueDate'
              defaultValue={DateTime.now()}
              control={control}
              rules={{
                validate: { isValid: (value: DateTime) => value && value.isValid },
                required: t`Field is required`,
              }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Overdue Starting Date`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='overdueAmount'
              defaultValue={transaction?.settlement?.remainingAmount}
              control={control}
              rules={{ required: t`Field is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t`Overdue Transfering Amount`}
                  required
                  fullWidth
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='overdueRate'
              defaultValue=''
              control={control}
              rules={{ required: t`Field is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t`Penal Rate of Overdue Type 1 (APR)`}
                  required
                  fullWidth
                  error={invalid}
                  InputProps={{ inputComponent: InputNumber as any }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' onClick={handleClickSubmit} loading={isLoading}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransTransferOverduePopup;
