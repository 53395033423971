import { client } from './axios';
import { ConfirmOtpParams, ForgotPasswordParams, ForgotPasswordToken, ResetPasswordParams } from 'types/ForgotPassword';

const forgotPassword = (params: ForgotPasswordParams): Promise<ForgotPasswordToken> =>
  client.get(`/api/v1/users/forgot-password`, { params });

const confirmOtp = ({ params, headers }: { params: ConfirmOtpParams; headers: any }) =>
  client.post(`/api/v1/users/otp-password`, { ...params }, { headers });

const resetPassword = ({ params, headers }: { params: ResetPasswordParams; headers: any }) =>
  client.post(`/api/v1/users/reset-password`, { ...params }, { headers });

export default {
  forgotPassword,
  confirmOtp,
  resetPassword,
};
