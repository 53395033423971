import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PopupController } from 'types/Common';
import PdfViewer from 'components/PdfViewer';

type PopupProps = PopupController & {
  conditionNoticeKey: string;
};

const HRNoticePopup = ({ onClose, conditionNoticeKey }: PopupProps) => {
  return (
    <>
      <DialogTitle>
        <Trans>NOTICE</Trans>
      </DialogTitle>

      <DialogContent>
        <PdfViewer objectKey={conditionNoticeKey} />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default HRNoticePopup;
