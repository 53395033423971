import { t } from '@lingui/macro';
export const getEmployeeType = (type: string | undefined) => {
  if (type) {
    const MAP_EMPLOYEE_TYPE: { [char: string]: string } = {
      FULL: t`Full-time`,
      SEASON: t`Part-time`,
    };
    return MAP_EMPLOYEE_TYPE[type];
  }
  return '';
};

export const getStatusType = (status: string | undefined) => {
  const MAP_STATUS_TYPE: { [char: string]: string } = {
    PAS_STATE_CONFIRM: t`Waiting for approval`,
    PAS_STATE_APPROVE: t`Approved`,
    PAS_STATE_REJECT: t`Rejected`,
  };
  return status ? MAP_STATUS_TYPE[status] : '';
};

export const getDocumentStatusType = (status: string | undefined) => {
  const MAP_DOCUMENT_STATUS_TYPE: { [char: string]: string } = {
    1: t`Lack`,
    2: t`Enough`,
  };
  return status ? MAP_DOCUMENT_STATUS_TYPE[status] : MAP_DOCUMENT_STATUS_TYPE[1];
};
