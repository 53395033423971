import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { getStatusType } from 'constants/employee';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { employeeSetupService } from 'services';
import { ConditionFullTimePopup, ConditionPartTimePopup } from 'views/ClientSetup/Employee/popups';

const EmployeeHistorySetting = ({ id }: { id: string }) => {
  const [dataSearch, onSearchChange] = useSearch();
  const [enterpriseEmployeeId, setEnterpriseEmployeeId] = useState<number>();
  const [pasId, setPasId] = useState<number>();
  const { data } = useQuery(
    ['employeeSetupService.getSalaryCondition', { id, ...dataSearch }],
    () => employeeSetupService.getPayrollAdvanceSetting({ id, ...dataSearch }),
    {
      keepPreviousData: true,
    },
  );

  const [isOpenDetail, setOpenDetail] = useState(false);
  const [status, setStatus] = useState<number>(1);

  const [employeeTypeCurrent, setEmployeeTypeCurrent] = useState('');

  const { data: items = [], paginate } = data ?? {};

  return (
    <div className='mt-6'>
      <Typography variant='h4'>
        <Trans>Employee Histories Setting</Trans>
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Execution Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Executor</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Approval Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Approver</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Reason</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center'>{DateTime.fromISO(item.confirmedAt).toFormat('dd/MM/yyyy')}</TableCell>
                <TableCell>{item.confirmedBy}</TableCell>
                <TableCell>{item.enterpriseName}</TableCell>
                <TableCell align='center'>{item.approvedAt ? DateTime.fromISO(item.approvedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell>{item.approvedBy}</TableCell>
                <TableCell>{getStatusType(item.status)}</TableCell>
                <TableCell>{item.reason}</TableCell>
                <TableCell>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setEnterpriseEmployeeId(item.enterpriseEmployeeId);
                      setOpenDetail(true);
                      setPasId(item.id);
                      if (item.status === 'PAS_STATE_APPROVE' || item.status === 'PAS_STATE_REJECT') {
                        setStatus(4);
                      } else {
                        setStatus(3);
                      }
                      setEmployeeTypeCurrent(item.employeeType);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='lg' open={isOpenDetail} onClose={() => setOpenDetail(false)}>
        {employeeTypeCurrent === 'FULL' ? (
          <ConditionFullTimePopup
            stepCurrent={status}
            employeeId={id}
            pasId={pasId!}
            enterpriseEmployeeId={enterpriseEmployeeId!}
            onClose={() => {
              setOpenDetail(false);
            }}
          />
        ) : (
          <ConditionPartTimePopup
            stepCurrent={status}
            employeeId={id}
            pasId={pasId!}
            enterpriseEmployeeId={enterpriseEmployeeId!}
            onClose={() => {
              setOpenDetail(false);
            }}
          />
        )}
      </Dialog>
    </div>
  );
};

export default EmployeeHistorySetting;
