import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { ContractGuaranteeStatus, ContractListEnterprise, ContractServiceFee } from 'views/Enterprise/EnterpriseContract/components';
import { EnterprisePaymentMethod } from 'views/Enterprise/EnterpriseView/components';

const EnterpriseContractManagement = () => {
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Contract Management</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <ContractListEnterprise />
        <EnterprisePaymentMethod />
        <ContractGuaranteeStatus />
        <ContractServiceFee />
      </div>
    </Paper>
  );
};

export default EnterpriseContractManagement;
