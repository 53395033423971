import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { PopupController } from 'types/Common';
import { ContractType } from 'types/EnterpriseContract';

type PopupProps = PopupController & {
  contract: ContractType;
  enterpriseId: string;
};

const ContractViewPopup = ({ contract, enterpriseId, onClose }: PopupProps) => {
  return (
    <>
      <DialogTitle>
        <Trans>Enterprise Contract</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={(contract?.state ?? 'MISSING') === 'COMPLETED' ? 2 : 1}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>
        <PdfViewer objectKey={contract?.object?.key} />
      </DialogContent>

      <DialogActions className='relative' style={{ height: '55px' }}>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ContractViewPopup;
