import { t, Trans } from '@lingui/macro';

import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber } from 'components';
import { getEnterpriseApplyFeeType } from 'constants/enterprise';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { employeeSetupService, queryClient } from 'services';
import { formatNumber } from 'utils/common';
import { RejectPopup } from 'views/ClientSetup/Employee/popups';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseEmployeeId: number;
  employeeId: string;
  pasId: number;
};

const PartTimePopupStep3 = ({ pasId, enterpriseEmployeeId, employeeId, onBack, onNext }: PopupProps) => {
  const { control, handleSubmit, setValue, watch } = useForm({ mode: 'onChange' });

  const [isOpenReject, setOpenReject] = useState(false);
  const {
    data: {
      totalEarnedLabour,
      advanceRatio,
      appliedEnterpriseFeeEn,
      employeeSupportDiscountRate,
      earnedDiscountRate,
    } = {},
  } = useQuery(
    ['employeeSetupService.getPayrollAdvanceSettingEachEnterprise', { employeeId, enterpriseEmployeeId, pasId }],
    () => employeeSetupService.getPayrollAdvanceSettingEachEnterprise({ employeeId, enterpriseEmployeeId, pasId }),
    {
      onSuccess: (data) => {
        Object.entries(data ?? {}).forEach(([key, value]) => {
          setValue(key, value);
        });
      },
    },
  );

  const { currentHourlyLabour, averageHourLabour } = watch();

  const { mutate: updatePayrollAdvanceSettingEachEnterprise, isLoading } = useMutation(
    employeeSetupService.updatePayrollAdvanceSettingEachEnterprise,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employeeSetupService.getSalaryCondition');
        onNext();
      },
    },
  );

  const handleApprove = () => {
    handleSubmit(({ currentHourlyLabour, averageHourLabour }) => {
      updatePayrollAdvanceSettingEachEnterprise({
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
        pasId,
        currentHourlyLabour,
        averageHourLabour,
        status: 'PAS_STATE_APPROVE',
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>CREDIT SETUP FOR PART-TIME EMPLOYEE</Trans>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h4'>
          <Trans>Payroll Advance Setting</Trans>
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12} lg={6} xl={6}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Current Hourly Labour</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='currentHourlyLabour'
                        defaultValue=''
                        control={control}
                        rules={{ required: t`Current Hourly Labour is required`, min: 0 }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            required
                            label={t`Current Hourly Labour`}
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{ inputComponent: InputNumber as any }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>VND</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Average Hour/Labour</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='averageHourLabour'
                        defaultValue={0}
                        control={control}
                        rules={{ required: t`Average Hour Labour is required`, min: 0 }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            fullWidth
                            required
                            label={t`Average Hour Labour`}
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{ inputComponent: InputNumber as any }}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>Hours</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Average Salary/Day</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{ readOnly: true }}
                        value={formatNumber(currentHourlyLabour * averageHourLabour) ?? 0}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>VND</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Total Earned Labour</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={totalEarnedLabour ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>Labour</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Earned Salary</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{ readOnly: true }}
                        value={formatNumber(currentHourlyLabour * averageHourLabour * totalEarnedLabour!) ?? 0}
                      />
                    </TableCell>
                    <TableCell>
                      <Trans>VND</Trans>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Advance Ratio</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={advanceRatio ?? 0} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Applied Enterprise's Fee</Trans>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField
                        fullWidth
                        InputProps={{ readOnly: true }}
                        value={getEnterpriseApplyFeeType(appliedEnterpriseFeeEn)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Employee Support Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>
                      <TextField InputProps={{ readOnly: true }} value={employeeSupportDiscountRate ?? 0} />
                    </TableCell>
                    <TableCell>
                      <Trans>(% Year)</Trans>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={12} lg={6} xl={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Trans>APPROVAL DECISION</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Advance Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      {formatNumber(currentHourlyLabour * averageHourLabour! * totalEarnedLabour! * advanceRatio!)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell>{earnedDiscountRate ?? 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
            <Trans>Reject</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoading} onClick={handleApprove}>
            <Trans>Approve</Trans>
          </LoadingButton>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <RejectPopup
          pasId={pasId}
          employeeId={employeeId}
          enterpriseEmployeeId={enterpriseEmployeeId}
          onBack={() => {
            setOpenReject(false);
          }}
          onNext={() => {
            setOpenReject(false);
            onBack();
          }}
        />
      </Dialog>
    </>
  );
};

export default PartTimePopupStep3;
