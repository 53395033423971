import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
} from '@mui/material';
import { usePermission } from 'hooks';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { PopupController } from 'types/Common';
import { useStyles } from 'views/Transaction/TransView/components/TransInfo';
import { employeeSetupService, queryClient } from 'services';
import { CreateSupportProgramType, UpdateSupportProgramType } from 'types/EmployeeSetup';
import { useState } from 'react';
import { formatNumber } from 'utils/common';
import { RejectEmployeeSupportProgramPopup } from 'views/ClientSetup/Employee/popups';

type PopupProps = PopupController & {
  employeeId: number;
  enterpriseEmployeeId: number;
  programId: number;
};

const UpsertEmployeeRegularProgramPopup = ({ employeeId, enterpriseEmployeeId, programId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requreUserPermission, EMPLOYEE_DUYET_TUCHOI_CTHTNLD } = usePermission();
  const { control, handleSubmit } = useForm();
  const classes = useStyles();
  const [isOpenReject, setOpenReject] = useState(false);

  const { data: program } = useQuery(
    [
      'employeeSetupService.getEmployeeSupportProgram',
      { employeeId: employeeId, enterpriseEmployeeId: enterpriseEmployeeId, programId: programId },
    ],
    () =>
      employeeSetupService.getEmployeeSupportProgram({
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
        programId: programId,
      }),
    {
      keepPreviousData: true,
    },
  );

  const { mutate: createEmployeeSupportProgram, isLoading: isLoadingCreate } = useMutation(
    employeeSetupService.createEmployeeSupportProgram,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('employeeSetupService.getEmployeeSupportPrograms');
        queryClient.invalidateQueries('employeeSetupService.getEmployeeHisSupportPrograms');
        onClose();
      },
    },
  );

  const { mutate: approveEmployeeSupportProgram, isLoading: isLoadingApprove } = useMutation(
    employeeSetupService.updateEmployeeSupportProgram,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('employeeSetupService.getEmployeeSupportPrograms');
        queryClient.invalidateQueries('employeeSetupService.getEmployeeHisSupportPrograms');
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values: CreateSupportProgramType) => {
      createEmployeeSupportProgram({
        ...values,
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
      });
    })();
  };

  const handleClickApprove = () => {
    handleSubmit((values: UpdateSupportProgramType) => {
      approveEmployeeSupportProgram({
        ...values,
        status: 'APPLIED',
        id: programId,
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>EMPLOYEE SUPPORT PROGRAM</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>Enterprise</Trans>
                    </TableCell>
                    <TableCell>{program?.enterpriseName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>Type</Trans>
                    </TableCell>
                    <TableCell>{program?.isKey === 1 ? t`Key Employee` : t`Regular Employee`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>Support Limit from Enterprise</Trans>
                    </TableCell>
                    <TableCell>{formatNumber(program?.defaultSupportLimit)} VND</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitleOne}>
                      <Trans>% of Enterprise Support</Trans>
                    </TableCell>
                    <TableCell>
                      {program && (
                        <Controller
                          name='supportRate'
                          defaultValue={program?.supportRate}
                          control={control}
                          rules={{ required: t`Field is required` }}
                          render={({ field, fieldState: { invalid, error } }) => (
                            <TextField
                              {...field}
                              required
                              fullWidth
                              error={invalid}
                              InputProps={{
                                endAdornment: '%',
                                inputComponent: InputNumber as any,
                                inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                              }}
                            />
                          )}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      {program?.status === 'WAITING_FOR_APPLY' ? (
        <>
          <DialogActions>
            <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
              <Trans>Cancel</Trans>
            </LoadingButton>

            { requreUserPermission([EMPLOYEE_DUYET_TUCHOI_CTHTNLD]) && (
              <>
                <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
                  <Trans>Reject</Trans>
                </LoadingButton>
                <LoadingButton
                  variant='contained'
                  className='w-28'
                  loading={isLoadingApprove}
                  onClick={handleClickApprove}
                >
                  <Trans>Approve</Trans>
                </LoadingButton>
              </>
            )}
          </DialogActions>
          <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
            <RejectEmployeeSupportProgramPopup
              employeeId={employeeId}
              enterpriseEmployeeId={enterpriseEmployeeId}
              programId={programId}
              onSuccess={onClose}
              onClose={() => {
                setOpenReject(false);
              }}
            />
          </Dialog>
        </>
      ) : (
        <DialogActions>
          <LoadingButton variant='outlined' onClick={onClose}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' onClick={handleClickSubmit} loading={isLoadingCreate}>
            <Trans>Save</Trans>
          </LoadingButton>
        </DialogActions>
      )}
    </>
  );
};

export default UpsertEmployeeRegularProgramPopup;
