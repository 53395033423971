import { Trans } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { TransSummaryType } from 'types/Transaction';
import { formatNumber } from 'utils/common';

const TransSummary = ({ summary }: { summary?: TransSummaryType }) => {
  return (
    <Paper className='bg-[#f2f2f2] p-4'>
      <Grid container columnSpacing={3} rowSpacing={3} columns={15}>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>No. of Transaction</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.noOfTransaction)}</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Request Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalRequestAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Debt Purchasing Price</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalDebtPurchasingPriceAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Paid Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalPaidAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Transaction Cost</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalTransactionCostAmount)} VND</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} rowSpacing={3} className='mt-0' columns={15}>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>No. of Settling Transaction</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.noOfSelling)}</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Collection Amount from Employee</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalCollectionAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Total Settled Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalSettledAmount)} VND</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>No. of Overdue</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.noOfOverdue)}</Typography>
          </Paper>
        </Grid>
        <Grid item md={5} lg={3} xl={3} className='text-center'>
          <Paper className='bg-white py-4 rounded-lg'>
            <Typography variant='h6' className='mb-4'>
              <Trans>Overdue Unpaid Amount</Trans>
            </Typography>
            <Typography>{formatNumber(summary?.totalOverdueUnpaidAmount)} VND</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TransSummary;
