import { Trans } from '@lingui/macro';
import { Menu } from '@mui/icons-material';
import { AppBar, Drawer, IconButton, Link as MuiLink, List, ListItemButton, Toolbar, Button, Dialog } from '@mui/material';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setUserLocale } from 'reducers/user-settings';
import { staticRoute } from 'routes';
import ContactPopup from 'views/Static/ContactPopup';


const HeaderStatic = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userLocale = useActiveLocale();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  // const { aboutUs, salaryAdvance, enterprise, employee, investment, login } = staticRoute;
  const { aboutUs, enterprise, employee, investment, login, faqs } = staticRoute;
  function submitContact() {
    console.log("ok");
    setOpenSelectBank(true)
  }
  return (
    <div>
      <AppBar>
        <Toolbar className='h-20'>
          <Link to={login.path} className='flex items-end gap-2'>
            <img alt='logo' src={require('assets/images/logo_scp.png')} className='h-12' />
            <span className='font-bold'>
              <Trans>WAGE ADVANCE</Trans>
            </span>
          </Link>
          <div className='flex-1'></div>
          <List className='flex'>
            {/* {[aboutUs, salaryAdvance, enterprise, employee, investment].map((item, index) => ( */}
            {[aboutUs, employee, enterprise, investment].map((item, index) => (
              <Link key={index} to={item.path}>
                <ListItemButton
                  className={
                    (location.pathname.startsWith(item.path) ? 'text-action-main' : 'text-white') +
                    ' hover:text-action-main'
                  }
                >
                  {item.name}
                </ListItemButton>
              </Link>
            ))}
          </List>
          <div className='ml-4 mr-12'>
            {[
              { locale: 'en-US', label: 'EN' },
              { locale: 'vi-VN', label: 'VN' },
            ].map((item, index) => (
              <Fragment key={index}>
                <MuiLink
                  className={
                    (userLocale === item.locale ? 'text-action-main' : 'text-white') +
                    ' hover:text-action-main cursor-pointer'
                  }
                  onClick={() => dispatch(setUserLocale(item.locale))}
                >
                  {item.label}
                </MuiLink>
                {index === 0 && <span className='mx-1.5'>|</span>}
              </Fragment>
            ))}
          </div>
          <div className='w-20 flex justify-center'>
            {!isOpenDrawer && (
              <IconButton className='hover:bg-primary-dark' onClick={() => setIsOpenDrawer(true)}>
                <Menu className='text-white' />
              </IconButton>
            )}
            <Drawer
              anchor='right'
              variant='persistent'
              open={isOpenDrawer}
              onClose={() => setIsOpenDrawer(false)}
              PaperProps={{ className: 'overflow-visible border-0' }}
            >
              <div className='w-32 h-full py-6 bg-primary-main flex flex-col items-center'>
                <IconButton className='hover:bg-primary-dark' onClick={() => setIsOpenDrawer(false)}>
                  <Menu className='text-white' />
                </IconButton>
                <div className='h-32'></div>
                <div className='relative p-4'>
                  <img src={require('assets/icons/icon-signup.png')} />
                  <div className='absolute w-60 h-full top-0 -left-60 bg-primary-main flex items-center p-4 border-l-4 border-l-action-main'>
                    <Link to=''>
                      <span className='text-white hover:text-action-main'>
                        <Trans>Sign Up</Trans>
                      </span>
                    </Link>
                  </div>
                </div>
                <div className='h-20 w-0.5 bg-white'></div>
                <div className='relative p-4'>
                  <img src={require('assets/icons/icon-contact.png')} />
                  <div className='absolute w-60 h-full top-0 -left-60 bg-primary-main flex items-center p-4 border-l-4 border-l-action-main'>
                    <Button onClick={submitContact}>
                      <span className='text-white hover:text-action-main'>
                        <Trans>Contact</Trans>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className='h-20 w-0.5 bg-white'></div>
                <div className='relative p-4'>
                  <img src={require('assets/icons/icon-faqs.png')} />
                  <div className='absolute w-60 h-full top-0 -left-60 bg-primary-main flex items-center p-4 border-l-4 border-l-action-main'>
                    <Link to={faqs.path}>
                      <span className='text-white hover:text-action-main'>
                        <Trans>FAQs</Trans>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </Toolbar>
      </AppBar>
      <Dialog fullWidth maxWidth='xl' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <ContactPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
    </div>
  );
};

export default HeaderStatic;
