import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Dialog,
  debounce,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { formatNumber } from 'utils/common';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { queryClient, transactionService } from 'services';
import { TransType } from 'types/Transaction';
import { PaidStatus, WaitSettlementStatus, NoticeStatusInit, NoticeStatusSigned } from 'constants/transaction';
import { TransIncomeNoticesPopup } from 'views/Transaction/TransView/popups';
import { TableEmpty } from 'components';
import { usePermission } from 'hooks';
import SelectPageSize from 'components/SelectPageSize';

export const useStyles = makeStyles({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const TransPaid = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [transaction, setTransaction] = useState<TransType>();
  const [searchType, setSearchType] = useState('employeeName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const classes = useStyles();
  const [dataSearch, onSearchChange] = useSearch({ statuses: PaidStatus });
  const [dataSelect, setDataSelect] = useState<TransType[]>([]);
  const { rolesignablenew, isCaRoles } = usePermission();
  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: moveStatuses, isLoading } = useMutation(transactionService.moveStatuses, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.fetchTransactions');
    },
  });

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  const handleClickSubmit = () => {
    moveStatuses({
      ids: dataSelect.map((item) => item.id),
      status: WaitSettlementStatus,
    });
  };

  const handleClickSign = (trans: TransType) => {
    setTransaction(trans);
    setOpen(true);
  };

  function renderNoticeSign(trans: TransType) {
    if (trans.employeeIncomeNotice?.status === NoticeStatusInit && rolesignablenew()) {
      return (
        <Button variant='contained' size='small' onClick={() => handleClickSign(trans)}>
          <Trans>Sign</Trans>
        </Button>
      );
    }
    if (trans.employeeIncomeNotice?.status === NoticeStatusSigned) {
      return <Trans>Signed</Trans>;
    }
    return null;
  }

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Transaction List</Trans>
          </Typography>
          {
            isCaRoles() ?
              <LoadingButton
                variant='contained'
                disabled={dataSelect.length === 0}
                loading={isLoading}
                onClick={handleClickSubmit}
              >
                <Trans>Approve Transaction</Trans>
              </LoadingButton>
              : ""
          }

        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='employeeName'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='employeeErcNumber'>
              <Trans>ID Card No.</Trans>
            </MenuItem>
            <MenuItem value='transactionCode'>
              <Trans>Transaction ID</Trans>
            </MenuItem>
            <MenuItem value='enterpriseName'>
              <Trans>Enterprise Name</Trans>
            </MenuItem>
            <MenuItem value='accountantName'>
              <Trans>Accountant Name</Trans>
            </MenuItem>
          </TextField>
          <TextField
            placeholder={t`Search...`}
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceChangeValue({ [searchType]: event.target.value });
            }}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(event, checked) => {
                    if (checked) setDataSelect(items);
                    else setDataSelect([]);
                  }}
                  checked={dataSelect.length === items.length}
                  indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                />
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID Card No.</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Request Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Total Transaction Cost (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction Notice</Trans>
              </TableCell>
              <TableCell>
                <Trans>Accountant Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center' className='p-1'>
                  <Checkbox
                    checked={dataSelect.some((next) => next.id === item.id)}
                    onChange={(event, checked) => {
                      if (checked) setDataSelect((nexts) => nexts.concat(item));
                      else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                    }}
                  />
                </TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell align='center'>
                  {item.transactionDate ? DateTime.fromISO(item.transactionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.employee?.name}</TableCell>
                <TableCell>{item.employee?.ercNumber}</TableCell>
                <TableCell>{item.enterprise?.name}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.costAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.paymentAmount)}</TableCell>
                <TableCell align='center'>{renderNoticeSign(item)}</TableCell>
                <TableCell>{item.user?.fullname}</TableCell>
                <TableCell align='center'>
                  <Link to={privateRoute.transactionPayingView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
            setDataSelect([]);
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
            setDataSelect([]);
          }}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={isOpen}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.dialogPaper }}
      >
        <TransIncomeNoticesPopup
          transaction={transaction!}
          onClose={() => {
            setOpen(false);
            queryClient.invalidateQueries('transactionService.fetchTransactions');
          }}
        />
      </Dialog>
    </div>
  );
};

export default TransPaid;
