import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useRef, useState } from 'react';
import { PopupController } from 'types/Common';
import { TransType, TransUpsertSettlementRecordType, TransSettlementRecordType } from 'types/Transaction';
import { formatNumber } from 'utils/common';
import { useStyles } from 'views/Transaction/TransView/components/TransInfo';
import { transactionService, queryClient, fileService } from 'services';
import { ObjectType } from 'types/Object';
import { DateTime } from 'luxon';

type PopupProps = PopupController & {
  transaction: TransType;
  record: TransSettlementRecordType;
};

const TransSettlementUpdateRecordPopup = ({ transaction, record, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const [objects, setObjects] = useState<ObjectType[]>([]);
  const [objectIds, setObjectIds] = useState<number[]>([]);
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate: uploadFiles, isLoading: isLoadingUpload } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjects(data.objects);
      let _objectIds: number[] = [];
      Array.from(data.objects).forEach((o) => {
        _objectIds.push(o.id);
      });
      setObjectIds(_objectIds);
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(event.target.files!).forEach((file) => {
      formData.append('files', file);
    });
    uploadFiles(formData);
  };

  const { mutate: addRecord, isLoading } = useMutation(transactionService.updateTransactionSettlementRecord, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.fetchTransactionSettlementRecords');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: TransUpsertSettlementRecordType) => {
      type ValuesType = TransUpsertSettlementRecordType & { collectionDate: DateTime };
      const { collectionDate, ...others } = values as ValuesType;
      addRecord({
        ...others,
        collectionDate: collectionDate.toISO(),
        transId: transaction.id,
        id: record.id,
        objectIds,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Settlement Record</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <Trans>Settlement Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue}>
                      {formatNumber(transaction?.settlement?.remainingAmount)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.cellTitle}>
                      <Trans>Remaining Amount (VND)</Trans>
                    </TableCell>
                    <TableCell className={classes.cellValue}>
                      {formatNumber(transaction?.settlement?.remainingAmount)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='collectionDate'
              defaultValue={record?.collectionDate ? DateTime.fromISO(record?.collectionDate) : ''}
              control={control}
              rules={{
                validate: { isValid: (value: DateTime) => value && value.isValid },
                required: t`Collection Date is required`,
              }}
              render={({ field: { value, onChange }, fieldState: { invalid } }) => (
                <DesktopDatePicker
                  value={value}
                  onChange={onChange}
                  renderInput={(props) => <TextField {...props} required error={invalid} />}
                  label={t`Collection Date`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
          </Grid>
          <Grid item sm={6}>
            <Controller
              name='collectionAmount'
              defaultValue={record?.collectedAmount}
              control={control}
              rules={{ required: t`Collection Amount is required` }}
              render={({ field, fieldState: { invalid } }) => (
                <TextField
                  {...field}
                  label={t`Collection Amount`}
                  required
                  fullWidth
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='note'
              defaultValue={record.note}
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  minRows={2}
                  label={t`Note`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <div className='flex items-end space-x-3 h-10'>
              <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} />
              <LoadingButton
                variant='outlined'
                size='small'
                loading={isLoadingUpload}
                onClick={() => inputRef.current?.click()}
              >
                <Trans>Choose File</Trans>
              </LoadingButton>

              {objects.map((file) => (
                <Chip
                  key={file.id}
                  variant='outlined'
                  label={file.originalName}
                  onDelete={() => setObjects((items) => items.filter((item) => item.id !== file.id))}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' onClick={handleClickSubmit} loading={isLoading}>
          <Trans>Request to Settle</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransSettlementUpdateRecordPopup;
