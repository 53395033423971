import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { usePermission } from 'hooks';
import { employeeService } from 'services';
import { EmployeeViewInfo } from 'views/Employee/EmployeeView/components';
import { EmployeeBankAccount } from 'views/Employee/EmployeeView/components';

const AccountEmployee = () => {
  const { getEnterpriseId } = usePermission();

  const { data: employee, isSuccess } = useQuery(['employeeService.getEmployeeById'], () =>
    employeeService.getEmployeeById({ id: getEnterpriseId() }),
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Employee Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='-mt-6'></div>
        {isSuccess && (
          <>
            <EmployeeViewInfo employee={employee} />
            <EmployeeBankAccount employee={employee} />
          </>
        )}
      </div>
    </Paper>
  );
};

export default AccountEmployee;
