import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useSearch } from 'hooks';
import { useMutation } from 'react-query';
import { queryClient, fileService, transactionService } from 'services';
import { download } from 'utils/common';
import { ObjectType } from 'types/Object';
import { TableEmpty } from 'components';
import { usePermission } from 'hooks';
import SelectPageSize from 'components/SelectPageSize';

const TransDoc = ({
  transactionId,
  recordId,
  docType,
  removable,
}: {
  transactionId: number;
  recordId?: number;
  docType: string;
  removable: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dataSearch, onSearchChange] = useSearch({ transId: transactionId, recordId: recordId, docType: docType });
  const { requireRole } = usePermission();
  const { data } = useQuery(
    ['transactionService.fetchTransactionDocs', dataSearch],
    () => transactionService.fetchTransactionDocs(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: deleteDoc } = useMutation(transactionService.deleteTransactionDocs, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.fetchTransactionDocs');
    },
  });

  const handleClickDelete = (id: number) => {
    deleteDoc({
      id: transactionId,
      transDocId: id,
    });
  };

  const handleClickDownloadFile = (object: ObjectType) => {
    if (object) {
      fileService.downloadFile(object.key).then((data) => {
        download(data, object.originalName, object.contentType);
      });
    }
  };

  function renderPaymentDelCol() {
    if (removable) {
      return (
        <TableCell>
          <Trans>Remove</Trans>
        </TableCell>
      );
    }
    return null;
  }

  function renderPaymentDelButton(id: number) {
    if (removable) {
      return (
        <TableCell align='center'>
          {(requireRole('MASTER') || requireRole('SUB_MASTER') || requireRole('ACCOUNTANT') || requireRole('ACC_CONTROLLER')) && (
            <Button size='small' variant='outlined' onClick={() => handleClickDelete(id)}>
              <Trans>Remove</Trans>
            </Button>
          )}
        </TableCell>
      );
    }
    return null;
  }

  return (
    <div>
      <Typography variant='h4'>
        <Trans>Voucher List</Trans>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Voucher Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Uploader</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
              {renderPaymentDelCol()}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.object?.originalName}</TableCell>
                <TableCell>{item.createdUser?.fullname}</TableCell>
                <TableCell align='center'>
                  <Button size='small' onClick={() => handleClickDownloadFile(item.object)}>
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
                {renderPaymentDelButton(item.id)}
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>
      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>
    </div>
  );
};

export default TransDoc;
