import { t, Trans } from '@lingui/macro';

import { Grid, TextField, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { DateTime } from 'luxon';
import { formatNumber } from 'utils/common';

const EnterpriseFeeDetail = ({ id }: { id: string }) => {
  const {
    data: { enterpriseName, latestCollectionDate, latestCollectionFee, phoneNumber, transactionRepresentative } = {},
  } = useQuery(['enterpriseSetupService.getEnterpriseServiceFeeInfo', { id }], () =>
    enterpriseSetupService.getEnterpriseServiceFeeInfo({ id }),
  );

  return (
    <div>
      <Typography variant='h4' className='mb-4'>
        <Trans>Enterprise Information</Trans> {enterpriseName ?? ''}
      </Typography>

      <Grid container columnSpacing={4} rowSpacing={3}>
        <Grid item sm={3}>
          <TextField
            fullWidth
            label={t`Transaction Representative`}
            InputProps={{ readOnly: true }}
            value={transactionRepresentative ?? ''}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField fullWidth label={t`Phone number`} InputProps={{ readOnly: true }} value={phoneNumber ?? ''} />
        </Grid>
        <Grid item sm={1}></Grid>
        <Grid item sm={2}>
          <TextField
            fullWidth
            label={t`Latest Collection Date`}
            InputProps={{ readOnly: true }}
            value={latestCollectionDate ? DateTime.fromISO(latestCollectionDate).toFormat('dd/MM/yyyy') : ''}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            fullWidth
            label={t`Latest Collection Fee (VND)`}
            InputProps={{ readOnly: true }}
            value={formatNumber(latestCollectionFee) ?? 0}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EnterpriseFeeDetail;
