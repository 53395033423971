import { t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  debounce,
  Dialog,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEnterpriseStatus } from 'constants/enterprise';
import { usePermission, useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { employeeService, queryClient } from 'services';
import { EmployeeType } from 'types/Employee';
import { EmployeeMeetingCalendar, EmployeeMeetingSummary } from 'views/Employee/EmployeeList/components';
import { EmployeeAssignmentPopup } from 'views/Employee/EmployeeList/popups';
import { CheckCircle } from '@mui/icons-material';
import SelectPageSize from 'components/SelectPageSize';

const EmployeeAll = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole, requreUserPermission, EMPLOYEE_ASSIGN, EMPLOYEE_ACTIVE } = usePermission();
  const [dataSearch, onSearchChange] = useSearch();
  const [dataSelect, setDataSelect] = useState<EmployeeType[]>([]);
  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});

  const [isOpenAssign, setOpenAssign] = useState(false);

  const { data } = useQuery(
    ['employeeService.fetchEmployees', dataSearch],
    () => employeeService.fetchEmployees(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { data: statuses } = useQuery(['employeeService.fetchStatuses'], () => employeeService.fetchStatuses(), {
    keepPreviousData: true,
  });

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  const {
    mutate: activateUsers,
    isLoading: isLoadingActivate,
    data: activeInfo,
  } = useMutation(employeeService.activateUsers, {
    onSuccess: (res: any) => {
      var mess = res.failed === 0 ? t`Successful` : (res.failed.toString() + " " + t`employees are not activated sucessfully`);
      enqueueSnackbar(
        mess,
      );
      queryClient.invalidateQueries('employeeService.fetchEmployees');
    },
  });

  const handleClickActivateUsers = () => {
    if (dataSelect && dataSelect.length > 0) {
      activateUsers({
        employeeIds: dataSelect.map((item) => item.id),
      });
    }
  };

  return (
    <div>
      <Grid container columnSpacing={4} rowSpacing={1} className='mb-6'>
        <Grid item xl={2}>
          <EmployeeMeetingSummary />
        </Grid>
        <Grid item sm={12} xl={10}>
          <EmployeeMeetingCalendar />
        </Grid>
      </Grid>

      <div className='flex justify-between'>
        <div className='space-x-3'>
          {requireRole('CRM_SENIOR') && (
            <>
              {requreUserPermission([EMPLOYEE_ASSIGN]) && <Button variant='contained' disabled={dataSelect.length === 0} onClick={() => setOpenAssign(true)}>
                <Trans>Assignment</Trans>
              </Button>}
              {requreUserPermission([EMPLOYEE_ACTIVE]) && <LoadingButton
                variant='contained'
                loading={isLoadingActivate}
                disabled={dataSelect.length === 0}
                onClick={handleClickActivateUsers}
              >
                <Trans>Activate Users</Trans>
              </LoadingButton>}
            </>
          )}
        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='name'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='ercNumber'>
              <Trans>ID</Trans>
            </MenuItem>
            <MenuItem value='enterpriseName'>
              <Trans>Enterprise Name</Trans>
            </MenuItem>
            <MenuItem value='seniorCrmName'>
              <Trans>CRM Staff</Trans>
            </MenuItem>
            <MenuItem value='status'>
              <Trans>Status</Trans>
            </MenuItem>
            <MenuItem value='autoTransaction'>
              <Trans>Automatic Transaction</Trans>
            </MenuItem>
          </TextField>
          {['name', 'ercNumber', 'seniorCrmName', 'enterpriseName'].includes(searchType) && (
            <TextField
              placeholder={t`Search...`}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            />
          )}
          {['status'].includes(searchType) && (
            <TextField
              select
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
            >
              <MenuItem value=''>
                <Trans>All</Trans>
              </MenuItem>
              {(statuses ?? []).map((item) => (
                <MenuItem key={item} value={item}>
                  {getEnterpriseStatus(item)}
                </MenuItem>
              ))}
            </TextField>
          )}
          {['autoTransaction'].includes(searchType) && <TextField
            select
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceChangeValue({ [searchType]: event.target.value });
            }}
          >
            <MenuItem value=''>
              <Trans>All</Trans>
            </MenuItem>
            <MenuItem value='1'>
              <Trans>YES</Trans>
            </MenuItem>
            <MenuItem value='0'>
              <Trans>NO</Trans>
            </MenuItem>
          </TextField>}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {(requireRole('MASTER') || requireRole('SUB_MASTER') || requireRole('CRM_SENIOR')) &&
                <TableCell>
                  <Checkbox
                    onChange={(event, checked) => {
                      if (checked) setDataSelect(items);
                      else setDataSelect([]);
                    }}
                    checked={dataSelect.length === items.length}
                    indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                  />
                </TableCell>
              }
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Automatic Transaction</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Last Contact Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Senior CRM</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                {(requireRole('MASTER') || requireRole('SUB_MASTER') || requireRole('CRM_SENIOR')) &&
                  <TableCell align='center' className='p-1'>
                    <Checkbox
                      checked={dataSelect.some((next) => next.id === item.id)}
                      onChange={(event, checked) => {
                        if (checked) setDataSelect((nexts) => nexts.concat(item));
                        else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                      }}
                    />
                  </TableCell>
                }
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.ercNumber}</TableCell>
                <TableCell className='text-center'>{item.autoTransaction ? <CheckCircle color='primary' /> : ""}</TableCell>
                <TableCell>{item.enterpriseName}</TableCell>
                <TableCell>
                  {item.lastContactDate ? DateTime.fromISO(item.lastContactDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{getEnterpriseStatus(item.status)}</TableCell>
                <TableCell>{item.seniorUser?.fullname}</TableCell>
                <TableCell className='text-right'>
                  <Link to={privateRoute.employeeClientView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
            setDataSelect([]);
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
            setDataSelect([]);
          }}
        />
      </div>

      <Dialog fullWidth maxWidth='md' open={isOpenAssign} onClose={() => setOpenAssign(false)}>
        <EmployeeAssignmentPopup
          employeeIds={dataSelect.map((item) => item.id)}
          onSuccess={() => setDataSelect([])}
          onClose={() => setOpenAssign(false)}
        />
      </Dialog>
    </div>
  );
};

export default EmployeeAll;
