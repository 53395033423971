import { t, Trans } from '@lingui/macro';

import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { EnterpriseAll, EnterpriseOnBoard } from 'views/Enterprise/EnterpriseList/components';

const Enterprise = () => {
  const { data: all } = useQuery(['enterpriseService.fetchEnterprises'], () => enterpriseService.fetchEnterprises());
  const { data: onboard } = useQuery(
    ['enterpriseService.fetchEnterprises', { statuses: 'ONBOARDED,STOP_CORPORATION', sum: '1' }],
    () => enterpriseService.fetchEnterprises({ statuses: 'ONBOARDED,STOP_CORPORATION', sum: '1' }),
  );

  const tabs = [
    { code: 'all', label: t`ALL (${all?.paginate.totalRecord ?? 0})`, component: <EnterpriseAll /> },
    { code: 'onboard', label: t`ONBOARDED (${onboard?.paginate.totalRecord ?? 0})`, component: <EnterpriseOnBoard /> },
  ];
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Enterprise Management</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default Enterprise;
