import { Trans } from '@lingui/macro';
import { Typography, Paper } from '@mui/material';
import { EnterpriseFrequency } from 'views/Enterprise/EnterpriseView/components';

const UpdateInformation = () => {
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h4'>
          <Trans>FREQUENCY OF UPDATE & NOTICE</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <EnterpriseFrequency />
      </div>
    </Paper>
  );
};

export default UpdateInformation;
