import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { enterpriseService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ContractCreateType, ContractType } from 'types/EnterpriseContract';
import { EnterpriseViewInfo } from 'views/Enterprise/EnterpriseView/components';

type PopupProps = PopupController & {
  contract: ContractType;
  enterpriseId: string;
};

const ContractCreatePopup = ({ contract, enterpriseId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const [isCreate, setIsCreate] = useState(false);

  const { data: enterprise, isSuccess: isSuccessLoadEnterprise } = useQuery(
    ['enterpriseService.getEnterpriseById', { id: enterpriseId }],
    () => enterpriseService.getEnterpriseById({ id: enterpriseId }),
  );

  const { data: dataFile, isSuccess: isSuccessLoadFile } = useQuery(
    ['enterpriseService.generateEnterpriseContract', { id: enterpriseId, type: contract?.type }],
    () => enterpriseService.generateEnterpriseContract({ id: enterpriseId, type: contract?.type }),
  );

  const { mutate: createContract, isLoading: isLoadingCreate } = useMutation(
    enterpriseService.createEnterpriseContract,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.getEnterpriseLatest');
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseContract');
        onClose();
      },
    },
  );

  const handleClickRequest = () => {
    handleSubmit((values: ContractCreateType) => {
      createContract({
        ...values,
        _id: enterpriseId,
        type: contract.type!,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Enterprise Contract</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={0}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        {!isCreate && isSuccessLoadEnterprise && (
          <div>
            <Typography align='center' className='mb-2'>
              <Trans>Please check Enterprise's information before pressing "Create" button</Trans>
            </Typography>
            <EnterpriseViewInfo enterprise={enterprise} />
          </div>
        )}
        {isCreate && (
          <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
            {isSuccessLoadFile && (
              <object
                data={'data:application/pdf;base64,' + dataFile?.data!}
                type='application/pdf'
                className='w-full h-full'
              ></object>
            )}
          </div>
        )}
      </DialogContent>

      <DialogActions className='relative' style={{ height: '55px' }}>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </LoadingButton>

        {isCreate && (
          <Controller
            name='digital'
            defaultValue={true}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl className='flex-row justify-center w-full'>
                {[
                  { label: t`Digital Contract`, value: true },
                  { label: t`Manual Contract`, value: false },
                ].map((choice, index) => (
                  <FormControlLabel
                    key={index}
                    label={choice.label}
                    control={
                      <Checkbox
                        color='info'
                        checked={choice.value === value}
                        onChange={(event, checked) => {
                          if (checked) onChange({ target: { value: choice.value } });
                        }}
                      />
                    }
                  />
                ))}
              </FormControl>
            )}
          />
        )}

        {isCreate ? (
          <LoadingButton variant='contained' className='w-28' loading={isLoadingCreate} onClick={handleClickRequest}>
            <Trans>Request</Trans>
          </LoadingButton>
        ) : (
          <LoadingButton variant='contained' className='w-28' onClick={() => setIsCreate(true)}>
            <Trans>Create</Trans>
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default ContractCreatePopup;
