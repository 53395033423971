import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DigitalSign } from 'components';
import { PcTypeSignType } from 'constants/common';
import { NoticeStatusInit } from 'constants/transaction';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { transactionService } from 'services';
import { PopupController } from 'types/Common';
import { SignedDataType } from 'types/DigitalSign';
import { TransType } from 'types/Transaction';

const EmployeeNoticeType = 'employee';
const EnterpriseNoticeType = 'enterprise';

type PopupProps = PopupController & {
  transaction: TransType;
  // EMPLOYEE or ENTERPRISE, if not we view both
  view?: string;
};
const TransIncomeNoticesPopup = ({ transaction, view, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenSign, setOpenSign] = useState(false);
  const { data: employeeNotice, isSuccess: isSuccessEmployee } = useQuery(
    ['transactionService.getEmployeeNotice', { id: transaction?.id }],
    () => transactionService.getEmployeeNotice({ id: transaction?.id! }),
  );

  const { data: enterpriseNotice, isSuccess: isSuccessEnterprise } = useQuery(
    ['transactionService.getEnterpriseNotice', { id: transaction?.id }],
    () => transactionService.getEnterpriseNotice({ id: transaction?.id! }),
  );

  const { mutate: signIncomeNotices, isLoading } = useMutation(transactionService.signIncomeNotices, {
    onSuccess: (data) => {
      enqueueSnackbar(t`Successful`);
      onClose();
    },
  });

  const handleSignSuccess = (signedObjects: SignedDataType[]) => {
    let employeeObjId: number = 0;
    let enterpriseObjId: number = 0;
    Array.from(signedObjects).forEach((o) => {
      if (o.key === EmployeeNoticeType) {
        employeeObjId = o.objectId;
      }
      if (o.key === EnterpriseNoticeType) {
        enterpriseObjId = o.objectId;
      }
    });
    signIncomeNotices({ id: transaction.id, employeeObjectId: employeeObjId, enterpriseObjectId: enterpriseObjId });
  };

  function renderNoticeSign() {
    if(transaction.checkHisList === true){
      return
    }else{
      if (transaction.employeeIncomeNotice?.status === NoticeStatusInit) {
        return (
          <LoadingButton variant='contained' loading={isLoading} onClick={() => setOpenSign(true)}>
            <Trans>Sign</Trans>
          </LoadingButton>
        );
      }
    }
    
    return null;
  }

  return (
    <>
      <DialogTitle>
        <Trans>Transaction Notice</Trans>
      </DialogTitle>

      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container columnSpacing={2} rowSpacing={3}>
          {(!view || view! === 'EMPLOYEE') && (
            <Grid item sm={!!view ? 12 : 6}>
              <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
                {isSuccessEmployee && (
                  <object
                    data={'data:application/pdf;base64,' + employeeNotice?.data!}
                    type='application/pdf'
                    className='w-full h-full'
                  ></object>
                )}
              </div>
            </Grid>
          )}

          {(!view || view! === 'ENTERPRISE') && (
            <Grid item sm={!!view ? 12 : 6}>
              <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
                {isSuccessEnterprise && (
                  <object
                    data={'data:application/pdf;base64,' + enterpriseNotice?.data!}
                    type='application/pdf'
                    className='w-full h-full'
                  ></object>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        {renderNoticeSign()}
      </DialogActions>

      <Dialog fullWidth maxWidth='md' open={isOpenSign} onClose={() => setOpenSign(false)}>
        <DigitalSign
          onClose={() => setOpenSign(false)}
          documents={[
            { ...employeeNotice!, key: EmployeeNoticeType },
            { ...enterpriseNotice!, key: EnterpriseNoticeType },
          ]}
          signType={PcTypeSignType}
          onSigned={(signedObjects) => handleSignSuccess(signedObjects)}
        />
      </Dialog>
    </>
  );
};

export default TransIncomeNoticesPopup;
