import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService } from 'services';
import { CreditScoreType } from 'types/CreditScore';
import { CreditScoreCreateType, CreditScoreLimitationBody } from 'types/EnterpriseSetup';
import { useEffect, useState } from 'react';
import $ from "jquery";
type PopupProps = {
  enterpriseId: number;
  creditScore: CreditScoreType;
  creditScoreCreate: CreditScoreCreateType;
  onRequest: (args: any) => void;
  onBack: () => void;
  onNext: () => void;
  setCreditScoreLimit: (args: any) => void;
  creditScoreLimit: any;
};

const BusinessCreditScoreStep2 = ({
  enterpriseId,
  creditScore,
  creditScoreCreate,
  onRequest,
  onBack,
  onNext,
  setCreditScoreLimit,
  creditScoreLimit,
}: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, watch, setValue, getValues } = useForm({ mode: 'onChange' });
  const values = watch();

  const { mutate: updateCreditScoreLimitation, isLoading: isLoadingLimitation,isSuccess:isSuccessLimitation } = useMutation(
    enterpriseSetupService.updateCreditScoreLimitation,
    {
      onMutate: () => {
        onRequest({
          totalLimit: proposalLimit,
          earnedDiscountRate,
          futureDiscountRate,
        });
      },
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        onNext();
      },
    },
  );

  useEffect(() => {
    Object.entries(creditScoreLimit ?? {}).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [setValue, creditScoreLimit]);

  const { mutate: updateCreditScoreDiscount } = useMutation(enterpriseSetupService.updateCreditScoreDiscount, {

  });

  const handleClickSubmit = () => {
    handleSubmit((values: CreditScoreLimitationBody) => {

      updateCreditScoreLimitation({ ...values, enId: enterpriseId, csId: creditScoreCreate.id });
      updateCreditScoreDiscount({ ...values, enId: enterpriseId, csId: creditScoreCreate.id });
      setCreditScoreLimit(values);
    })();
  };
  const handleClickTestValid = () => {
    handleSubmit((values: CreditScoreLimitationBody) => {

    })();
  };

  const averageSalaryExpense = SumAverageSalary();
  // Number((values.salaryExpenseT1 || 0) + (values.salaryExpenseT2 || 0) + (values.salaryExpenseT3 || 0)) / 3;
  const expectedSalaryLimit = Math.round(averageSalaryExpense / 12);
  const proposalLimit =
    Math.round((expectedSalaryLimit * creditScoreCreate.ratingCategory.adjustmentRatio) / 100 +
      (values.limitationOtherAdjustment || 0));

  const earnedDiscountRate =
    creditScoreCreate.floorDiscountRate +
    (values.incentiveReferral || 0) +
    (values.enterpriseGuarantee || 0) +
    (values.discountRateOtherAdjustment || 0);
  const futureDiscountRate = earnedDiscountRate + (values.futureRate || 0);

  function SumAverageSalary(): number {
    var number = 0;
    var array = [values.salaryExpenseT1, values.salaryExpenseT2, values.salaryExpenseT3];
    //console.log(array)
    var sum = Number((values.salaryExpenseT1 || 0) + (values.salaryExpenseT2 || 0) + (values.salaryExpenseT3 || 0));
    number = Number(sum / (array?.filter(x => x != undefined && x != '').length ?? 1));
    number = number != null && number != undefined && !Number.isNaN(number) ? number : 0;
    return Number(Math.round(number ?? 0));
  }
  return (
    <>
      <DialogContent>
        <div className='flex mb-4'>
          <TextField
            label={t`Business Sector`}
            value={creditScore?.industryEn}
            InputProps={{ readOnly: true }}
            className='w-[240px]'
          />
        </div>

        <Stepper activeStep={2} className='mb-6'>
          <Step>
            <StepLabel>
              <Trans>Credit Scoring</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Input Assessment Information</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Assessment Result</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Approval Decision By Investment Committee</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        <Grid container className='border w-[320px]'>
          <Grid item md={7} className='font-bold px-3 py-1'>
            <Trans>CREDIT SCORE</Trans>
          </Grid>
          <Grid item md={5} className='font-bold text-center px-3 py-1 bg-gray-200'>
            {creditScoreCreate.totalScore}
          </Grid>
          <Grid item md={7} className='font-bold px-3 py-1'>
            <Trans>RATING</Trans>
          </Grid>
          <Grid item md={5} className='font-bold text-center px-3 py-1 bg-gray-200 text-red-700'>
            {creditScoreCreate.ratingCategory.ratingType}
          </Grid>
        </Grid>

        <Grid container columnSpacing={4}>
          <Grid item md={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Trans>Item</Trans>
                    </TableCell>
                    <TableCell className='w-60'>
                      <Trans>Value</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Salary Expense Year T-3 (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='salaryExpenseT3'
                        defaultValue=''
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Salary Expense Year T-2 (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='salaryExpenseT2'
                        defaultValue=''
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Salary Expense Year T-1 (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='salaryExpenseT1'
                        defaultValue=''
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            {...field}
                            error={invalid}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                              },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Average Salary Expense (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <div className='px-3 py-2 text-base text-right'>{averageSalaryExpense.toLocaleString()}</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Expected Salary Limit (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <div className='px-3 py-2 text-base text-right'>{expectedSalaryLimit.toLocaleString()}</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Limit Adjustment Ratio by Rating</Trans>
                    </TableCell>
                    <TableCell>
                      <div className='px-3 py-2 text-base text-right'>
                        {creditScoreCreate.ratingCategory.adjustmentRatio}%
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Other Adjustment (VND)</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='limitationOtherAdjustment'
                        defaultValue=''
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                thousandSeparator: ',',
                                className: 'text-right',
                                allowNegative: true,
                                decimalScale: 0,
                              },
                            }}
                            onBlur={() => { $(".testValidButton").trigger("click") }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Adjustment Reason</Trans>
                    </TableCell>

                    <TableCell>
                      <Controller
                        name='limitationAdjustmentReason'
                        defaultValue=''
                        control={control}
                        rules={{ required: values.limitationOtherAdjustment != undefined && values.limitationOtherAdjustment != null && values.limitationOtherAdjustment != "" ? true : false }}
                        render={({ field, fieldState: { invalid, error } }) =>
                          <TextField {...field} error={invalid} InputProps={{ readOnly: isSuccessLimitation }} />
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter className='bg-gray-200'>
                  <TableRow>
                    <TableCell className='font-bold text-sm'>
                      <Trans>Proposal Limit (VND)</Trans>
                    </TableCell>
                    <TableCell className='font-bold text-sm'>
                      <div className='text-red-700 text-right px-3'>{Number(proposalLimit).toLocaleString()}</div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item md={6}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Trans>Item</Trans>
                    </TableCell>
                    <TableCell className='w-60'>
                      <Trans>Value</Trans>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Trans>Flooring Discount Rate</Trans>
                    </TableCell>
                    <TableCell>
                      <div className='px-3 py-2 text-base text-right'>{creditScoreCreate.floorDiscountRate}%</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Incentive for Referral Partner</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='incentiveReferral'
                        defaultValue=''
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                maxLength: 5,
                                className: 'text-right',
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Enterprise's Guarantee</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='enterpriseGuarantee'
                        defaultValue=''
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                maxLength: 5,
                                className: 'text-right',
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Future Rate</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='futureRate'
                        defaultValue=''
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                maxLength: 5,
                                className: 'text-right',
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Other Adjustment</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='discountRateOtherAdjustment'
                        defaultValue=''
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              readOnly: isSuccessLimitation,
                              inputProps: {
                                maxLength: 3,
                                className: 'text-right',
                                allowNegative: true,
                                decimalScale: 0,
                              },
                              endAdornment: '%',
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Trans>Adjustment Reason</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='discountRateAdjustmentReason'
                        defaultValue=''
                        control={control}
                        render={({ field }) => <TextField {...field} InputProps={{ readOnly: isSuccessLimitation }} />}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className='h-[60px]'></TableRow>
                </TableBody>
                <TableFooter className='bg-gray-200'>
                  <TableRow>
                    <TableCell className='font-bold text-sm'>
                      <Trans>Earned Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell className='font-bold text-sm'>
                      <div className='text-red-700 text-right px-3'>{earnedDiscountRate.toFixed(2)}%</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='font-bold text-sm'>
                      <Trans>Future Discount Rate (APR)</Trans>
                    </TableCell>
                    <TableCell className='font-bold text-sm'>
                      <div className='text-red-700 text-right px-3'>{futureDiscountRate.toFixed(2)}%</div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={() => onBack()} className='absolute left-4 w-28'>
          <Trans>Back</Trans>
        </LoadingButton>

        <LoadingButton
          variant='contained'
          className='w-28'
          loading={isLoadingLimitation}
          onClick={isSuccessLimitation ? onNext : handleClickSubmit}
        >
          <Trans>Next</Trans>
        </LoadingButton>
        <LoadingButton
          variant='contained'
          className='w-28 testValidButton'
          style={{ display: 'none' }}
          loading={isLoadingLimitation}
          onClick={handleClickTestValid}
        >
          <Trans>Valid</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default BusinessCreditScoreStep2;
