import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from '@mui/material';
import { formatNumber } from 'utils/common';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const dataTable = [
  {
    name: '1.2022',
    transactionValue: 400,
    pv: 240,
  },
  {
    name: '2.2022',
    uv: 300,
    pv: 139,
  },
  {
    name: '3.2022',
    uv: 200,
    pv: 500,
  },
  {
    name: '4.2022',
    uv: 278,
    pv: 398,
  },
  {
    name: '5.2022',
    uv: 180,
    pv: 480,
  },
  {
    name: '6.2022',
    uv: 400,
    pv: 240,
  },
  {
    name: '7.2022',
    uv: 300,
    pv: 139,
  },
  {
    name: '8.2022',
    uv: 200,
    pv: 500,
  },
  {
    name: '9.2022',
    uv: 278,
    pv: 398,
  },
  {
    name: '10.2022',
    uv: 180,
    pv: 480,
  },
  {
    name: '11.2022',
    uv: 278,
    pv: 398,
  },
  {
    name: '12.2022',
    uv: 180,
    pv: 480,
  },
];

const BusinessResult = () => {
  return (
    <div className='border rounded-lg mt-2 p-4'>
      <Paper elevation={0} className='w-full' style={{ height: '400px' }}>
        <ResponsiveContainer>
          <LineChart data={dataTable}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey='name' />
            <YAxis ticks={[0, 100, 200, 300, 400, 500, 600]} />
            <Tooltip />
            <Legend />
            <Line type='monotone' dataKey='uv' name='Transaction Value' stroke='#b91c1c' />
            <Line type='monotone' dataKey='pv' name='Average Balance' stroke='#404040' />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableCell>Enterprise</TableCell>
            <TableCell>Employee</TableCell>
            <TableCell>Transaction Value (VND)</TableCell>
            <TableCell>Average Balance (VND)</TableCell>
            <TableCell>Transactions Number</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className='text-center'>15/30</TableCell>
              <TableCell className='text-center'>30/100</TableCell>
              <TableCell className='text-center'>{formatNumber(10000000)}</TableCell>
              <TableCell className='text-center'>{formatNumber(10000000)}</TableCell>
              <TableCell className='text-center'>{formatNumber(1000)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BusinessResult;
