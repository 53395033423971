import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { AdvanceSeniorityCreateType, AdvanceSeniorityType } from 'types/EnterpriseSetupAdvance';

type PopupProps = PopupController & {
  id: number;
  advanceSeniority?: AdvanceSeniorityType;
};

const AdvanceSeniorityCreatePopup = ({ id, advanceSeniority, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const isUpdate = !!advanceSeniority;

  const { mutate: createAdvanceSeniority, isLoading } = useMutation(
    isUpdate ? enterpriseSetupService.updateAdvanceSeniority : enterpriseSetupService.createAdvanceSeniority,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchAdvanceSenioritys');
        onClose();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values: AdvanceSeniorityCreateType) => {
      createAdvanceSeniority({
        ...values,
        id,
        snId: advanceSeniority?.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Advance Seniority</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={8}>
            <Controller
              name='minWorkYear'
              defaultValue={advanceSeniority?.minWorkYear ?? ''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Minimum years of work`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    readOnly: isUpdate,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={8}>
            <Controller
              name='maxWorkYear'
              defaultValue={advanceSeniority?.maxWorkYear ?? ''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Maximum years of work`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    readOnly: isUpdate,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name='rate'
              defaultValue={advanceSeniority?.rate ?? ''}
              control={control}
              rules={{ required: true, min: 0 }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t`Advance Ratio`}
                  error={invalid}
                  InputProps={{
                    inputComponent: InputNumber as any,
                    inputProps: {
                      // maxLength: 5,
                      className: 'text-right',
                    },
                    endAdornment: '%',
                  }}
                  onBlur={(e: any) => 
                  {                
                    if((Math.round((parseFloat(e.target.value) + Number.EPSILON) * 100) / 100) < 0){
                      setValue("rate", 0);
                    }else{
                      setValue("rate", (Math.round((parseFloat(e.target.value) + Number.EPSILON) * 100) / 100));
                    }
                  }
                  }
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AdvanceSeniorityCreatePopup;
