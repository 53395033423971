import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { transactionService, transactionOverdueService } from 'services';
import { TransInfo, TransDetail, TransPayment, TransAssignedUser } from 'views/Transaction/TransView/components';
import {
  TransWaitSettlement,
  TransReviseHis,
  TransSettlementRecordHis,
} from 'views/Transaction/TransSettlement/TransSettlementView/components';
import { TransOverdueDetail, TransOverdueRecordHis, TransOverdueTracking } from './components';

const TransOverdueView = () => {
  const { id: _id } = useParams() as { id: string };

  const { data: transaction, isSuccess } = useQuery(['transactionService.getTransactionById', { id: _id }], () =>
    transactionService.getTransactionById({ id: _id }),
  );

  const { data: overdue } = useQuery(['transactionOverdueService.getOverdueTransaction', { id: _id }], () =>
    transactionOverdueService.getOverdueTransaction({ id: _id }),
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Transaction Detail</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <TransInfo transaction={transaction} />
        <TransDetail transaction={transaction} />
        <TransPayment transaction={transaction} />
        {isSuccess && <TransWaitSettlement transaction={transaction} />}
        {isSuccess && <TransSettlementRecordHis transaction={transaction} />}
        {isSuccess && <TransReviseHis transaction={transaction} />}
        <TransOverdueTracking overdueTracking={overdue?.overdueTracking} />
        {isSuccess && <TransOverdueDetail overdueDetail={overdue?.overdueDetail} transaction={transaction} />}
        <TransOverdueRecordHis overdueRecords={overdue?.overdueHistories} />
        {isSuccess && <TransAssignedUser transaction={transaction} />}
      </div>
    </Paper>
  );
};

export default TransOverdueView;
