import { t } from '@lingui/macro';
import { Grid, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { EnterpriseType } from 'types/Enterprise';

const EnterpriseViewInfo = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { control } = useForm({ mode: 'onChange' });

  return (
    <div className='mt-6'>
      <Grid container columnSpacing={4} rowSpacing={3}>
        <Grid item sm={6}>
          <Controller
            name='name'
            defaultValue={enterprise?.name}
            control={control}
            rules={{ required: t`Enterprise Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Enterprise Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='taxCode'
            defaultValue={enterprise?.taxCode}
            control={control}
            rules={{ required: t`Tax Code is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Tax Code`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='businessSector'
            defaultValue={enterprise?.businessSector}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Business Sector`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name='address'
            defaultValue={enterprise?.address}
            control={control}
            rules={{ required: t`Address is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                required
                label={t`Address`}
                {...field}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='phone'
            defaultValue={enterprise?.phone}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                label={t`Phone`}
                {...field}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='fax'
            defaultValue={enterprise?.fax}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                label={t`Fax`}
                {...field}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={3}>
          <Controller
            name='ercNumber'
            defaultValue={enterprise?.ercNumber}
            control={control}
            rules={{ required: t`ERC Number is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`ERC Number`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='firstGrantedDate'
            defaultValue={
              enterprise?.firstGrantedDate ? DateTime.fromISO(enterprise?.firstGrantedDate).toFormat('dd/MM/yyyy') : ''
            }
            control={control}
            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`First Granted Date`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='issuanceAgency'
            defaultValue={enterprise?.issuanceAgency}
            control={control}
            rules={{ required: t`Issuance Agency is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Issuance Agency`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}></Grid>

        <Grid item sm={3}>
          <Controller
            name='amendmentNo'
            defaultValue={enterprise?.amendmentNo}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Amendment No`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='lastAmendmentDate'
            defaultValue={
              enterprise?.lastAmendmentDate ? DateTime.fromISO(enterprise?.lastAmendmentDate).toFormat('dd/MM/yyyy') : ''
            }
            control={control}
            rules={{ validate: { isValid: (value: DateTime) => !value || value.isValid } }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Last Granted Date`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={6}></Grid>

        <Grid item sm={3}>
          <Controller
            name='authorizedRepresentative'
            defaultValue={enterprise?.authorizedRepresentative}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Authorized Representative`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='authorizedRepresentativePosition'
            defaultValue={enterprise?.authorizedRepresentativePosition}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Authorized Representative Position`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='attorneyLetterNo'
            defaultValue={enterprise?.attorneyLetterNo}
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Attorney Letter No`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='attorneySigningDate'
            defaultValue={
              enterprise?.attorneySigningDate
                ? DateTime.fromISO(enterprise?.attorneySigningDate).toFormat('dd/MM/yyyy')
                : ''
            }
            control={control}
            rules={{ validate: { isValid: (value: DateTime) => !value || value.isValid } }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Attorney Signing Date`}
                InputProps={{ readOnly: true }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item sm={3}>
          <Controller
            name='legalRepresentative'
            defaultValue={enterprise?.legalRepresentative}
            control={control}
            rules={{ required: t`Legal Representative Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Legal Representative`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='legalRepresentativePosition'
            defaultValue={enterprise?.legalRepresentativePosition}
            control={control}
            rules={{ required: t`Legal Representative Position is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Legal Representative Position`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={6}></Grid>

        <Grid item sm={3}>
          <Controller
            name='contactName'
            defaultValue={enterprise?.contactName}
            control={control}
            rules={{ required: t`Contact Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Contact Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='contactDepartment'
            defaultValue={enterprise?.contactDepartment}
            control={control}
            rules={{ required: 'Contact Department is required' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Contact Department`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='contactPhone'
            defaultValue={enterprise?.contactPhone}
            control={control}
            rules={{ required: t`Contact Phone is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Contact Phone`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='contactEmail'
            defaultValue={enterprise?.contactEmail}
            control={control}
            rules={{ required: t`Contact Email is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                InputProps={{ readOnly: true }}
                label={t`Contact Email`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EnterpriseViewInfo;
