import { t, Trans } from '@lingui/macro';
import { EmailRounded, Lock, Person, PhoneRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AppBar, Container, InputAdornment, Link as MuiLink, TextField, Toolbar, Typography } from '@mui/material';
import { EpayzFooter } from 'containers';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { profileSelector, signIn, signOut } from 'reducers/profile';
import { setUserLocale } from 'reducers/user-settings';
import { epayzRoute, privateRoute } from 'routes';
import { authService, userService } from 'services';
import { store } from 'reducers';
import { openAlert } from 'reducers/notification';
import { sha256 } from 'utils/common';

const Header = () => {
  const dispatch = useDispatch();
  const userLocale = useActiveLocale();
  return (
    <AppBar position='static'>
      <Toolbar>
        <Container className='flex items-center py-4'>
          <div className='flex-1 flex justify-center'>
            <Link to='/'>
              <img src={require('assets/images/Logo_IFZ.png')} className='h-20 ml-16' />
            </Link>
          </div>
          <div className='ml-4 mr-12'>
            {[
              { locale: 'en-US', label: 'EN' },
              { locale: 'vi-VN', label: 'VN' },
            ].map((item, index) => (
              <Fragment key={index}>
                <MuiLink
                  className={
                    (userLocale === item.locale ? 'text-white' : 'text-white/60') +
                    ' hover:text-white cursor-pointer font-bold no-underline'
                  }
                  onClick={() => dispatch(setUserLocale(item.locale))}
                >
                  {item.label}
                </MuiLink>
                {index === 0 && <span className='mx-1.5'>|</span>}
              </Fragment>
            ))}
          </div>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

const Contact = () => (
  <div className='flex justify-between mt-10'>
    <div className='flex items-center gap-1'>
      <PhoneRounded />
      <MuiLink href='tel:1900121211'>1900 1212 11</MuiLink>
    </div>
    <div className='flex items-center gap-1'>
      <EmailRounded />
      <MuiLink href='mailto:support@IFZ.com.vn'>support@IFZ.com.vn</MuiLink>
    </div>
  </div>
);

const LoginEpayz = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector(profileSelector);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(privateRoute.home.path, { replace: true });
    }
  }, [navigate, isLoggedIn]);

  const { control, handleSubmit } = useForm();

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: ({ data }) => {
      dispatch(signIn(data));
      userService.getProfile().then((profile) => {
        if (!profile) {
          store.dispatch(
            openAlert({
              message: t`Username or password is incorrect`,
              code: 400,
              variant: 'error',
            }),
          );
          dispatch(signOut());
          return;
        }
        dispatch(signIn(profile));
      });
    },
    onError: () => {
      store.dispatch(
        openAlert({
          message: t`Username or password is incorrect`,
          code: 400,
          variant: 'error',
        }),
      );
    },
  });

  const handleClickLogin = () => {
    handleSubmit(({ username, password }) => {
      login({
        username,
        password: sha256(password),
      });
    })();
  };

  return (
    <>
      <Header />
      <Container maxWidth='sm' className='flex flex-col py-32'>
        <Typography className='text-center text-5xl'>
          <Trans>Welcome back!</Trans>
        </Typography>
        <Typography className='text-center mt-8 mb-12'>
          <Trans>Please login with your registered username & password</Trans>
        </Typography>

        <Container maxWidth='xs'>
          <Controller
            name='username'
            control={control}
            rules={{ required: t`Username is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                size='medium'
                placeholder={t`Username`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Person />
                    </InputAdornment>
                  ),
                }}
                className='mb-6'
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name='password'
            control={control}
            rules={{ required: t`Password is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                size='medium'
                placeholder={t`Password`}
                type='password'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                className='mb-6'
                error={invalid}
                helperText={error?.message}
              />
            )}
          />

          <div className='flex justify-end mb-10'>
            <Link to={epayzRoute.forgotPassword.path}>
              <span className='italic hover:underline'>
                <Trans>Forgot password?</Trans>
              </span>
            </Link>
          </div>
          <div className='flex justify-center'>
            <LoadingButton
              variant='contained'
              size='large'
              className='w-60 bg-primary-main hover:opacity-90'
              loading={isLoading}
              onClick={handleClickLogin}
            >
              <Trans>LOGIN</Trans>
            </LoadingButton>
          </div>
          <Contact />
        </Container>
      </Container>

      <EpayzFooter />
    </>
  );
};

LoginEpayz.Header = Header;
LoginEpayz.Contact = Contact;

export default LoginEpayz;
