import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Save } from '@mui/icons-material';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { CreditScoreType } from 'types/CreditScore';
import { usePermission } from 'hooks';

const ExpireDate = ({ creditScore }: { creditScore?: CreditScoreType }) => {
  const { requireRole } = usePermission();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm();

  const { mutate: updateCreditScore, isLoading } = useMutation(creditScoreService.updateCreditScore, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.getCreditScoreById');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      const { expireDate } = values as { expireDate: DateTime };
      updateCreditScore({
        id: creditScore?.id!,
        expireDate: expireDate.toISO(),
        qualitativeProportion: creditScore?.qualitativeProportion!,
      });
    })();
  };

  useEffect(() => {
    if (creditScore?.expireDate) {
      setValue('expireDate', DateTime.fromISO(creditScore?.expireDate));
    }
  }, [creditScore, setValue]);

  return (
    <div className='flex items-center gap-3 mt-3'>
      <Controller
        name='expireDate'
        defaultValue={DateTime.now()}
        control={control}
        rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
          <DesktopDatePicker
            value={value}
            onChange={onChange}
            renderInput={(props) => <TextField {...props} required error={invalid} />}
            label={t`Expire Date`}
            inputFormat='dd/MM/yyyy'
          />
        )}
      />
      {requireRole('CA_SENIOR') && (
        <LoadingButton
          variant='outlined'
          sx={{ padding: 0.5, minWidth: 5 }}
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          <Save />
        </LoadingButton>
      )}
    </div>
  );
};

export default ExpireDate;
