import React from 'react';
import NumberFormat from 'react-number-format';

const InputNumberNegative = React.forwardRef(({ onChange, allowNegative, ...props }, ref) => (
  <NumberFormat
    getInputRef={ref}
    allowNegative={true}
    {...props}
    onValueChange={({ floatValue }) => {
      onChange({ target: { value: floatValue } });
    }}
  />
));

export default InputNumberNegative;
