import { useState } from 'react';
import { PopupController } from 'types/Common';
import {
  EnterpriseSettingFeePopupStep1,
  SingleServicePopupStep2,
  SingleServicePopupStep3,
  SingleServicePopupStep4,
  FixedServicePopupStep2,
  FixedServicePopupStep3,
  FixedServicePopupStep4,
  FixedServicePopupStep5,
  FixedServicePopupStep6,
  FixedServicePopupStep7,
  ManualPopupStep4,
  ManualPopupStep5,
} from 'views/Enterprise/EnterpriseServiceFee/popups';
import { AppendixObjectDataType, AppendixPayloadType, EnterpriseServiceFeeSingleType } from 'types/EnterpriseSetup';
import { ObjectType } from 'types/Object';
import { usePermission } from 'hooks';

type PopupProps = PopupController & {
  enterpriseId: string;
  stepCurrent?: number;
  dataItem?: EnterpriseServiceFeeSingleType;
};

const EnterpriseSettingFeePopup = ({ stepCurrent, dataItem, enterpriseId, onClose }: PopupProps) => {
  debugger;
  const [step, setStep] = useState(stepCurrent || 1);
  const [typeFeeSetting, setTypeFeeSetting] = useState(dataItem?.appliedType || '');
  const [objectData, setObjectData] = useState<AppendixObjectDataType>(dataItem as AppendixObjectDataType);
  const [appendixPayload, setAppendixPayload] = useState<AppendixPayloadType>();
  const [appendixCreate, setAppendixCreate] = useState<EnterpriseServiceFeeSingleType>(dataItem!);
  const [objectManualData, setObjectManualData] = useState<ObjectType[]>([]);

  const { requireRole } = usePermission();

  return (
    <>
      {step === 1 && (
        <EnterpriseSettingFeePopupStep1
          onBack={onClose}
          onNext={() => setStep(2)}
          setTypeFeeSetting={setTypeFeeSetting}
          typeFeeSetting={typeFeeSetting}
        />
      )}
      {step === 2 &&
        (typeFeeSetting === 'FIXED_SERVICE_FEE' ? (
          <FixedServicePopupStep2
            enterpriseId={enterpriseId}
            onBack={() => setStep(1)}
            onNext={() => setStep(3)}
            setObjectData={setObjectData}
            appendixPayload={appendixPayload!}
            setAppendixPayload={setAppendixPayload}
          />
        ) : (
          <SingleServicePopupStep2
            setAppendixCreate={setAppendixCreate}
            enterpriseId={enterpriseId}
            onBack={() => setStep(1)}
            onNext={() => (requireRole('ENTERPRISE', false) ? setStep(3) : setStep(4))}
          />
        ))}

      {step === 3 &&
        (typeFeeSetting === 'FIXED_SERVICE_FEE' ? (
          <FixedServicePopupStep3
            objectData={objectData!}
            onBack={() => setStep(2)}
            onNext={() => setStep(4)}
            appendixPayload={appendixPayload!}
            setAppendixPayload={setAppendixPayload}
            setAppendixCreate={setAppendixCreate}
          />
        ) : (
          <SingleServicePopupStep3
            enterpriseId={enterpriseId}
            appendixId={appendixCreate?.id!}
            onBack={onClose}
            onNext={() => setStep(4)}
          />
        ))}

      {step === 4 &&
        (typeFeeSetting === 'FIXED_SERVICE_FEE' ? (
          appendixCreate?.appendixType === 'DIGITAL' ? (
            <FixedServicePopupStep4
              enterpriseId={enterpriseId}
              appendixId={appendixCreate?.id!}
              objectData={objectData!}
              onBack={onClose}
              onNext={() => setStep(5)}
            />
          ) : (
            <ManualPopupStep4
              enterpriseId={enterpriseId}
              appendixId={appendixCreate?.id!}
              onBack={onClose}
              onNext={onClose}
              setObjectManualData={setObjectManualData}
              objectManualData={objectManualData}
            />
          )
        ) : (
          <SingleServicePopupStep4 enterpriseId={enterpriseId} onBack={onClose} />
        ))}

      {step === 5 &&
        typeFeeSetting === 'FIXED_SERVICE_FEE' &&
        (appendixCreate?.appendixType === 'DIGITAL' ? (
          <FixedServicePopupStep5
            enterpriseId={enterpriseId}
            appendixId={appendixCreate?.id!}
            objectData={objectData!}
            onBack={onClose}
            onNext={() => setStep(6)}
          />
        ) : (
          <ManualPopupStep5
            enterpriseId={enterpriseId}
            appendixId={appendixCreate?.id!}
            onNext={onClose}
            objectData={objectData!}
          />
        ))}

      {step === 6 && typeFeeSetting === 'FIXED_SERVICE_FEE' && (
        <FixedServicePopupStep6
          enterpriseId={enterpriseId}
          appendixId={appendixCreate?.id!}
          objectData={objectData!}
          onBack={onClose}
          onNext={() => setStep(7)}
        />
      )}

      {step === 7 &&
        (typeFeeSetting === 'FIXED_SERVICE_FEE' ? (
          <FixedServicePopupStep7 objectData={objectData!} onBack={onClose} />
        ) : (
          <SingleServicePopupStep4 enterpriseId={enterpriseId} onBack={onClose} />
        ))}
    </>
  );
};

export default EnterpriseSettingFeePopup;
