import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { employeeService, queryClient } from 'services';
import { EmployeeType, EmployeeUpdateType } from 'types/Employee';
import { usePermission } from 'hooks';
const EmployeeInfo = ({ employee }: { employee?: EmployeeType }) => {
  const GENDER_TYPES = [
    { value: 'MALE', label: t`Male` },
    { value: 'FEMALE', label: t`Female` },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const { EMPLOYEE_ACTIVE, EMPLOYEE_UPDATE, requreUserPermission } = usePermission();
  useEffect(() => {
    Object.entries(employee ?? {}).forEach(([key, value]) => {
      if (['firstGrantedDate', 'birthday'].includes(key)) {
        setValue(key, DateTime.fromISO(value as string));
      } else setValue(key, value);
    });
  }, [employee, setValue]);

  const { mutate: updateEmployee, isLoading } = useMutation(employeeService.updateEmployee, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
    },
  });
  const { mutate: activateUser, isLoading: isLoadingActivate } = useMutation(employeeService.activateUser, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.getEmployeeById');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = EmployeeUpdateType & {
        firstGrantedDate: DateTime;
        birthday: DateTime;
      };
      const { firstGrantedDate, birthday, ...others } = values as ValuesType;

      var valid = validate(others);

      if (!valid) {
        return;
      }

      updateEmployee({
        ...others,
        firstGrantedDate: firstGrantedDate.toISO(),
        birthday: birthday.toISO(),
        id: employee?.id!,
      });
    })();
  };

  const validate = (data: any) => {
    var strValid = "";
    if (data.name) {
      if (data.name.trim() == '') {
        strValid += strValid ? ("\n" + t`Employee Name is required`) : t`Employee Name is required`;
      }
    }
    if (data.ercNumber) {
      if (data.ercNumber.trim() == '') {
        strValid += strValid ? ("\n" + t`ID Card / Passport is required`) : t`ID Card / Passport is required`;
      }
    }
    if (data.issuanceAgency) {
      if (data.issuanceAgency.trim() == '') {
        strValid += strValid ? ("\n" + t`Issuance Agency is required`) : t`Issuance Agency is required`;
      }
    }
    if (data.address) {
      if (data.address.trim() == '') {
        strValid += strValid ? ("\n" + t`Address is required`) : t`Address is required`;
      }
    }
    if (data.phone) {
      if (data.phone.trim() == '') {
        strValid += strValid ? ("\n" + t`Phone is required`) : t`Phone is required`;
      }
    }
    if (data.email) {
      if (data.email.trim() == '') {
        strValid += strValid ? ("\n" + t`Email is required`) : t`Email is required`;
      }
    }
    if (data.contactAddress) {
      if (data.contactAddress.trim() == '') {
        strValid += strValid ? ("\n" + t`Contact Address is required`) : t`Contact Address is required`;
      }
    }

    if (strValid) {
      enqueueSnackbar(strValid, { variant: 'warning', style: { whiteSpace: 'pre-line' } })
      return false;
    } else {
      return true;
    }
  }

  const handleClickActivateUser = () => {
    activateUser({
      id: employee?.id!,
    });
  };

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Employee Information</Trans>
          </Typography>

          {requreUserPermission([EMPLOYEE_ACTIVE]) && employee?.status != "STOP_WORKING" && <LoadingButton
            variant='contained'
            size='small'
            loading={isLoadingActivate}
            disabled={employee?.hasUser}
            onClick={() => handleClickActivateUser()}
          >
            <Trans>Activate User</Trans>
          </LoadingButton>}
        </div>
      </div>

      <Grid container columnSpacing={4} rowSpacing={3} className='mt-3'>
        <Grid item sm={3}>
          <Controller
            name='name'
            defaultValue=''
            control={control}
            rules={{ required: t`Employee Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Employee Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='birthday'
            defaultValue=''
            control={control}
            rules={{
              validate: { isValid: (value: DateTime) => value && value.isValid },
              required: t`Field is required`,
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`Birthday`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='gender'
            defaultValue=''
            control={control}
            rules={{ required: t`Gender is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                select
                required
                label={t`Gender`}
                error={invalid}
                helperText={error?.message}
              >
                {GENDER_TYPES.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item sm={3}></Grid>

        <Grid item sm={3}>
          <Controller
            name='ercNumber'
            defaultValue=''
            control={control}
            rules={{ required: t`ID Card / Passport is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`ID Card / Passport`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='firstGrantedDate'
            defaultValue=''
            control={control}
            rules={{
              validate: { isValid: (value: DateTime) => value && value.isValid },
              required: t`Field is required`,
            }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`Issuance Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='issuanceAgency'
            defaultValue=''
            control={control}
            rules={{ required: t`Issuance Agency is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Issuance Agency`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}></Grid>

        <Grid item sm={6}>
          <Controller
            name='address'
            defaultValue=''
            control={control}
            rules={{ required: t`Address is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField fullWidth required label={t`Address`} {...field} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='phone'
            defaultValue=''
            control={control}
            rules={{ required: t`Phone is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField fullWidth required label={t`Phone`} {...field} error={invalid} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='email'
            defaultValue=''
            control={control}
            //rules={{ required: t`Email is required`, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField fullWidth required label='Email'{...field} error={invalid} helperText={error?.message} />
            )}
            rules={{
              validate: (e) => {
                if (!e) {
                  return t`Email is required`;
                } else if (e.length == 0) {
                  return t`Email is required`;
                } else if (!e.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                  return t`Email is invalid`;
                }
                return true;
              }
            }}
          />
        </Grid>

        <Grid item sm={6}>
          <Controller
            name='contactAddress'
            defaultValue=''
            control={control}
            rules={{ required: t`Contact Address is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                required
                label={t`Contact Address`}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='registrationNo'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                label={t`Registration Book No`}
                {...field}
                error={invalid}
                helperText={error?.message}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setValue("registrationNo", e.target.value)
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={3}>
          <Controller
            name='socialInsuranceNo'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                fullWidth
                label={t`Social Insurance Book No`}
                {...field}
                error={invalid}
                helperText={error?.message}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    setValue("socialInsuranceNo", e.target.value)
                  }
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {requreUserPermission([EMPLOYEE_UPDATE]) &&
        <div className='flex justify-end mt-4'>

          <LoadingButton variant='contained' className='w-32' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      }
    </div>
  );
};

export default EmployeeInfo;
