import { UploadFileType } from 'types/Object';
import { FileDataType } from 'types/Common';
import { clientUpload, clientDownload } from './axios';

const uploadFileTemplate = (key: string, body: FormData): Promise<UploadFileType> =>
  clientUpload.post(`/api/v1/employees/import/${key}`, body);

const downloadFileBase64 = (key: string): Promise<FileDataType> => clientDownload.get(`/api/v1/templates/${key}`);

export default {
  uploadFileTemplate,
  downloadFileBase64,
};
