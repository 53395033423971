import { useState, useRef } from 'react';
import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
} from '@mui/material';
import { Upload } from '@mui/icons-material';
import { employeeLaborService, queryClient } from 'services';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

type PopupProps = {
  value: string;
  onBack: () => void;
  onNext: () => void;
  setValue: (args: any) => void;
};

const AttendanceUpdateEnterprise = ({ value, onBack, onNext, setValue }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>();

  const { mutate: uploadFileEnterprise, isLoading } = useMutation(employeeLaborService.uploadFileEnterprise, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeLaborService.fetchEmployeeLabors');
      onNext();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      if (fileSelected && value) {
        const formData = new FormData();
        formData.append('file', fileSelected, fileSelected.name);
        formData.append('start_row', values.startRow);
        formData.append('labor_col', values.laborCol);
        formData.append('employee_col', values.employeeCol);
        uploadFileEnterprise(formData);
      } else {
        enqueueSnackbar('Please select file', { variant: 'warning' });
      }
    })();
  };

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };
  return (
    <>
      <DialogTitle>
        <Trans>UPDATE LABOUR BY ENTERPRISE'S TEMPLATE</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-center justify-between space-x-3'>
          <Typography variant='h6'>
            <Trans>Column of information</Trans>
          </Typography>
          <TableContainer component={Paper} variant='outlined'>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Trans>Staff ID</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Column</Trans>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name='employeeCol'
                      defaultValue=''
                      control={control}
                      rules={{ required: t`Staff ID is required` }}
                      render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          required
                          error={invalid}
                          helperText={error?.message}
                        ></TextField>
                      )}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Trans>No. of Earned Labour</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Column</Trans>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name='laborCol'
                      defaultValue=''
                      control={control}
                      rules={{ required: t`No. of Earned Labour is required` }}
                      render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          required
                          error={invalid}
                          helperText={error?.message}
                        ></TextField>
                      )}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Trans>Row starts to get data</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Row</Trans>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name='startRow'
                      defaultValue=''
                      control={control}
                      rules={{ required: t`Row starts is required` }}
                      render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          required
                          error={invalid}
                          helperText={error?.message}
                        ></TextField>
                      )}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className='mt-6'>
          <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} />
          <div className='space-x-3'>
            <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
              <Trans>Choose Files</Trans>
            </LoadingButton>
            {fileSelected && (
              <Chip
                key={fileSelected?.name}
                variant='outlined'
                label={fileSelected?.name}
                onDelete={() => {
                  setFileSelected(null);
                  if (inputRef.current?.value) {
                    inputRef.current.value = '';
                  }
                }}
              />
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button
            variant='outlined'
            onClick={() => {
              onBack();
              setValue('');
            }}
          >
            <Trans>Back</Trans>
          </Button>
          <LoadingButton
            variant='contained'
            size='small'
            loading={isLoading}
            startIcon={<Upload />}
            onClick={handleClickSubmit}
          >
            <Trans>Upload</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default AttendanceUpdateEnterprise;
