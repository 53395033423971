import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { CheckCircleOutline, Download, Upload } from '@mui/icons-material';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import {
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { employeeService, fileService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ChecklistDocCreateType, ChecklistDocType, ChecklistLegalType } from 'types/EmployeeChecklist';
import { ObjectType } from 'types/Object';
import { download } from 'utils/common';
import { TableEmpty } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import SelectPageSize from 'components/SelectPageSize';

type PopupProps = PopupController & {
  legal: ChecklistLegalType;
  employeeId: string;
};

const ChecklistContractPopup = ({ legal, employeeId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const [enterpriseId, setEnterpriseId] = useState<number | string>('');
  const [dataSelect, setDataSelect] = useState<ChecklistDocType[]>([]);
  const [dataSearch, onSearchChange] = useSearch({
    _id: employeeId,
    legalTypeId: legal.id,
    refEnterpriseId: enterpriseId,
  });
  const [objects, setObjects] = useState<ObjectType[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: dataEnterprise } = useQuery(
    ['employeeService.fetchEmployeeWorkings', { _id: employeeId }],
    () => employeeService.fetchEmployeeWorkings({ _id: employeeId, size: 100 }),
    {
      onSuccess: ({ data: items }) => {
        if (items[0]) {
          setEnterpriseId(items[0].enterprise.id);
          onSearchChange({ refEnterpriseId: items[0].enterprise.id });
        }
      },
    },
  );
  const { data: enterprises = [] } = dataEnterprise ?? {};

  const { data } = useQuery(
    ['employeeService.fetchEmployeeChecklistDoc', dataSearch],
    () => employeeService.fetchEmployeeChecklistDoc(dataSearch),
    { enabled: !!enterpriseId },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: createEmployeeChecklistDoc, isLoading } = useMutation(employeeService.createEmployeeChecklistDoc, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.fetchEmployeeChecklistDoc');
      queryClient.invalidateQueries('employeeService.fetchEmployeeChecklist');
      setObjects([]);
    },
  });

  const { mutate: confirmDoc, isLoading: isLoadingConfirm } = useMutation(
    employeeService.confirmEmployeeChecklistContract,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('employeeService.fetchEmployeeChecklistDoc');
        queryClient.invalidateQueries('employeeService.fetchEmployeeChecklist');
        setObjects([]);
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = ChecklistDocCreateType & { signedAt: DateTime };
      const { signedAt, ...others } = values as ValuesType;

      createEmployeeChecklistDoc({
        ...others,
        _id: employeeId,
        refEnterpriseId: enterpriseId!,
        legalTypeId: legal.id,
        objectIds: objects.map((item) => item.id),
        signedAt: signedAt.toISO(),
      });
    })();
  };

  const handleClickConfirm = () => {
    confirmDoc({
      _id: employeeId,
      legalDocId: dataSelect[0].id,
    });
  };

  const { mutate: uploadFiles, isLoading: isLoadingUpload } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjects(data.objects);
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(event.target.files!).forEach((file) => {
      formData.append('files', file);
    });
    uploadFiles(formData);
  };

  return (
    <>
      <DialogTitle>
        <Trans>Review Legal</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex justify-between mt-2'>
          <Grid container columnSpacing={4} rowSpacing={3}>
            <Grid item sm={6}>
              <TextField
                select
                value={enterpriseId}
                label={t`Enterprise`}
                fullWidth
                onChange={(event) => {
                  setEnterpriseId(event.target.value);
                  onSearchChange({ refEnterpriseId: event.target.value });
                }}
              >
                {enterprises.map((item) => (
                  <MenuItem key={item.id} value={item.enterprise.id}>
                    {item.enterprise.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sm={3}>
              <Controller
                name='contractNo'
                defaultValue=''
                control={control}
                rules={{ required: t`Contract No is required` }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField fullWidth required label={t`Contract No`} {...field} error={invalid} />
                )}
              />
            </Grid>
            <Grid item sm={3}>
              <Controller
                name='signedAt'
                defaultValue=''
                control={control}
                rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                  <DesktopDatePicker
                    value={value}
                    onChange={onChange}
                    renderInput={(props) => <TextField {...props} required error={invalid} />}
                    label={t`Signing Date`}
                    inputFormat='dd/MM/yyyy'
                  />
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-between mt-2'>
          <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} />
          <LoadingButton
            variant='outlined'
            size='small'
            loading={isLoadingUpload}
            onClick={() => inputRef.current?.click()}
          >
            <Trans>Choose Files</Trans>
          </LoadingButton>

          <LoadingButton
            variant='contained'
            size='small'
            startIcon={<Upload />}
            disabled={objects.length === 0}
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            <Trans>Upload</Trans>
          </LoadingButton>
        </div>

        <div className='flex flex-wrap gap-2 mt-2'>
          {objects.map((file) => (
            <Chip
              key={file.id}
              variant='outlined'
              label={file.originalName}
              onDelete={() => setObjects((items) => items.filter((item) => item.id !== file.id))}
            />
          ))}
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Trans>File</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Creator</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Contract No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Signing Date</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className='p-1 text-center'>
                    <Checkbox
                      checked={dataSelect.some((next) => next.id === item.id)}
                      onChange={(event, checked) => {
                        if (checked) setDataSelect((nexts) => nexts.concat(item));
                        else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                      }}
                    />
                  </TableCell>
                  <TableCell>{item.active && <CheckCircleOutline color='success' />}</TableCell>
                  <TableCell>{item.object.originalName}</TableCell>
                  <TableCell>{item.createdUser.fullname}</TableCell>
                  <TableCell>{item.contractNo}</TableCell>
                  <TableCell className='text-center'>
                    {item.signedAt ? DateTime.fromISO(item.signedAt).toFormat('dd/MM/yyyy') : ''}
                  </TableCell>
                  <TableCell>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        fileService.downloadFile(item.object.key).then((data) => {
                          download(data, item.object.originalName, item.object.contentType);
                        });
                      }}
                    >
                      <Download />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton
          disabled={dataSelect.length === 0}
          variant='contained'
          loading={isLoadingConfirm}
          onClick={handleClickConfirm}
        >
          <Trans>Confirm</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ChecklistContractPopup;
