import { Trans } from '@lingui/macro';
import { Delete, Edit } from '@mui/icons-material';
import {
  Button,
  Dialog,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import { usePermission, useSearch } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { priceSetupSerivice } from 'services';
import { BankFeeType } from 'types/PriceSetup';
import { BankFeeCreatePopup, BankFeeRemovePopup } from 'views/GeneralSetup/SettingCost/popups';
import { formatNumber } from 'utils/common';

const SettingBank = () => {
  const [dataSearch, onSearchChange] = useSearch();
  const { requireUserPCManager } = usePermission();

  const [isOpenGroup, setOpenGroup] = useState(false);
  const [isOpenRemove, setOpenRemove] = useState(false);
  const [chosenBank, setChosenBank] = useState<BankFeeType>();

  const { data } = useQuery(
    ['priceSetupSerivice.fetchBankFees', dataSearch],
    () => priceSetupSerivice.fetchBankFees(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <div></div>
    // <div>
    //   <div className='flex items-center gap-3'>
    //     <Typography variant='h4'>
    //       <Trans>Bank Collection Fee</Trans>
    //     </Typography>
    //     {requireUserPCManager() && (
    //       <Button
    //         variant='contained'
    //         onClick={() => {
    //           setOpenGroup(true);
    //           setChosenBank(undefined);
    //         }}
    //       >
    //         <Trans>Add</Trans>
    //       </Button>
    //     )}
    //   </div>

    //   <TableContainer component={Paper}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>
    //             <Trans>Bank</Trans>
    //           </TableCell>
    //           <TableCell>
    //             <Trans>Collection Fee</Trans>
    //           </TableCell>
    //           {requireUserPCManager() && <TableCell></TableCell>}
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {items.map((item) => (
    //           <TableRow key={item.id}>
    //             <TableCell>{item.bank.name}</TableCell>
    //             <TableCell className='text-center'>{formatNumber(item.fee)}</TableCell>
    //             {requireUserPCManager() && (
    //               <TableCell className='text-right space-x-1'>
    //                 <IconButton
    //                   size='small'
    //                   color='info'
    //                   onClick={() => {
    //                     setOpenGroup(true);
    //                     setChosenBank(item);
    //                   }}
    //                 >
    //                   <Edit fontSize='small' />
    //                 </IconButton>
    //                 <IconButton
    //                   size='small'
    //                   color='error'
    //                   onClick={() => {
    //                     setOpenRemove(true);
    //                     setChosenBank(item);
    //                   }}
    //                 >
    //                   <Delete fontSize='small' />
    //                 </IconButton>
    //               </TableCell>
    //             )}
    //           </TableRow>
    //         ))}
    //         <TableEmpty data={items} />
    //       </TableBody>
    //     </Table>
    //   </TableContainer>

    //   <div className='flex justify-center'>
    //     <Pagination
    //       page={paginate?.page ?? 1}
    //       count={paginate?.totalPage}
    //       onChange={(event, value) => onSearchChange({ page: value })}
    //     />
    //   </div>

    //   <Dialog fullWidth maxWidth='xs' open={isOpenGroup} onClose={() => setOpenGroup(false)}>
    //     <BankFeeCreatePopup bankFee={chosenBank} onClose={() => setOpenGroup(false)} />
    //   </Dialog>
    //   <Dialog fullWidth maxWidth='xs' open={isOpenRemove} onClose={() => setOpenRemove(false)}>
    //     <BankFeeRemovePopup bankFee={chosenBank!} onClose={() => setOpenRemove(false)} />
    //   </Dialog>
    // </div>
  );
};

export default SettingBank;
