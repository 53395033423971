import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import {
  EnterpriseProposalHistory,
  EnterpriseProposalInformation,
} from 'views/Enterprise/EnterpriseProposal/components';

const AssignmentProposal = () => {
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Assignment Proposal</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <EnterpriseProposalInformation />
        <EnterpriseProposalHistory />
      </div>
    </Paper>
  );
};

export default AssignmentProposal;
