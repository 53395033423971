import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { EnterpriseSettingType, EnterpriseSettingUpdateType } from 'types/EnterpriseSetup';
import { usePermission } from 'hooks';
import React from 'react';

const EnterpriseFullTimeAdvance = ({ setting }: { setting?: EnterpriseSettingType }) => {
  const { isCaRole } = usePermission();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const { mutate: createFullTimeConditions, isLoading } = useMutation(enterpriseSetupService.createFullTimeConditions, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.getGeneralSettings');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: EnterpriseSettingUpdateType) => {
      createFullTimeConditions({ ...values, id: setting?.enterpriseId! });
    })();
  };

  return (
    <div>
      <Typography variant="h4" className="mt-4">
        <Trans>Advance Conditions</Trans>
      </Typography>

      <div className="flex items-center gap-4">
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Trans>Advance Term</Trans>
                </TableCell>
                <TableCell colSpan={2} className="flex items-center gap-2">
                  <Controller
                    name="fromAdvanceTerm"
                    defaultValue={setting?.fromAdvanceTerm}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={invalid}
                        InputProps={{ inputComponent: InputNumber as any, endAdornment: t`Day` }}
                      />
                    )}
                  />
                  <Remove fontSize="small" />
                  <Controller
                    name="toAdvanceTerm"
                    defaultValue={setting?.toAdvanceTerm}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={invalid}
                        InputProps={{
                          inputComponent: InputNumber as any,
                          endAdornment: t`Day`,
                        }}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Advance Amount</Trans>
                </TableCell>
                <TableCell colSpan={2} className="flex items-center gap-2">
                  <Controller
                    name="fromAdvanceAmount"
                    defaultValue={setting?.fromAdvanceAmount}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={invalid}
                        InputProps={{
                          inputComponent: InputNumber as any,
                          inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                        }}
                      />
                    )}
                  />
                  <Remove fontSize="small" />
                  <Controller
                    name="toAdvanceAmount"
                    defaultValue={setting?.toAdvanceAmount}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={invalid}
                        InputProps={{
                          inputComponent: InputNumber as any,
                          inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                        }}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Trans>Party bearing transfer fee</Trans>
                </TableCell>
                <TableCell colSpan={2} className="flex items-center gap-2">
                  <Controller
                    name="transactionFeeAt"
                    defaultValue={setting?.transactionFeeAt ?? 10}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <Select
                        {...field}
                        fullWidth
                        error={invalid}
                      >
                        <MenuItem key={'10'} value={'10'}>
                          <Trans>Enterprise</Trans>
                        </MenuItem>
                        <MenuItem key={'20'} value={'20'}>
                          <Trans>Employee</Trans>
                        </MenuItem>
                      </Select>
                    )}
                  />
                  <Trans>Amount</Trans>
                  <Controller
                    name="transactionFeeAmount"
                    defaultValue={setting?.transactionFeeAmount ?? 0}
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={invalid}
                        InputProps={{
                          inputComponent: InputNumber as any,
                          inputProps: { thousandSeparator: '.', decimalSeparator: ',', allowNegative: true },
                        }}
                      />
                    )}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {isCaRole() && (
          <LoadingButton variant="contained" loading={isLoading} onClick={handleClickSubmit} className="w-32">
            <Trans>Apply</Trans>
          </LoadingButton>)}
      </div>
    </div>
  );
};

export default EnterpriseFullTimeAdvance;
