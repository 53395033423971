import { t, Trans } from '@lingui/macro';
import {
    Button,
    CardMedia,
    Container,
    Grid,
    LinearProgress,
    Paper,
    Rating,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
    onNext: (bankCode: string) => void;
};

const ContactSupportPopup = ({ onNext, onClose }: PopupProps) => {
    const [activeTab, setActiveTab] = useState('1');
    return (
        <div>
            <div
                id='register-div'
                // style={{ background: `url(${require('assets/epayz/form_image_1.png')}) no-repeat left bottom` }}
            >
                <Container className='flex justify-between gap-20' style={{height:'680px',paddingLeft:'0px'}}>
                    <div style={{width:'50%',background: `url(${require('assets/epayz/form_image_1.png')}) no-repeat right bottom`,backgroundSize:'cover'}}>
                        <div style={{ margin: '30px 0px', }}>
                            <img className='mx-[100px]' style={{ width: '150px' }} src={require('assets/epayz/bo nhan dien thuong hieu EPZ sua-12.png')} />
                        </div>
                        <Typography className='font-bold mx-[100px]' style={{fontSize:'40px'}}>
                            <Trans>Contact for advice, support</Trans>
                        </Typography>
                        <img src={require('assets/epayz/form_image_2.png')} className='mx-[100px]' />
                    </div>
                    <Paper className='flex-1 rounded-xl mt-20 mb-10 p-6 max-w-[480px]' style={{background:'#D0CECE'}}>
                        <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
                            <Tab label={t`Person`} value='1' />
                            <Tab label={t`Company`} value='2' />
                        </Tabs>
                        <div className='mt-4'>
                            <Typography style={{ fontSize: '14px' }}>
                                <Trans>Leave your information here, we will contact you as soon as possible</Trans>
                            </Typography>
                            <div className='flex flex-col gap-6 mt-6'>
                                {/* {activeTab === '2' && <TextField label={t`Company`} />} */}
                                {/* <TextField label={t`Full name`} />
                                <TextField label={t`Email`} />
                                <TextField label={t`Phone`} /> */}
                                {activeTab === '2' &&
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ width: '20%', fontSize: 14 }}><Trans>Company</Trans></div>
                                        <div style={{ width: '80%' }}><input type={"text"} style={{ width: '100%', height: "30px", borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", borderRadius: 5 }} /></div>
                                    </div>
                                }
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20%', fontSize: 14 }}><Trans>Your name</Trans></div>
                                    <div style={{ width: '80%' }}><input type={"text"} style={{ width: '100%', height: "30px", borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", borderRadius: 5 }} /></div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20%', fontSize: 14 }}><Trans>Your Email</Trans></div>
                                    <div style={{ width: '80%' }}><input type={"text"} style={{ width: '100%', height: "30px", borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", borderRadius: 5 }} /></div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20%', fontSize: 14 }}><Trans>Your phone</Trans></div>
                                    <div style={{ width: '80%' }}><input type={"text"} style={{ width: '100%', height: "30px", borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", borderRadius: 5 }} /></div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '20%', fontSize: 14 }}><Trans>Note</Trans></div>
                                    <div style={{ width: '80%' }}><textarea placeholder={t`Enter your requirements and areas of interest so that we can advise you more clearly`} style={{ width: '100%', borderWidth: 1, borderColor: "rgb(196 196 196 / 1)", padding: "10px", borderRadius: 5 }} rows={4}></textarea></div>
                                </div>
                                <div className='flex justify-center'>
                                    <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90'>
                                        <Trans>Send</Trans>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Container>
            </div>
        </div>
    );
}

export default ContactSupportPopup;