import { Trans, t } from '@lingui/macro';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody, Table, TableCell, TableContainer, TableRow, Dialog, Paper } from '@mui/material';
import { Typography, Button } from '@mui/material';
import { TransType } from 'types/Transaction';
import { getTransactionStatus, RejectedStatus } from 'constants/transaction';
import { getEmployeeType } from 'constants/employee';
import { formatNumber } from 'utils/common';
import { useState } from 'react';
import { TransIncomeNoticesPopup } from 'views/Transaction/TransView/popups';
import { ArrowRight } from '@mui/icons-material';
import { usePermission } from 'hooks';
export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '20%',
  },
  cellTitleBold: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '20%',
    fontWeight: 600,
  },
  cellTitleOne: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '40%',
  },
  cellValue: {
    width: '13%',
  },
  cellTitle4: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '30%',
  },
  cellValue4: {
    width: '20%',
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const TransInfo = ({ transaction }: { transaction?: TransType }) => {
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const { requreUserPermission, TRANSACTION_THONGBAOGIAODICH } = usePermission();
  return (
    <div className='mt-6'>
      <div className='flex items-center space-x-3'>
        <Typography variant='h4'>
          <Trans>Transaction Information</Trans>
        </Typography>
        <ArrowRight />
        <Typography variant='h5'>{getTransactionStatus(transaction?.status)}</Typography>
        {transaction?.status !== RejectedStatus && requreUserPermission([TRANSACTION_THONGBAOGIAODICH]) && (
          <Button variant='contained' onClick={() => setOpen(true)}>
            <Trans>Transaction Notice</Trans>
          </Button>
        )}
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{transaction?.employee?.name}</TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>ID Card No.</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{transaction?.employee?.ercNumber}</TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{getEmployeeType(transaction?.employeeType)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{transaction?.enterprise?.name}</TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Tax Code</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{transaction?.enterprise?.taxCode}</TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Employee Support Program</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {transaction?.isKey === 1 ? t`Key Employee` : t`Regular Employee`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Transaction Date</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {transaction?.transactionDate
                  ? DateTime.fromISO(transaction?.transactionDate).toFormat('dd/MM/yyyy')
                  : ''}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Salary Payment Date</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {transaction?.salaryPaymentDate
                  ? DateTime.fromISO(transaction?.salaryPaymentDate).toFormat('dd/MM/yyyy')
                  : ''}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Total Earned Labours</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{transaction?.totalLabor}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Attendance Starting Date</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {transaction?.attendanceStartingDate
                  ? DateTime.fromISO(transaction?.attendanceStartingDate).toFormat('dd/MM/yyyy')
                  : ''}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Attendance Closing Date</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>
                {transaction?.attendanceClosingDate
                  ? DateTime.fromISO(transaction?.attendanceClosingDate).toFormat('dd/MM/yyyy')
                  : ''}
              </TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Expected Labour in month</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{transaction?.expectedLaborMonth}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellTitle}>
                <Trans>Monthly Average Salary</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(transaction?.monthlyAverageSalary)}</TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Advance Limit</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{formatNumber(transaction?.advanceLimit)}</TableCell>
              <TableCell className={classes.cellTitle}>
                <Trans>Advanced Labours</Trans>
              </TableCell>
              <TableCell className={classes.cellValue}>{transaction?.advancedLabor}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={isOpen}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.dialogPaper }}
      >
        <TransIncomeNoticesPopup transaction={transaction!} onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default TransInfo;
