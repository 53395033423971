import { Trans, t } from '@lingui/macro';
import {
  Grid,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Select,
  MenuItem,
  Paper,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Pagination,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import { DateTime } from 'luxon';
import { TableEmpty } from 'components';
import { useSearch } from 'hooks';
import { formatNumber } from 'utils/common';
import { EnterpriseServiceFeeNoticeCreateOrGenerateParamsType } from 'types/EnterpriseSetup';
import { usePermission } from 'hooks';
import SelectPageSize from 'components/SelectPageSize';
type PopupProps = {
  enterpriseId: string;
  onClose: () => void;
  onNext: () => void;
  setNoticeCreate: (args: any) => void;
  setData: (args: any) => void;
};

const NoticeCreatePopupStep1 = ({ enterpriseId, onClose, onNext, setNoticeCreate, setData }: PopupProps) => {
  const { requreUserPermission, NOTICE_CREATE_FEE_PAYMENT } = usePermission();
  const [dataSearch, onSearchChange] = useSearch();
  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });

  const { pcBankId } = watch();

  const { data: { banks } = {} } = useQuery(
    ['enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo', { id: enterpriseId }],
    () =>
      enterpriseSetupService.getEnterpriseServiceFeeCategoryInfo({
        id: enterpriseId,
        appliedFee: 'SINGLE_SERVICE_FEE',
      }),
  );

  const { data } = useQuery(
    ['enterpriseSetupService.fetchEnterpriseFeeNoticeTrans', { enterpriseId, ...dataSearch }],
    () => enterpriseSetupService.fetchEnterpriseFeeNoticeTrans({ enterpriseId, ...dataSearch }),
    { keepPreviousData: true },
  );

  const { data: items = [], paginate } = data ?? {};

  const { mutate: generateEnterpriseFeeNotice, isLoading: isLoadingGenerate } = useMutation(
    enterpriseSetupService.generateEnterpriseFeeNotice,
    {
      onSuccess: (data) => {
        setData(data.data);
        onNext();
      },
      onError: () => {
        onNext();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      const { month } = values;
      const payload = {
        ...values,
        enterpriseId,
        month: month.month,
        year: month.year,
      } as EnterpriseServiceFeeNoticeCreateOrGenerateParamsType;
      setNoticeCreate(payload);
      generateEnterpriseFeeNotice(payload);
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>NOTICE OF SINGLE FEE PAYMENT</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>PC's Bank Account</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='pcBankId'
                        defaultValue=''
                        control={control}
                        rules={{ required: t`PC's Bank Account is required` }}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <Select {...field} fullWidth size='small' error={invalid}>
                            {banks?.map((bank) => (
                              <MenuItem key={bank.id} value={bank.id}>
                                {`${bank.accountNumber} - ${bank.name} - ${bank.branch}`}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Month</Trans>
                    </TableCell>
                    <TableCell>
                      <Controller
                        name='month'
                        defaultValue={DateTime.now()}
                        control={control}
                        rules={{ required: t`Month is required` }}
                        render={({ field: { value, onChange: setValue }, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            views={['month', 'year']}
                            value={value}
                            onChange={(value) => {
                              setValue(value);
                              onSearchChange({
                                enterpriseId,
                                month: value.month,
                                year: value.year,
                              });
                            }}
                            renderInput={(props) => <TextField {...props} />}
                            inputFormat='MM/yyyy'
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Typography variant='h5'>
          <Trans>Transaction List</Trans>
        </Typography>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Employee Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Staff ID</Trans>
                </TableCell>
                <TableCell>
                  <Trans>ID Card No.</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Single Service Fee before tax (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Single Service Fee after tax (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Related transactions</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, idx) => (
                <TableRow key={item.employeeId}>
                  <TableCell>{item.employeeName}</TableCell>
                  <TableCell>{item.employeeCode}</TableCell>
                  <TableCell>{item.employeeCardId}</TableCell>
                  <TableCell align='right'>{formatNumber(item.amountBeforeTax)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.amountAfterTax)}</TableCell>
                  <TableCell>{item.transCodes}</TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          {requreUserPermission([NOTICE_CREATE_FEE_PAYMENT]) && <LoadingButton
            variant='contained'
            onClick={handleClickSubmit}
            loading={isLoadingGenerate}
            disabled={!pcBankId}
          >
            <Trans>Create</Trans>
          </LoadingButton>}
        </div>
      </DialogActions>
    </>
  );
};

export default NoticeCreatePopupStep1;
