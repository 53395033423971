import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Chip, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DocTypePaymentSlip, PayingStatus } from 'constants/transaction';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { fileService, queryClient, transactionService } from 'services';
import { PopupController } from 'types/Common';
import { ObjectType } from 'types/Object';
import { TransType } from 'types/Transaction';
import TransDoc from 'views/Transaction/TransView/components/TransDoc';
import { usePermission } from 'hooks';

type PopupProps = PopupController & {
  transaction: TransType;
  docType: string;
};

const TransSlipPopup = ({ transaction, docType, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [objects, setObjects] = useState<ObjectType[]>([]);
  const [objectIds, setObjectIds] = useState<number[]>([]);
  const { requireRole } = usePermission();
  const inputRef = useRef<HTMLInputElement>(null);

  const { mutate: uploadFiles, isLoading: isLoadingUpload } = useMutation(fileService.uploadFiles, {
    onSuccess: (data) => {
      setObjects(data.objects);
      let _objectIds: number[] = [];
      Array.from(data.objects).forEach((o) => {
        _objectIds.push(o.id);
      });
      setObjectIds(_objectIds);
    },
  });

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(event.target.files!).forEach((file) => {
      formData.append('files', file);
    });
    uploadFiles(formData);
  };

  const { mutate: addDocs, isLoading: isLoadingAddDoc } = useMutation(transactionService.addTransactionDocs, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.fetchTransactionDocs');
    },
  });

  const handleSave = () => {
    addDocs({
      id: transaction.id,
      docType: docType,
      objectIds: objectIds,
    });
  };

  function renderUpload() {
    if (transaction.status === PayingStatus) {
      return (
        <>
          <Grid item sm={10}>
            {(requireRole('MASTER') || requireRole('SUB_MASTER') || requireRole('ACCOUNTANT') || requireRole('ACC_CONTROLLER')) && (
              <div className='flex items-end space-x-3 h-10'>
                <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} />
                <LoadingButton
                  variant='outlined'
                  size='small'
                  loading={isLoadingUpload}
                  onClick={() => inputRef.current?.click()}
                >
                  <Trans>Choose File</Trans>
                </LoadingButton>

                {objects.map((file) => (
                  <Chip
                    key={file.id}
                    variant='outlined'
                    label={file.originalName}
                    onDelete={() => setObjects((items) => items.filter((item) => item.id !== file.id))}
                  />
                ))}
              </div>
            )}
          </Grid>
          <Grid item sm={2}>
            {(requireRole('MASTER') || requireRole('SUB_MASTER') || requireRole('ACCOUNTANT') || requireRole('ACC_CONTROLLER')) && (
              <div className='flex items-end justify-end space-x-3 h-10'>
                <LoadingButton variant='contained' loading={isLoadingAddDoc} onClick={handleSave}>
                  <Trans>Upload</Trans>
                </LoadingButton>
              </div>
            )}
          </Grid>
        </>
      );
    }
    return null;
  }

  return (
    <>
      <DialogTitle>
        <Trans>Payment Slip</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          {renderUpload()}
          <Grid item sm={12}>
            <TransDoc
              docType={DocTypePaymentSlip}
              transactionId={transaction.id}
              removable={transaction.status === PayingStatus}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransSlipPopup;
