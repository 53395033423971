import { DashboardMain } from 'views/Dashboard/Main';
import { DashboardEnterprise } from 'views/Dashboard/Enterprise';
import { DashboardEmployee } from 'views/Dashboard/Employee';
import { EpayzDashboardMain } from 'views/Epayz/Dashboard/Main';
import { EpayzDashboardEnterprise } from 'views/Epayz/Dashboard/Enterprise';
import { EpayzDashboardEmployee } from 'views/Epayz/Dashboard/Employee';
import { usePermission } from 'hooks';
import { IS_EPAYZ } from 'env';

const Home = () => {
  const { requireUserPC, requireRole } = usePermission();
  return (
    <div>
      {IS_EPAYZ ? (
        <>
          {requireUserPC() && <EpayzDashboardMain />}
          {requireRole('ENTERPRISE', false) && <EpayzDashboardEnterprise />}
          {requireRole('EMPLOYEE', false) && <EpayzDashboardEmployee />}
        </>
      ) : (
        <>
          {requireUserPC() && <DashboardMain />}
          {requireRole('ENTERPRISE', false) && <DashboardEnterprise />}
          {requireRole('EMPLOYEE', false) && <DashboardEmployee />}
        </>
      )}
    </div>
  );
};

export default Home;
