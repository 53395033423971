import { t } from '@lingui/macro';

export const getEnterpriseStatus = (status: string) => {
  const MAP_ENTERPRISE_STATUSES: { [char: string]: string } = {
    NEW: t`new`,
    AGREED_TO_MEET: t`Agreed to Meet`,
    AGREED_TO_JOIN: t`Agreed to Join`,
    PROCESSING: t`In Processing`,
    PERSUADING: t`Persuading`,
    CONSIDERING: t`Considering/No need`,
    REJECTED: t`Rejected`,
    REGISTERED: t`Registered`,
    DOCUMENT_REVIEWING: t`Document Reviewing`,
    ASSESSMENT: t`In Assessment`,
    ONBOARDED: t`Onboarded`,
    STOP_CORPORATION: t`Stop Corporation`,
    ACTIVATE: t`Activate`,
    BLACKLISTED: t`Blacklisted`,
    LOCKED: t`Locked`,
    STOP_WORKING: t`Stop Working`,
  };
  return MAP_ENTERPRISE_STATUSES[status];
};

export const getLegalStatus = (status: number) => {
  const CHECKLIST_STATUS_MAP: any = {
    1: t`Missing`,
    2: t`Complete`,
  };
  return CHECKLIST_STATUS_MAP[status];
};

export const getProfileType = (value: number | undefined) => {
  const PROFILE_TYPES: { [char: number]: string } = {
    1: t`Hard`,
    2: t`Soft`,
  };
  return value ? PROFILE_TYPES[value] : '';
};

export const getAppraisalType = (value: number | undefined) => {
  const APPRAISAL_TYPES: { [char: number]: string } = {
    1: t`One`,
    2: t`Two`,
    3: t`Three`,
  };
  return value ? APPRAISAL_TYPES[value] : '';
};

export const getPaymentMethodStatus = (status: string) => {
  const STATUS_MAP: any = {
    WAITING_FOR_CONFIRMATION: t`Waiting for confirmation`,
    COMPLETED: t`Completed`,
  };
  return STATUS_MAP[status];
};

export const getGuaranteeStatusType = (status: string | undefined) => {
  const MAP_STATUS_TYPE: { [char: string]: string } = {
    WAITING_FOR_CONFIRMATION: t`Waiting for confirmation`,
    WAITING_FOR_APPROVAL: t`Waiting for approval`,
    WAITING_FOR_SIGNING: t`Waiting for signing`,
    REJECTED: t`Rejected`,
    COMPLETED: t`Completed`,
  };
  return status ? MAP_STATUS_TYPE[status] : '';
};

export const getFeeNoticeStatusType = (status: string | undefined) => {
  const MAP_STATUS_TYPE: { [char: string]: string } = {
    WAITING_FOR_SIGNING: t`Waiting for signing`,
    REJECTED: t`Rejected`,
    COMPLETED: t`Completed`,
  };
  return status ? MAP_STATUS_TYPE[status] : '';
};

export const getGuaranteeStatusName = (status: string | undefined) => {
  const MAP_DATA: { [char: string]: string } = {
    guarantee: t`Guarantee`,
    non_guarantee: t`Non-Guarantee`,
  };
  return status ? MAP_DATA[status.toLowerCase()] : t`Non-Guarantee`;
};

export const getEnterpriseApplyFeeType = (feeType: string | undefined) => {
  const MAP_DATA: { [char: string]: string } = {
    FIXED_SERVICE_FEE: t`FIXED SERVICE FEE`,
    SINGLE_SERVICE_FEE: t`SINGLE SERVICE FEE`,
  };
  return feeType ? MAP_DATA[feeType] : '';
};

export const getEnterpriseServiceFeeHistoryStatusType = (status: string | undefined) => {
  const MAP_STATUS_TYPE: { [char: string]: string } = {
    WAITING_FOR_APPROVE: t`Waiting for approve`,
    APPROVED: t`Approved`,
    WAITING_FOR_ENT_CONFIRMATION: t`Waiting for Enterprise confirmation`,
    COMPLETED: t`Completed`,
    REJECTED: t`Rejected`,
    WAITING_FOR_UPLOAD: t`Waiting for upload`,
    WAITING_FOR_CONFIRMATION: t`Waiting for confirmation`,
    CANCELED: t`Canceled`,
    WAITING_FOR_SIGNING: t`Waiting for signing`,
    WAITING_FOR_PC_CONFIRMATION: t`Waiting for PC confirmation`,
  };
  return status ? MAP_STATUS_TYPE[status] : '';
};
