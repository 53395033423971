import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { transactionService, queryClient, userService } from 'services';
import { PopupController } from 'types/Common';
import { UserType } from 'types/User';
import { TableEmpty } from 'components';
import PdfViewer from 'components/PdfViewer';
type PopupProps = {
  keykhac: string;
  title: string;
  onClose: () => void;
};

const ViewFile = ({ keykhac, title , onClose }: PopupProps) => {
    console.log(keykhac);
//   useEffect(() => {
//     const { data } = useQuery(
//         ['transactionService.viewFileNotice', id],
//         () => transactionService.viewFileNotice({id : id}),
//         { keepPreviousData: true },
//       );
//       changeBase64(data ? data : '');
//   }, [id]);
//     debugger;
//   const { data } = useQuery(
//     ['transactionService.viewFileNotice', id],
//     () => transactionService.viewFileNotice({id : id}),
//     { keepPreviousData: true },
//   );
//   changeBase64(data ? data : '');

  return (
    <>
      <DialogTitle>
        { title == 'TRANSACTION NOTICE' &&
        <Trans>TRANSACTION NOTICE</Trans>
        }
        { title == 'GUARANTEE NOTICE' &&
        <Trans>GUARANTEE NOTICE</Trans>
        }
         { title == 'FEE PAYMENT NOTICE' &&
        <Trans>FEE PAYMENT NOTICE</Trans>
        }
      </DialogTitle>

      <DialogContent>
        <PdfViewer objectKey={keykhac} />
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ViewFile;
