import { Trans } from '@lingui/macro';
import { DialogTitle } from '@mui/material';
import { useState } from 'react';
import { PopupController } from 'types/Common';
import { CreditScoreType } from 'types/CreditScore';
import { ProposalType } from 'types/EnterpriseProposal';
import { CreditScoreApproveType, CreditScoreCreateType } from 'types/EnterpriseSetup';
import BusinessCreditScoreStep1 from './BusinessCreditScoreStep1';
import BusinessCreditScoreStep2 from './BusinessCreditScoreStep2';
import BusinessCreditScoreStep3 from './BusinessCreditScoreStep3';
import BusinessConditionReview from './BusinessConditionReview';

type PopupProps = PopupController & {
  id: number;
};

const BusinessCreditScorePopup = ({ id, onClose }: PopupProps) => {
  const [step, setStep] = useState(1);
  const [creditScore, setCreditScore] = useState<CreditScoreType>();
  const [proposalRequest, setProposalRequest] = useState<ProposalType>();
  const [creditScoreCreate, setCreditScoreCreate] = useState<CreditScoreCreateType>();
  const [creditApproveCreate, setCreditApproveCreate] = useState<CreditScoreApproveType>();
  const [creditScoreLimit, setCreditScoreLimit] = useState();

  return (
    <>
      <DialogTitle>
        <Trans>Business Credit Scoring</Trans>
      </DialogTitle>

      <div className={step === 1 ? '' : 'hidden'}>
        <BusinessCreditScoreStep1
          id={id}
          onSelect={setCreditScore}
          onCreate={setCreditScoreCreate}
          onBack={() => onClose()}
          onNext={() => setStep(2)}
        />
      </div>
      {step === 2 && (
        <BusinessCreditScoreStep2
          enterpriseId={id}
          creditScore={creditScore!}
          creditScoreCreate={creditScoreCreate!}
          onRequest={setProposalRequest}
          onBack={() => setStep(1)}
          onNext={() => setStep(3)}
          setCreditScoreLimit={setCreditScoreLimit}
          creditScoreLimit={creditScoreLimit}
        />
      )}
      {step === 3 && (
        <BusinessCreditScoreStep3
          enterpriseId={id}
          creditScore={creditScore!}
          creditScoreCreate={creditScoreCreate!}
          proposalRequest={proposalRequest}
          conditionId={creditApproveCreate?.id!}
          onCreate={setCreditApproveCreate}
          onBack={() => setStep(2)}
          onNext={() => setStep(4)}
        />
      )}
      {step === 4 && (
        <BusinessConditionReview
          isStep={true}
          enterpriseId={id}
          conditionId={creditApproveCreate?.id!}
          onClose={() => onClose()}
          onBack={() => setStep(3)}
        />
      )}
    </>
  );
};

export default BusinessCreditScorePopup;
