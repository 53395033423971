import { Trans } from '@lingui/macro';
import { DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { AppendixObjectDataType } from 'types/EnterpriseSetup';

type PopupProps = {
  onBack: () => void;
  objectData: AppendixObjectDataType;
};

const FixedServicePopupStep7 = ({ objectData, onBack }: PopupProps) => {
  return (
    <>
      <DialogTitle>
        <Trans>APPENDIX OF FIXED SERVICE FEE</Trans>
      </DialogTitle>
      <DialogContent>
        <PdfViewer objectKey={objectData.objectKey} />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' color='info' onClick={onBack}>
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </>
  );
};

export default FixedServicePopupStep7;
