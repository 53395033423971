import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { TableEmpty } from 'components';
import { usePermission } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { employeeService } from 'services';
import { BankAccountType } from 'types/BankAccount';
import { EmployeeType } from 'types/Employee';
import { BankAccountCreatePopup } from 'views/Employee/EmployeeView/popups';

const EmployeeBankAccount = ({ employee }: { employee?: EmployeeType }) => {
  console.log(employee)
  const { requireUserPC, requreUserPermission, EMPLOYEE_UPDATE_PAYMENT_RECEIVE, EMPLOYEE_UPDATE_BANK_ACCOUNT_INFO } = usePermission();
  const { id: _id = 'me' } = useParams() as { id: string };
  const [enterpriseId, setEnterpriseId] = useState<number | string>('');

  const [isOpen, setOpen] = useState(false);
  const [chosenUpdate, setChosenUpdate] = useState<BankAccountType>();

  const { data } = useQuery(
    ['employeeService.fetchEmployeeWorkings', { _id }],
    () => employeeService.fetchEmployeeWorkings({ _id }),
    {
      onSuccess: ({ data: items }) => {
        if (items[0]) {
          setEnterpriseId(items[0].enterprise.id);
        }
      },
    },
  );
  const { data: enterprises = [] } = data ?? {};

  const { data: bank } = useQuery(
    ['employeeService.getEmployeeBankAccount', { _id }],
    () => employeeService.getEmployeeBankAccount({ _id }),
    {},
  );

  const { data: working } = useQuery(
    ['employeeService.getEmployeeBankAccountWorking', { _id, enterpriseId }],
    () => employeeService.getEmployeeBankAccountWorking({ _id, enterpriseId }),
    { enabled: !!enterpriseId },
  );

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Bank Account of Payment Receive</Trans>
          </Typography>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Bank</Trans>
              </TableCell>
              <TableCell>
                <Trans>Branch</Trans>
              </TableCell>
              <TableCell>
                <Trans>Account Number</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[bank].map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Trans>Payment Receive</Trans>
                </TableCell>
                <TableCell>{item?.bankName}</TableCell>
                <TableCell>{item?.branchName}</TableCell>
                <TableCell>{item?.accountNumber}</TableCell>
                <TableCell className='text-right'>
                  {requreUserPermission([EMPLOYEE_UPDATE_PAYMENT_RECEIVE]) && (
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpen(true);
                        setChosenUpdate(item);
                      }}
                    >
                      <Trans>Update</Trans>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={[bank]} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between mt-6'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Bank Account Information</Trans>
          </Typography>
          <TextField select value={enterpriseId} onChange={(event) => setEnterpriseId(event.target.value)}>
            {enterprises.map((item) => (
              <MenuItem key={item.id} value={item.enterprise.id}>
                {item.enterprise.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Bank</Trans>
              </TableCell>
              <TableCell>
                <Trans>Branch</Trans>
              </TableCell>
              <TableCell>
                <Trans>Account Holder</Trans>
              </TableCell>
              <TableCell>
                <Trans>Account Number</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Trans>Designated Account</Trans>
              </TableCell>
              <TableCell>{working?.designated.bankName}</TableCell>
              <TableCell>{working?.designated.branchName}</TableCell>
              <TableCell>{working?.designated.accountHolder}</TableCell>
              <TableCell>{working?.designated.accountNumber}</TableCell>
              <TableCell className='text-right'>
                {requreUserPermission([EMPLOYEE_UPDATE_BANK_ACCOUNT_INFO]) && working?.status == "LOCKED" && (
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setChosenUpdate({ ...working?.designated!, enterpriseId: enterpriseId as number });
                    }}
                  >
                    <Trans>Update</Trans>
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Trans>Salary Account</Trans>
              </TableCell>
              <TableCell>{working?.salary.bankName}</TableCell>
              <TableCell>{working?.salary.branchName}</TableCell>
              <TableCell>{working?.salary.accountHolder}</TableCell>
              <TableCell>{working?.salary.accountNumber}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {requireUserPC() && (
        <Dialog fullWidth maxWidth='xs' open={isOpen} onClose={() => setOpen(false)}>
          <BankAccountCreatePopup item={chosenUpdate!} employee={employee!} onClose={() => setOpen(false)} />
        </Dialog>
      )}
    </div>
  );
};

export default EmployeeBankAccount;
