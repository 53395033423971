import { t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { enterpriseSetupService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { usePermission } from 'hooks';

type PopupProps = PopupController & {
  enterpriseId: number;
  conditionId: number;
  isStep: boolean;
  onBack?: () => void;
};

const BusinessConditionReview = ({ isStep, enterpriseId, conditionId, onBack, onClose }: PopupProps) => {
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const { requireUserPCManager } = usePermission();

  const { data: condition } = useQuery(
    ['enterpriseSetupService.getConditionById', { enId: enterpriseId, id: conditionId }],
    () => enterpriseSetupService.getConditionById({ enId: enterpriseId, id: conditionId }),
    {
      onSuccess: (data) => {
        setValue('status', data.status);
        setValue('comment', data.comment);
      },
    },
  );

  const { mutate: updateCondition, isLoading: isLoadingApprove } = useMutation(
    condition?.type === 1 ? enterpriseSetupService.updateApprovalDecision : enterpriseSetupService.updateTrialCondition,
    {
      onSuccess: () => {
        onClose();
        queryClient.invalidateQueries('enterpriseSetupService.fetchConditionSetting');
        queryClient.invalidateQueries('enterpriseSetupService.getConditionLastest');
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateCondition({
        enId: enterpriseId,
        csId: condition?.enterpriseCreditScoringId,
        id: condition?.id!,
        ...values,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Approval Request By Investment Committee</Trans>
      </DialogTitle>

      <DialogContent>
        {condition?.type === 1 && (
          <Grid container className='border w-[720px] mb-4'>
            <Grid item md={4} className='font-bold px-3 py-1'>
              CREDIT SCORE
            </Grid>
            <Grid item md={2.5} className='font-bold text-center px-3 py-1 bg-gray-200'>
              {condition?.totalScore}
            </Grid>
            <Grid item md={3} className='font-bold px-3 py-1'>
              RATING
            </Grid>
            <Grid item md={2.5} className='font-bold text-center px-3 py-1 bg-gray-200 text-red-700'>
              {condition?.ratingType}
            </Grid>
          </Grid>
        )}

        <Typography variant='h4'>
          <Trans>Approval Request from CA Department</Trans>
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='w-10'>
                  <Trans>No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Content</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Approval Decision</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className='h-12'>
                <TableCell colSpan={3} className='font-bold'>
                  <Trans>Advance Conditions</Trans>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>
                  <Trans>Total Enterprise Limit (VND)</Trans>
                </TableCell>
                <TableCell className='font-bold'>{condition?.totalLimit.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>
                  <Trans>Expire Date</Trans>
                </TableCell>
                <TableCell>
                  {condition?.expireDate ? DateTime.fromISO(condition.expireDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3</TableCell>
                <TableCell>
                  <Trans>Advance Term (Day)</Trans>
                </TableCell>
                <TableCell>
                  {condition?.minAdvanceTerm} - {condition?.maxAdvanceTerm}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4</TableCell>
                <TableCell>
                  <Trans>Advance Amount (VND)</Trans>
                </TableCell>
                <TableCell>
                  {condition?.minAdvanceAmount} - {condition?.maxAdvanceAmount}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5</TableCell>
                <TableCell>
                  <Trans>Earned Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell className='font-bold'>{condition?.earnedDiscountRate}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>6</TableCell>
                <TableCell>
                  <Trans>Future Discount Rate (APR)</Trans>
                </TableCell>
                <TableCell className='font-bold'>{condition?.futureDiscountRate}%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>7</TableCell>
                <TableCell>
                  <Trans>Promotion</Trans>
                </TableCell>
                <TableCell>{condition?.promotion}</TableCell>
              </TableRow>

              <TableRow className='h-12'>
                <TableCell colSpan={3} className='font-bold'>
                  <Trans>Employee Conditions</Trans>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>
                  <Trans>Labor Contract eligible for future advance</Trans>
                </TableCell>
                <TableCell>{condition?.laborContract}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2</TableCell>
                <TableCell>
                  <Trans>Other Proposal</Trans>
                </TableCell>
                <TableCell>{condition?.otherProposal}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant='h4' className='mb-4'>
          <Trans>Final Decision of Investment Committee</Trans>
        </Typography>
        <div className='flex gap-6'>
          <Controller
            name='status'
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                select
                label={t`Decision`}
                className='w-[240px]'
                error={invalid}
                disabled={condition?.status !== 'pending' ? true : false}
              >
                <MenuItem value='approved'>{t`Approve`}</MenuItem>
                <MenuItem value='reject'>{t`Reject`}</MenuItem>
              </TextField>
            )}
          />
          <Controller
            name='comment'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                label={t`Comment`}
                disabled={condition?.status !== 'pending' ? true : false}
              />
            )}
          />
        </div>
      </DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={isStep ? onBack : onClose} className='w-28'>
          {requireUserPCManager() && isStep ? <Trans>Back</Trans> : <Trans>Close</Trans>}
        </LoadingButton>
        {condition?.status === 'pending' && requireUserPCManager() && (
          <LoadingButton variant='contained' className='w-28' loading={isLoadingApprove} onClick={handleClickSubmit}>
            <Trans>Confirm</Trans>
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default BusinessConditionReview;
