import { t, Trans } from '@lingui/macro';
import { useActiveLocale } from 'hooks/useActiveLocale';
import {
  Button,
  Checkbox,
  Dialog,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import { getEmployeeType } from 'constants/employee';
import { usePermission, useSearch } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseSetupService } from 'services';
import { EmployeeType } from 'types/EnterpriseSetup';
import { formatNumber } from 'utils/common';
import { EmployeeChangeDivisionPopup } from '../popups';
import { CheckCircle } from '@mui/icons-material';
import SelectPageSize from 'components/SelectPageSize';

const EmployeeAll = () => {
  const userLocale = useActiveLocale();
  const { isCaRole } = usePermission();
  const { id: _id } = useParams() as { id: string };

  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');

  const [dataSearch, onSearchChange] = useSearch({ id: _id });
  const [dataSelect, setDataSelect] = useState<EmployeeType[]>([]);

  const [isOpenAssign, setOpenAssign] = useState(false);

  const handleSearchType = (event: any) => {
    var type = event.target.value;
    onSearchChange({ ['name']: '', 'type': '', 'employee_level': '', 'working_type': '' })
  }
  const handleSearchText = (event: any) => {
    var type = searchType;
    //onSearchChange({ [searchType]: event.target.value })
    if (type === 'name')
      onSearchChange({ ['name']: event.target.value, 'type': '', 'employee_level': '', 'working_block': '', 'language_vi': userLocale == 'vi-VN' ? 'true' : 'false' })
    else if (type === 'type')
      onSearchChange({ ['name']: '', 'type': event.target.value, 'employee_level': '', 'working_block': '', 'language_vi': userLocale == 'vi-VN' ? 'true' : 'false' })
    else if (type === 'employee_level')
      onSearchChange({ ['name']: '', 'type': '', 'employee_level': event.target.value, 'working_block': '', 'language_vi': userLocale == 'vi-VN' ? 'true' : 'false' })
    else
      onSearchChange({ ['name']: '', 'type': '', 'employee_level': '', 'working_block': event.target.value, 'language_vi': userLocale == 'vi-VN' ? 'true' : 'false' })
  }

  const { data } = useQuery(
    ['enterpriseSetupService.fetchEmployees', dataSearch],
    () => enterpriseSetupService.fetchEmployees(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};
  return (
    <div>
      <Typography variant='h4' className='mb-4'>
        <Trans>List of Enterprise's Employee</Trans>
      </Typography>

      <div className='flex justify-between'>
        <div className='space-x-3'>
          {isCaRole() && (<Button variant='contained' disabled={dataSelect.length === 0} onClick={() => setOpenAssign(true)}>
            <Trans>Change Division</Trans>
          </Button>)}
        </div>
        <div className='space-x-3'>
          <TextField value={searchType} select onChange={(event) => {
            setSearchText('');
            setSearchType(event.target.value);
            handleSearchType(event);
          }}>
            <MenuItem value={'name'}>
              <Trans>Name</Trans>
            </MenuItem>
            <MenuItem value={'type'}>
              <Trans>Type</Trans>
            </MenuItem>
            <MenuItem value={'employee_level'}>
              <Trans>Level</Trans>
            </MenuItem>
            <MenuItem value={'working_block'}>
              <Trans>Division</Trans>
            </MenuItem>
          </TextField>
          {searchType != 'type' &&
            <TextField value={searchText} placeholder={t`Search...`} onChange={(event) => {
              setSearchText(event.target.value);
            }} onBlur={(event) => {
              handleSearchText(event);
            }} />
          }
          {searchType == 'type' &&
            <TextField value={searchText} select onChange={(event) => {
              setSearchText(event.target.value);
              handleSearchText(event);
            }}>
              <MenuItem value={'FULL'}>
                <Trans>{t`Full-time`}</Trans>
              </MenuItem>
              <MenuItem value={'SEASON'}>
                <Trans>{t`Part-time`}</Trans>
              </MenuItem>
            </TextField>
          }
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Automatic Transaction</Trans>
              </TableCell>
              <TableCell>
                <Trans>Future Advance</Trans>
              </TableCell>
              <TableCell>
                <Trans>Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Level</Trans>
              </TableCell>
              <TableCell>
                <Trans>Division</Trans>
              </TableCell>
              <TableCell>
                <Trans>Average Salary Amount (VND)</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.employeeId}>
                <TableCell className='p-1'>
                  <Checkbox
                    checked={dataSelect.some((next) => next.employeeId === item.employeeId)}
                    onChange={(event, checked) => {
                      if (checked) setDataSelect((nexts) => nexts.concat(item));
                      else setDataSelect((nexts) => nexts.filter((next) => next.employeeId !== item.employeeId));
                    }}
                  />
                </TableCell>
                <TableCell>{item.employee.name}</TableCell>
                <TableCell className='text-center'>{item.autoTransaction ? <CheckCircle color='primary' /> : ""}</TableCell>
                <TableCell className='text-center'>{item.canAdvance ? t`YES` : t`NO`}</TableCell>
                <TableCell>{getEmployeeType(item.employeeType)}</TableCell>
                <TableCell>
                  {userLocale === 'vi-VN' ? item.employeeLevel?.viContent : item.employeeLevel?.enContent}
                </TableCell>
                <TableCell>
                  {userLocale === 'vi-VN' ? item.workingBlock?.viContent : item.workingBlock?.enContent}
                </TableCell>
                <TableCell align='right'>{formatNumber(item.salaryAverage)}</TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      <Dialog fullWidth maxWidth='xs' open={isOpenAssign} onClose={() => setOpenAssign(false)}>
        <EmployeeChangeDivisionPopup
          enterpriseId={_id}
          employeeIds={dataSelect.map((item) => item.employeeId)}
          onSuccess={() => setDataSelect([])}
          onClose={() => setOpenAssign(false)}
        />
      </Dialog>
    </div>
  );
};

export default EmployeeAll;
