import { t } from '@lingui/macro';

export const MissingState = 'MISSING';
export const WaitingDocState = 'WAITING_DOC';
export const ReviewingState = 'REVIEWING';
export const WaitingSignStatus = 'WAITING_SIGN';
export const WaitingPartnerState = 'WAITING_PARTNER';
export const CompletedState = 'COMPLETED';
export const OutdatedState = 'OUTDATED';

export const getContractState = (state: string | undefined, isEnterprise = true) => {
  if (state) {
    const MAP_CONTRACT_STATES: { [char: string]: string } = {
      MISSING: t`To be created`,
      WAITING_DOC: t`Waiting for Upload`,
      REVIEWING: t`In Assessment`,
      WAITING_SIGN: t`Waiting for Signing`,
      WAITING_PARTNER: isEnterprise ? t`Waiting for Enterprise signing` : t`Waiting for Employee signing`,
      COMPLETED: t`Completed`,
      OUTDATED: t`Expired`
    };
    return MAP_CONTRACT_STATES[state];
  }
  return '';
};

export const getContractStateForEnterprise = (state: string | undefined) => {
  if (state) {
    const MAP_CONTRACT_STATES: { [char: string]: string } = {
      WAITING_PARTNER: t`Waiting for Signing`,
      COMPLETED: t`Appyling`,
      OUTDATED: t`Expired`
    };
    return MAP_CONTRACT_STATES[state];
  }
  return '';
};

export const getContractName = (code: string | undefined) => {
  if (code) {
    const MAP_CONTRACT_NAMES: { [char: string]: string } = {
      SERVICE_SUPPLY: t`Service Supply Contract`,
      COORDINATION_AGREEMENT: t`Coordination Agreement`,
      MASTER_CONTRACT: t`Master Contract`,
      COMMITMENT_AGREEMENT: t`Commitment Agreement`,
      AGREEMENT_AUTOMATIC_TRANSACTION:"Thỏa thuận giao dịch tự động"
    };
    return MAP_CONTRACT_NAMES[code];
  }
  return '';
};

export const getContractMethod = (digital: boolean | undefined) => {
  if (digital) {
    return t`Digital Contract`
  }
  return t`Manual Contract`
};
