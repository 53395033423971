import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { UserInformation, UserPassword } from './components';

const AccountInformation = () => {
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Account Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <UserInformation />
        <UserPassword />
      </div>
    </Paper>
  );
};

export default AccountInformation;
