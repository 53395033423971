import { Trans } from '@lingui/macro';
import { LoginPage } from 'views/Login';
import SigninCallback from '../SigninCallback';
import { ForgotPasswordPage } from 'views/ForgotPassword';
import { AboutUsStatic, EmployeeStatic, EnterpriseStatic, InvestmentStatic, FAQS } from 'views/Static';
// import { AboutUsStatic, EmployeeStatic, EnterpriseStatic, InvestmentStatic, SalaryAdvanceStatic } from 'views/Static';

const staticRoute = {
  signin_callback: {
    path: '/signin-callback',
    name: <Trans>Signin-Callback</Trans>,
    element: <SigninCallback />,
  },
  login: {
    path: '/login',
    name: <Trans>Login</Trans>,
    element: <LoginPage />,
  },
  forgotPassword: {
    path: '/forgot-password',
    name: <Trans>Forgot Password</Trans>,
    element: <ForgotPasswordPage />,
  },
  aboutUs: {
    path: '/about-us',
    name: <Trans>About Us</Trans>,
    element: <AboutUsStatic />,
  },
  // salaryAdvance: {
  //   path: '/salary-advance',
  //   name: <Trans>Salary Advance</Trans>,
  //   element: <SalaryAdvanceStatic />,
  // },
  enterprise: {
    path: '/enterprise',
    name: <Trans>Enterprise</Trans>,
    element: <EnterpriseStatic />,
  },
  employee: {
    path: '/employee',
    name: <Trans>Employee</Trans>,
    element: <EmployeeStatic />,
  },
  investment: {
    path: '/investment',
    name: <Trans>Investment</Trans>,
    element: <InvestmentStatic />,
  },
  faqs:{
    path: '/faqs',
    name: <Trans>FAQS</Trans>,
    element: <FAQS />,
  }
};

export default staticRoute;
