import { configureStore } from '@reduxjs/toolkit';
import profile from './profile';
import notification from './notification';
import userSettings from './user-settings';

export const store = configureStore({
  reducer: {
    profile,
    notification,
    userSettings,
  },
});

export type RootState = ReturnType<typeof store.getState>;
