import { Trans } from '@lingui/macro';
import { usePermission } from 'hooks';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { enterpriseService } from 'services';
import { BankAccountType } from 'types/BankAccount';
import { EnterpriseType } from 'types/Enterprise';
import { BankAccountCreatePopup } from 'views/Enterprise/EnterpriseView/popups';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';

const EnterpriseBankAccount = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const { requreUserPermission, ACCOUNT_BANK_CREATEORUPDATE, ACCOUNT_BANK_VIEW, requireUserPCManagerOrCRMLeader } = usePermission();
  const { id: _id = 'me' } = useParams() as { id: string };

  const [dataSearch, onSearchChange] = useSearch({ _id });
  const [isOpen, setOpen] = useState(false);
  const [chosenUpdate, setChosenUpdate] = useState<BankAccountType>();

  const { data } = useQuery(
    ['enterpriseService.fetchEnterpriseBanks', dataSearch],
    () => enterpriseService.fetchEnterpriseBankAccounts(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Bank Account Information</Trans>
          </Typography>
          {requireUserPCManagerOrCRMLeader() && requreUserPermission([ACCOUNT_BANK_CREATEORUPDATE]) && (<Button
            variant='outlined'
            size='small'
            onClick={() => {
              setOpen(true);
              setChosenUpdate(undefined);
            }}
          >
            <Trans>Add Account</Trans>
          </Button>)}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Bank</Trans>
              </TableCell>
              <TableCell>
                <Trans>Branch</Trans>
              </TableCell>
              <TableCell>
                <Trans>Account Number</Trans>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {requreUserPermission([ACCOUNT_BANK_VIEW]) && (<TableBody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{item.bankName}</TableCell>
                <TableCell>{item.branchName}</TableCell>
                <TableCell>{item.accountNumber}</TableCell>
                <TableCell className='text-right'>
                  {requireUserPCManagerOrCRMLeader() && requreUserPermission([ACCOUNT_BANK_CREATEORUPDATE]) && (<Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setChosenUpdate(item);
                    }}
                  >
                    <Trans>Update</Trans>
                  </Button>)}
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>)}
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ ...dataSearch, size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
        />
      </div>

      {requireUserPCManagerOrCRMLeader() && (<Dialog fullWidth maxWidth='xs' open={isOpen} onClose={() => setOpen(false)}>
        <BankAccountCreatePopup item={chosenUpdate} enterprise={enterprise!} onClose={() => setOpen(false)} />
      </Dialog>)}
    </div>
  );
};

export default EnterpriseBankAccount;
