import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TableEmpty } from 'components';
import { TransRevisedStatusInit } from 'constants/transaction';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { TransReviseType, TransType } from 'types/Transaction';
import { formatNumber } from 'utils/common';
import {
  TransReviseNoticePopup, TransReviseViewPopup
} from 'views/Transaction/TransSettlement/TransSettlementView/popups';
import { usePermission } from 'hooks';
import SelectPageSize from 'components/SelectPageSize';
export const useStyles = makeStyles({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const TransReviseHis = ({ transaction }: { transaction?: TransType }) => {
  const [isOpenRevise, setOpenRevise] = useState(false);
  const [isOpenNotice, setOpenNotice] = useState(false);
  const [record, setRecord] = useState<TransReviseType>();
  const [dataSearch, onSearchChange] = useSearch({ transId: transaction?.id });
  const classes = useStyles();
  const { requreUserPermission, TRANSACTION_XEMTHONGBAODIEUCHINH, TRANSACTION_XEMLICHSUDIEUCHINH } = usePermission();
  const { data } = useQuery(
    ['transactionService.fetchTransactionRevises', dataSearch],
    () => transactionService.fetchTransactionRevises(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const renderSign = (item: TransReviseType) => {
    if (item.status === TransRevisedStatusInit) {
      return (
        <div className='flex items-center space-x-3'>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              setRecord(item);
              setOpenNotice(true);
            }}
          >
            <Trans>Sign</Trans>
          </Button>
        </div>
      );
    }
    return (
      <div className='flex items-center space-x-3'>
        <Button
          variant='contained'
          size='small'
          onClick={() => {
            setRecord(item);
            setOpenNotice(true);
          }}
        >
          <Trans>View</Trans>
        </Button>
      </div>
    );
  };

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Revision History</Trans>
          </Typography>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Revision Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Revision Time</Trans>
              </TableCell>
              <TableCell>
                <Trans>Salary Payment Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collection Amount from Employee (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Revision Notice</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, idx) => (
              <TableRow key={item.id}>
                <TableCell align='center'>
                  {item.updatedAt ? DateTime.fromISO(item.updatedAt).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell align='center'>{idx + 1}</TableCell>
                <TableCell align='center'>
                  {item.salaryPaymentDate ? DateTime.fromISO(item.salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectionAmount)}</TableCell>
                <TableCell>{requreUserPermission([TRANSACTION_XEMTHONGBAODIEUCHINH]) && renderSign(item)}</TableCell>
                <TableCell align='center'>
                  {requreUserPermission([TRANSACTION_XEMLICHSUDIEUCHINH]) &&
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setRecord(item);
                        setOpenRevise(true);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth='lg'
        classes={{ paper: classes.dialogPaper }}
        open={isOpenNotice}
        onClose={() => setOpenNotice(false)}
      >
        <TransReviseNoticePopup
          transactionId={transaction?.id!}
          transactionReviseId={record?.id!}
          onClose={() => setOpenNotice(false)}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpenRevise} onClose={() => setOpenRevise(false)}>
        <TransReviseViewPopup
          salaryPaymentDate={transaction?.salaryPaymentDate!}
          transactionRevise={record!}
          onClose={() => setOpenRevise(false)}
        />
      </Dialog>
    </div>
  );
};

export default TransReviseHis;
