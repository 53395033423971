import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Dialog,
} from '@mui/material';
import { PopupController } from 'types/Common';
import { Controller, useForm } from 'react-hook-form';
import { enterpriseSetupService, queryClient } from 'services';
import { useMutation } from 'react-query';
import { EnterpriseWaitingPaymentType, EnterpriseServiceFeePaymentMoveLateSaveType } from 'types/EnterpriseSetup';
import { DateTime } from 'luxon';
import { ActionConfirmPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { useState } from 'react';
import { InputNumber } from 'components';

type PopupProps = PopupController & {
  dataSelect: EnterpriseWaitingPaymentType[];
  enterpriseId: string;
  setDataSelect: (value: any) => void;
};

const MoveLatePaymentPopup = ({ enterpriseId, dataSelect, setDataSelect, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const [actionConfirmPopup, setActionConfirmPopup] = useState(false);

  const { mutate, isLoading } = useMutation(enterpriseSetupService.moveToLateFeePayment, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      setDataSelect([]);
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseWaitingPaymentData');
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseLatePaymentData');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      type ValuesType = EnterpriseServiceFeePaymentMoveLateSaveType & { latePaymentDate: DateTime };
      const { latePaymentDate, ...others } = values as ValuesType;

      mutate({
        ...others,
        feePaymentId: dataSelect[0].id,
        latePaymentDate: latePaymentDate.toISO(),
        enterpriseId,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>LATE PAYMENT</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TableContainer component={Paper} variant='outlined'>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Late Payment Fee (VND)</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <Controller
                        name='lateFee'
                        defaultValue={dataSelect[0] ? dataSelect[0].serviceFeeAfterTax : 0}
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => (
                          <TextField
                            fullWidth
                            {...field}
                            error={invalid}
                            helperText={error?.message}
                            InputProps={{
                              inputComponent: InputNumber as any,
                              inputProps: { thousandSeparator: '.', decimalSeparator: ',' },
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className='border-r'>
                      <Trans>Late Payment Date</Trans>
                    </TableCell>
                    <TableCell className='border-r'>
                      <Controller
                        name='latePaymentDate'
                        defaultValue={DateTime.now()}
                        control={control}
                        rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
                        render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                          <DesktopDatePicker
                            value={value}
                            onChange={onChange}
                            renderInput={(props) => <TextField {...props} fullWidth error={invalid} />}
                            inputFormat='dd/MM/yyyy'
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' onClick={onClose}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' disabled={isLoading} onClick={() => setActionConfirmPopup(true)}>
            <Trans>Confirm Late Payment</Trans>
          </LoadingButton>
        </div>
      </DialogActions>

      <Dialog fullWidth maxWidth='sm' open={actionConfirmPopup} onClose={() => setActionConfirmPopup(false)}>
        <ActionConfirmPopup
          isLoading={isLoading}
          handleAction={handleClickSubmit}
          onClose={() => setActionConfirmPopup(false)}
        />
      </Dialog>
    </>
  );
};

export default MoveLatePaymentPopup;
