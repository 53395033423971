import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  onNext: (bankCode: string) => void;
};

const TransBankPopup = ({ onNext, onClose }: PopupProps) => {
  const [bankCode, setBankCode] = useState('');
  const { data } = useQuery(['transactionService.fetchTransactions'], () => transactionService.listTransBanks(), {
    keepPreviousData: true,
  });
  const { list: banks = [] } = data ?? {};

  const handleNext = () => {
    if (bankCode) {
      onNext(bankCode);
    }
  };

  return (
    <>
      <DialogTitle>
        <Trans>SELECT BANK TO TRANSFER</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Trans>Please select bank to transfer payment</Trans>
          </Grid>
          <Grid item sm={3} className='mt-1'>
            <Trans>Bank</Trans>
          </Grid>
          <Grid item sm={9}>
            <TextField select fullWidth onChange={(event) => setBankCode(event.target.value)}>
              {banks.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>
        <LoadingButton disabled={bankCode === ''} variant='contained' onClick={handleNext}>
          <Trans>Next</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransBankPopup;
