import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
} from '@mui/material';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  typeFeeSetting: string;
  setTypeFeeSetting: (args: string) => void;
};

const EnterpriseSettingFeePopupStep1 = ({ typeFeeSetting, setTypeFeeSetting, onBack, onNext }: PopupProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeFeeSetting((event.target as HTMLInputElement).value);
  };
  return (
    <>
      <DialogTitle>
        <Trans>ENTERPRISE'S SERVICE FEE SETTING</Trans>
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'><Trans>Please select one of these service fees</Trans></Typography>
        <RadioGroup name='enterprise-service-fee-type' value={typeFeeSetting} onChange={handleChange}>
          <Grid container columnSpacing={2} className='my-6'>
            <Grid item sm={12} md={6}>
              <div className='border flex justify-center items-center py-4 rounded-lg'>
                <FormControlLabel value='FIXED_SERVICE_FEE' control={<Radio />} label={t`Fixed Service Fee`} />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div className='border flex justify-center items-center py-4 rounded-lg'>
                <FormControlLabel value='SINGLE_SERVICE_FEE' control={<Radio />} label={t`Single Service Fee`} />
              </div>
            </Grid>
          </Grid>
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <Button variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </Button>
          <LoadingButton variant='contained' onClick={onNext} disabled={!typeFeeSetting}>
            <Trans>Next</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default EnterpriseSettingFeePopupStep1;
