import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';

import { TextField, Grid, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { enterpriseService, queryClient } from 'services';
import { ContractRejectType, ContractType } from 'types/EnterpriseContract';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  contract: ContractType;
  enterpriseId: string;
};

const ContractRejectPopup = ({ contract, enterpriseId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const { mutate: rejectContract, isLoading } = useMutation(enterpriseService.rejectEnterpriseContract, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseService.getEnterpriseLatest');
      queryClient.invalidateQueries('enterpriseService.fetchEnterpriseContract');
      onClose();
    },
  });

  const handleClickReject = () => {
    handleSubmit((values: ContractRejectType) => {
      rejectContract({
        ...values,
        _id: enterpriseId,
        id: contract.id!,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Reject Contract</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='reason'
              defaultValue=''
              control={control}
              rules={{
                required: t`Field is required`,
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  multiline
                  minRows={2}
                  label={t`Reject Reason`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' onClick={handleClickReject} loading={isLoading}>
          <Trans>Reject</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ContractRejectPopup;
