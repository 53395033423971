import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const TooltipLight = styled(({ className, open, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className, popper: className }} open={open} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
  },
}));

export default TooltipLight;
