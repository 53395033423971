import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { SettlementRecordDocType } from 'constants/transaction';
import { PopupController } from 'types/Common';
import TransDoc from 'views/Transaction/TransView/components/TransDoc';

type PopupProps = PopupController & {
  transactionId: number;
  recordId: number;
};

const TransRecordDocPopup = ({ transactionId, recordId, onClose }: PopupProps) => {
  return (
    <>
      <DialogTitle>
        <Trans>Record Document</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <TransDoc
              docType={SettlementRecordDocType}
              transactionId={transactionId}
              recordId={recordId}
              removable={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={() => onClose()}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransRecordDocPopup;
