import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { CreditScoreCreateType } from 'types/CreditScore';

const CreditScoreCreatePopup = ({ onClose }: PopupController) => {
  const { enqueueSnackbar } = useSnackbar();
const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: createCreditScore, isLoading } = useMutation(creditScoreService.createCreditScore, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.fetchCreditScores');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: CreditScoreCreateType) => {
      createCreditScore(values);
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Add Business Industry</Trans>
      </DialogTitle>

      <DialogContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <Controller
              name='industryVi'
              defaultValue=''
              control={control}
              rules={{ required: t`Vietnamese Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`Vietnamese Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              name='industryEn'
              defaultValue=''
              control={control}
              rules={{ required: t`English Name is required` }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t`English Name`}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' className='w-20' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Save</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default CreditScoreCreatePopup;
