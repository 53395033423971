import { Trans } from '@lingui/macro';
import { Button, Paper, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { enterpriseService } from 'services';
import { usePermission } from 'hooks';
import {
  EnterpriseBankAccount,
  EnterpriseFrequency,
  EnterpriseInfo,
  EnterprisePaymentMethod,
  EnterpriseReportTable,
  EnterpriseSeniorUserTable,
  EnterpriseOriginatorTable,
  EnterpriseTransactionRepresentative,
} from 'views/Enterprise/EnterpriseView/components';

const EnterpriseView = () => {
  const { id: _id } = useParams() as { id: string };
  const { ENTERPRISE_APPRAISAL, ENTERPRISE_REPORT, ENTERPRISE_VIEW, 
    requreUserPermission,ENTERPRISE_CONTRACT, ENTERPRISE_DANHMUCTHAMDINH,ENTERPRISE_DEXUATTHAMDINH } = usePermission();
  const { data: enterprise } = useQuery(['enterpriseService.getEnterpriseById', { id: _id }], () =>
    enterpriseService.getEnterpriseById({ id: _id }),
  );

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Enterprise Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <div className='flex justify-between mb-6'>
          <div>
            {requreUserPermission([ENTERPRISE_CONTRACT]) && <Link to={privateRoute.enterpriseContract.url?.({ id: _id })!}>
              <Button variant='contained'>
                <Trans>Contract Management</Trans>
              </Button>
            </Link>}
          </div>
          <div className='space-x-3'>
            {requreUserPermission([ENTERPRISE_DANHMUCTHAMDINH]) && <Link to={privateRoute.enterpriseAssignmentChecklist.url?.({ id: _id })!}>
              <Button variant='contained'>
                <Trans>Assignment Checklist</Trans>
              </Button>
            </Link>}
            { requreUserPermission([ENTERPRISE_DEXUATTHAMDINH]) &&
            <Link  to={privateRoute.enterpriseAssignmentProposal.url?.({ id: _id })!}>
              <Button variant='contained'>
                <Trans>Assignment Proposal</Trans>
              </Button>
            </Link>
            }
          </div>
        </div>
        {requreUserPermission([ENTERPRISE_REPORT]) && <EnterpriseReportTable enterprise={enterprise} />}
        {requreUserPermission([ENTERPRISE_VIEW]) && <EnterpriseInfo enterprise={enterprise} />}
        {requreUserPermission([ENTERPRISE_VIEW]) && <EnterprisePaymentMethod />}
        {requreUserPermission([ENTERPRISE_VIEW]) && <EnterpriseFrequency />}
        {requreUserPermission([ENTERPRISE_VIEW]) && <EnterpriseTransactionRepresentative />}
        {requreUserPermission([ENTERPRISE_VIEW]) && <EnterpriseBankAccount enterprise={enterprise} />}

        {requreUserPermission([ENTERPRISE_VIEW]) && <EnterpriseSeniorUserTable enterprise={enterprise} />}
        {requreUserPermission([ENTERPRISE_VIEW]) && <EnterpriseOriginatorTable enterprise={enterprise} />}
      </div>
    </Paper>
  );
};

export default EnterpriseView;
