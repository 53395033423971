import { t, Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper } from '@mui/material';
import PdfViewer from 'components/PdfViewer';
import { usePermission } from 'hooks';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { enterpriseService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ContractType } from 'types/EnterpriseContract';
import ContractRejectPopup from './ContractRejectPopup';

type PopupProps = PopupController & {
  contract: ContractType;
  enterpriseId: string;
};

const ContractApprovePopup = ({ contract, enterpriseId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireRole } = usePermission();
  const [isOpenReject, setOpenReject] = useState(false);

  const { mutate: approveContract, isLoading: isLoadingApprove } = useMutation(
    enterpriseService.approveEnterpriseContract,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseService.getEnterpriseLatest');
        queryClient.invalidateQueries('enterpriseService.fetchEnterpriseContract');
        onClose();
      },
    },
  );

  const handleClickApprove = () => {
    approveContract({
      _id: enterpriseId,
      id: contract.id!,
    });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Enterprise Contract</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={1}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>
        <PdfViewer objectKey={contract?.object?.key} />
      </DialogContent>

      <DialogActions className='relative' style={{ height: '55px' }}>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>

        {requireRole('CA_SENIOR') && (
          <>
            <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
              <Trans>Reject</Trans>
            </LoadingButton>
            <LoadingButton variant='contained' className='w-28' loading={isLoadingApprove} onClick={handleClickApprove}>
              <Trans>Approve</Trans>
            </LoadingButton>
          </>
        )}
      </DialogActions>
      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <ContractRejectPopup
          contract={contract}
          enterpriseId={enterpriseId}
          onClose={() => {
            setOpenReject(false);
            onClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default ContractApprovePopup;
