import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSearch, usePermission } from 'hooks';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { transactionOverdueService } from 'services';
import { TableEmpty } from 'components';
import { PopupController } from 'types/Common';
import { LoadingButton } from '@mui/lab';
import { TransOverdueGuaranteeNoticeType } from 'types/TransactionOverdue';
import {
  ViewGuaranteeNoticePopup,
  SignGuaranteeNoticePopup,
  ApproveGuaranteeNoticePopup,
  CreateGuaranteeNoticePopup,
} from './components';
import { getGuaranteeStatusType } from 'constants/enterprise';
import SelectPageSize from 'components/SelectPageSize';

const TransOverdueGuaranteeNoticePopup = ({ onClose }: PopupController) => {
  const { requireRole } = usePermission();
  const [isOpenNoticeCreate, setOpenNoticeCreate] = useState(false);
  const [isOpenNotice, setOpenNotice] = useState(false);
  const [record, setRecord] = useState<TransOverdueGuaranteeNoticeType>();
  const [dataSearch, onSearchChange] = useSearch({});

  const { data } = useQuery(
    ['transactionOverdueService.getOverdueTransactionGuaranteeNotices', dataSearch],
    () => transactionOverdueService.getOverdueTransactionGuaranteeNotices(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const onConfirm = (noticeId: number) => {
    transactionOverdueService
      .getGuaranteeNotice({
        id: noticeId,
      })
      .then(setRecord);
  };

  return (
    <>
      <DialogTitle>
        <Trans>Guarantee Implement Notice</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex justify-between'>
          <div className='flex items-center space-x-3'>
            <Typography variant='h4'>
              <Trans>List of Notice</Trans>
            </Typography>
            {(requireRole('ACCOUNTANT') || requireRole('ACC_CONTROLLER')) && (
              <LoadingButton variant='contained' onClick={() => setOpenNoticeCreate(true)}>
                <Trans>Create New</Trans>
              </LoadingButton>
            )}
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Notice No.</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Enterprise Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, idx) => (
                <TableRow key={item.id}>
                  <TableCell align='center'>{idx + 1}</TableCell>
                  <TableCell>{item.noticeNo}</TableCell>
                  <TableCell>{item.enterpriseName}</TableCell>
                  <TableCell align='center'>{getGuaranteeStatusType(item.status)}</TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setRecord(item);
                        setOpenNotice(true);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
      </DialogActions>
      <Dialog fullWidth maxWidth='md' open={isOpenNoticeCreate} onClose={() => setOpenNoticeCreate(false)}>
        <CreateGuaranteeNoticePopup onConfirm={onConfirm} onClose={() => setOpenNoticeCreate(false)} />
      </Dialog>
      {record?.status === 'WAITING_FOR_APPROVAL' && (
        <Dialog fullWidth maxWidth='md' open={isOpenNotice} onClose={() => setOpenNotice(false)}>
          <ApproveGuaranteeNoticePopup noticeId={record?.id!} onClose={() => setOpenNotice(false)} />
        </Dialog>
      )}
      {record?.status === 'WAITING_FOR_SIGNING' && (
        <Dialog fullWidth maxWidth='md' open={isOpenNotice} onClose={() => setOpenNotice(false)}>
          <SignGuaranteeNoticePopup noticeId={record?.id!} onClose={() => setOpenNotice(false)} />
        </Dialog>
      )}
      {record?.status === 'COMPLETED' && (
        <Dialog fullWidth maxWidth='md' open={isOpenNotice} onClose={() => setOpenNotice(false)}>
          <ViewGuaranteeNoticePopup noticeId={record?.id!} onClose={() => setOpenNotice(false)} />
        </Dialog>
      )}
      {record?.status === 'REJECTED' && (
        <Dialog fullWidth maxWidth='md' open={isOpenNotice} onClose={() => setOpenNotice(false)}>
          <ViewGuaranteeNoticePopup noticeId={record?.id!} onClose={() => setOpenNotice(false)} />
        </Dialog>
      )}
    </>
  );
};

export default TransOverdueGuaranteeNoticePopup;
