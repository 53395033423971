import { Trans } from '@lingui/macro';
import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { getDepartment } from 'constants/common';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { userService } from 'services';
import { DepartmentType } from 'types/User';
import { UserList } from './components';

const ManageDepartment = () => {
  const { data: manager } = useQuery(['userService.fetchUsers', { department: 'MANAGER' }], () =>
    userService.fetchUsers({ department: 'MANAGER' }),
  );
  const { data: crm } = useQuery(['userService.fetchUsers', { department: 'CRM' }], () =>
    userService.fetchUsers({ department: 'CRM' }),
  );
  const { data: ca } = useQuery(['userService.fetchUsers', { department: 'CA' }], () =>
    userService.fetchUsers({ department: 'CA' }),
  );
  const { data: accounting } = useQuery(['userService.fetchUsers', { department: 'ACCOUNTING' }], () =>
    userService.fetchUsers({ department: 'ACCOUNTING' }),
  );

  const tabs = [
    { code: 'manager', department: 'MANAGER', count: manager?.activeUsers },
    { code: 'crm', department: 'CRM', count: crm?.activeUsers },
    { code: 'ca', department: 'CA', count: ca?.activeUsers },
    { code: 'accounting', department: 'ACCOUNTING', count: accounting?.activeUsers },
  ].map((item) => ({
    code: item.code,
    label: `${getDepartment(item.department)} (${item.count ?? 0})`,
    component: <UserList department={item.department as DepartmentType} />,
  }));
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Department Assignment</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default ManageDepartment;
