import { Trans } from '@lingui/macro';
import { Grid, Paper, Typography } from '@mui/material';
import { SettingBank, SettingCost } from './components';

const CostAndPrice = () => {
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>General Setting</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Grid container rowSpacing={3}>
          <Grid item sm={12}>
            <SettingCost />
          </Grid>
          <Grid item sm={6}>
            <SettingBank />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default CostAndPrice;
