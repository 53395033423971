import { Trans } from '@lingui/macro';
import { PrivacyPolicy, RegisterPage, TermsOfUse } from 'views/Register';

const publicRoute = {
  register: {
    path: '/register',
    name: <Trans>Register</Trans>,
    element: <RegisterPage />,
  },
  termsOfUse: {
    path: '/terms-of-use',
    name: <Trans>Terms Of Use</Trans>,
    element: <TermsOfUse />,
  },
  privacyPolicy: {
    path: '/privacy-policy',
    name: <Trans>Privacy Policy</Trans>,
    element: <PrivacyPolicy />,
  },
};

export default publicRoute;
