import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useSearch } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { groupService, queryClient, userService } from 'services';
import { PopupController } from 'types/Common';
import { GroupType, GroupUpdateType } from 'types/Group';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';

type PopupProps = PopupController & {
  group: GroupType;
};

const GroupUpdatePopup = ({ group, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm();

  const [dataSearch, onSearchChange] = useSearch({ groupId: group.id, status: 'active' });

  const { data } = useQuery(['userService.fetchUsers', dataSearch], () => userService.fetchUsers(dataSearch), {
    keepPreviousData: true,
  });
  const { data: items = [], paginate } = data ?? {};

  const { mutate: updateGroup, isLoading: isLoadingUpdate } = useMutation(groupService.updateGroup, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('groupService.fetchGroups');
      queryClient.invalidateQueries('userService.fetchUsers');
    },
  });

  const { mutate: removeGroup, isLoading: isLoadingRemove } = useMutation(groupService.removeGroup, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('groupService.fetchGroups');
      queryClient.invalidateQueries('userService.fetchUsers');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values: GroupUpdateType) => {
      updateGroup({
        ...values,
        id: group.id,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Update Group</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-start'>
          <Controller
            name='name'
            defaultValue={group.name}
            control={control}
            rules={{ required: t`Group Name is required` }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                fullWidth
                required
                label={t`Group Name`}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />

          <LoadingButton
            variant='contained'
            className='min-w-fit ml-6'
            loading={isLoadingUpdate}
            onClick={handleClickSubmit}
          >
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Role</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Leader</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.fullname}</TableCell>
                  <TableCell>{item.role.name}</TableCell>
                  <TableCell className='text-center'>
                    <Checkbox checked={item.isLeader} />
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Back</Trans>
        </LoadingButton>
        <LoadingButton
          variant='contained'
          color='error'
          loading={isLoadingRemove}
          onClick={() => removeGroup({ id: group.id })}
        >
          <Trans>Remove</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default GroupUpdatePopup;
