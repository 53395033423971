import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profile';
import { HRManagementEligibleEmployee, HRManagementInfo, HRManagementList } from 'views/HR/components';

const HRManagement = () => {
  const { enterprise } = useSelector(profileSelector);
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>HR Management</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Paper className='bg-[#f2f2f2] p-4'>
          <HRManagementInfo enterprise={enterprise} />
        </Paper>
        <div className='mt-6'>
          <HRManagementEligibleEmployee enterprise={enterprise} />
          <HRManagementList enterprise={enterprise} />
        </div>
      </div>
    </Paper>
  );
};

export default HRManagement;
