import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { TableEmpty } from 'components';
import { PopupController } from 'types/Common';
import { useParams } from 'react-router-dom';
import { employeeSetupService } from 'services';
import { useQuery } from 'react-query';
import { useState } from 'react';
import ImageViewer from 'components/ImageViewer';

type PopupProps = PopupController;

const EKYCInformationPopup = ({ onSuccess, onClose }: PopupProps) => {
  const { id: _id } = useParams() as { id: string };
  const { data } = useQuery(['employeeSetupService.listEmployeeEkyc', { id: _id }], () =>
    employeeSetupService.listEmployeeEkyc({ id: _id }),
  );

  const { data: items = [] } = data ?? {};
  const [isOpenImage, setOpenImage] = useState(false);
  const [url, setUrl] = useState('');

  const handleView = (link: string) => {
    setOpenImage(true);
    setUrl(link);
  };

  return (
    <>
      <DialogTitle>
        <Trans>eKYC Information</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Card Type</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Near Image</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Far Image</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Front Image</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Back Image</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Authenticity</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, idx) => (
                <TableRow key={item.id}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{item.cardType}</TableCell>
                  <TableCell>
                    {item.nearImageKey && (
                      <Button onClick={() => handleView(item.nearImageKey)} size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.farImageKey && (
                      <Button onClick={() => handleView(item.farImageKey)} size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.frontImageKey && (
                      <Button onClick={() => handleView(item.frontImageKey)} size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {item.backImageKey && (
                      <Button onClick={() => handleView(item.backImageKey)} size='small' color='info'>
                        <Trans>View</Trans>
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{item.result}</TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>

      <Dialog fullWidth maxWidth='md' open={isOpenImage} onClose={() => setOpenImage(false)}>
        {url && <ImageViewer objectKey={url!} onClose={() => setOpenImage(false)} showFooter={true} />}
      </Dialog>
    </>
  );
};

export default EKYCInformationPopup;
