import { Trans } from '@lingui/macro';
import { Typography, TableContainer, Table, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { formatNumber } from 'utils/common';

const TopSale = () => {
  return (
    <div className='border rounded-lg mt-2 p-4'>
      <Typography variant='subtitle2'>
        <Trans>The business with the highest transaction value</Trans>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className='bg-primary-dark text-white'>TOP 1</TableCell>
              <TableCell>Company A</TableCell>
              <TableCell>{formatNumber(10000000)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='bg-primary-light text-white'>TOP 2</TableCell>
              <TableCell>Company B</TableCell>
              <TableCell>{formatNumber(10000000)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='bg-primary-light text-white'>TOP 3</TableCell>
              <TableCell>Company C</TableCell>
              <TableCell>{formatNumber(10000000)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant='subtitle2'>
        <Trans>The employee with the highest transaction value</Trans>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className='bg-primary-dark text-white'>TOP 1</TableCell>
              <TableCell>Company A</TableCell>
              <TableCell>{formatNumber(10000000)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='bg-primary-light text-white'>TOP 2</TableCell>
              <TableCell>Company B</TableCell>
              <TableCell>{formatNumber(10000000)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className='bg-primary-light text-white'>TOP 3</TableCell>
              <TableCell>Company C</TableCell>
              <TableCell>{formatNumber(10000000)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TopSale;
