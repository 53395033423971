import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Dialog, Step, StepLabel, Stepper } from '@mui/material';
import { useState } from 'react';
import { usePermission } from 'hooks';
import { PopupController } from 'types/Common';
import { ContractType } from 'types/EmployeeContract';
import ContractRejectPopup from './ContractRejectPopup';
import PdfViewer from 'components/PdfViewer';

type PopupProps = PopupController & {
  contract: ContractType;
  employeeId: string;
};

const ContractPartnerSignPopup = ({ contract, employeeId, onClose }: PopupProps) => {
  const { requireRole } = usePermission();
  const [isOpenReject, setOpenReject] = useState(false);

  return (
    <>
      <DialogTitle>
        <Trans>Employee Contract</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={1}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>
        <PdfViewer objectKey={contract?.object?.key} />
      </DialogContent>

      <DialogActions className='relative' style={{ height: '55px' }}>
        <LoadingButton variant='outlined' onClick={onClose} className='absolute left-4'>
          <Trans>Close</Trans>
        </LoadingButton>

        {requireRole('MASTER') && (
          <LoadingButton variant='outlined' color='error' onClick={() => setOpenReject(true)}>
            <Trans>Cancel Contract</Trans>
          </LoadingButton>
        )}
      </DialogActions>
      <Dialog fullWidth maxWidth='sm' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <ContractRejectPopup
          contract={contract}
          employeeId={employeeId}
          onClose={() => {
            setOpenReject(false);
            onClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default ContractPartnerSignPopup;
