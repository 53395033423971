import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-query';
import { PopupController } from 'types/Common';
import { formatNumber } from 'utils/common';
import { transactionService } from 'services';
import { DateTime } from 'luxon';
import { TransReviseType } from 'types/Transaction';

export const useStyles = makeStyles({
  table: {
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  cellTitle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    width: '30%',
  },
  cellValue: {
    width: '20%',
  },
});

type PopupProps = PopupController & {
  transactionRevise: TransReviseType;
  salaryPaymentDate?: string;
};

const TransReviseViewPopup = ({ transactionRevise, salaryPaymentDate, onClose }: PopupProps) => {
  const dataSearch = {
    transId: transactionRevise.transactionId,
    salaryPaymentDate: `${transactionRevise.salaryPaymentDate}`,
  };

  const classes = useStyles();

  const { data: transaction } = useQuery(
    ['transactionService.calculateTransactionRevise', dataSearch],
    () => transactionService.calculateTransactionRevise(dataSearch),
    { keepPreviousData: true },
  );

  return (
    <>
      <DialogTitle>
        <Trans>Transaction Revision</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Expected Salary Payment Date</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>
                  {salaryPaymentDate ? DateTime.fromISO(salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Revised Salary Payment Date</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>
                  {transaction?.salaryPaymentDate
                    ? DateTime.fromISO(transaction?.salaryPaymentDate).toFormat('dd/MM/yyyy')
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Received Amount (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.paymentAmount)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Revised Term (Day)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.appliedTerm)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Earned Discount Amount (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.earnedDiscountAmount)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Future Discount Amount (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.futureDiscountAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Promotion of Transaction Cost (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.promotionAmount)}</TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Total Revised Transaction Cost Employee pays (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.costAmount)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.cellTitle}>
                  <Trans>Revised Debt Purchasing Price (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>
                  {formatNumber(transaction?.debtPurchasingPriceAmount)}
                </TableCell>
                <TableCell className={classes.cellTitle}>
                  <Trans>Total Revised Transaction Cost Enterprise supported (VND)</Trans>
                </TableCell>
                <TableCell className={classes.cellValue}>{formatNumber(transaction?.enterpriseCost)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className={classes.cellTitle}>
                  <b>
                    <Trans>Collection Amount from Employee (VND)</Trans>
                  </b>
                </TableCell>
                <TableCell colSpan={2} className={classes.cellValue}>
                  {formatNumber(transaction?.collectionAmount)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className='mt-6'>
          <Grid container columnSpacing={4} rowSpacing={3}>
            <Grid item md={12} lg={6} xl={6}>
              <div className='flex items-center space-x-3'>
                <Typography variant='h4'>
                  <Trans>Program For Key Employee</Trans>
                </Typography>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Enterprise Supported Amount (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.keyEnterpriseSupportAmount)}
                      </TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Corresponding Labours</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.keyCorrespondingLabor)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>No. of Earned Labour</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>{formatNumber(transaction?.keyEarnedLabor)}</TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Earned Discount Amount (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.keyEarnedDiscountAmount)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>No. of Future Labour</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>{formatNumber(transaction?.keyFutureLabor)}</TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Future Discount Amount (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.keyFutureDiscountAmount)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Total Transaction Cost (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.keyTransactionCost)}
                      </TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>% of Enterprise support</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.keyEnterpriseSupportRate)} %
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={12} lg={6} xl={6}>
              <div className='flex items-center space-x-3'>
                <Typography variant='h4'>
                  <Trans>Program For Regular Employee</Trans>
                </Typography>
              </div>
              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Enterprise Supported Amount (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularEnterpriseSupportAmount)}
                      </TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Corresponding Labours</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularCorrespondingLabor)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>No. of Earned Labour</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularEarnedLabor)}
                      </TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Earned Discount Amount (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularEarnedDiscountAmount)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>No. of Future Labour</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularFutureLabor)}
                      </TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Future Discount Amount (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularFutureDiscountAmount)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.cellTitle}>
                        <Trans>Total Transaction Cost (VND)</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularTransactionCost)}
                      </TableCell>
                      <TableCell className={classes.cellTitle}>
                        <Trans>% of Enterprise support</Trans>
                      </TableCell>
                      <TableCell className={classes.cellValue}>
                        {formatNumber(transaction?.regularEnterpriseSupportRate)} %
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default TransReviseViewPopup;
