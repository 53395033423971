import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import {
  CompletedState,
  getContractMethod,
  getContractName,
  getContractStateForEnterprise,
  OutdatedState,
  WaitingPartnerState,
} from 'constants/contract';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { ContractType } from 'types/EnterpriseContract';
import { ContractPartnerSignPopup, ContractViewPopup } from 'views/Enterprise/EnterpriseContract/components';

const ContractListEnterprise = () => {
  const { data } = useQuery(
    [
      'enterpriseService.fetchEnterpriseContract',
      { _id: '0', states: `${WaitingPartnerState},${CompletedState},${OutdatedState}` },
    ],
    () =>
      enterpriseService.fetchEnterpriseContract({
        _id: '0',
        states: `${WaitingPartnerState},${CompletedState},${OutdatedState}`,
      }),
  );
  const { data: items = [] } = data ?? {};

  const [isOpen, setOpen] = useState(false);
  const [chosenContract, setChosenContract] = useState<ContractType>();

  return (
    <div className='mt-6'>
      <div className='flex justify-between items-center'>
        <Typography variant='h4'>
          <Trans>Contract List</Trans>
        </Typography>
        <Button variant='contained' color='primary'>
          <a target='_blank' className='text-primary' href='https://kyso.vnpt-ca.vn/Authen/PluginVersion?product=2'>
            <Trans>Download the e-sign plugin</Trans>
          </a>
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Contract Type</Trans>
              </TableCell>
              <TableCell>
                <Trans>Contract Form</Trans>
              </TableCell>
              <TableCell>
                <Trans>Contract No</Trans>
              </TableCell>
              <TableCell>
                <Trans>Signing Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>View</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{getContractStateForEnterprise(item.state)}</TableCell>
                <TableCell>{getContractName(item.type)}</TableCell>
                <TableCell>{getContractMethod(item.digital)}</TableCell>
                <TableCell>{item.contractNo}</TableCell>
                <TableCell>{item.signedAt ? DateTime.fromISO(item.signedAt).toFormat('dd/MM/yyyy') : ''}</TableCell>
                <TableCell className='text-right'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpen(true);
                      setChosenContract(item);
                    }}
                  >
                    <Trans>View</Trans>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        fullWidth
        maxWidth='lg'
        open={isOpen}
        PaperProps={{
          sx: {
            minHeight: '80vh',
            maxHeight: '80vh',
          },
        }}
        onClose={() => setOpen(false)}
      >
        {['WAITING_PARTNER'].includes(chosenContract?.state!) ? (
          <ContractPartnerSignPopup contract={chosenContract!} enterpriseId='0' onClose={() => setOpen(false)} />
        ) : (
          <ContractViewPopup contract={chosenContract!} enterpriseId='0' onClose={() => setOpen(false)} />
        )}
      </Dialog>
    </div>
  );
};

export default ContractListEnterprise;
