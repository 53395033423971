import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { creditScoreService, queryClient } from 'services';
import { PopupController } from 'types/Common';

type PopupProps = PopupController & {
  csId: number;
  prId: number;
};

const ParameterRemovePopup = ({ csId, prId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: removeParameter, isLoading } = useMutation(creditScoreService.removeParameter, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('creditScoreService.getCreditScoreById');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    removeParameter({ csId, id: prId });
  };

  return (
    <>
      <DialogTitle>
        <Trans>Remove Parameter</Trans>
      </DialogTitle>

      <DialogContent>
        <Typography>
          <Trans>Are your sure you want to remove this parameter?</Trans>
        </Typography>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        <LoadingButton variant='contained' color='error' loading={isLoading} onClick={handleClickSubmit}>
          <Trans>Remove</Trans>
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ParameterRemovePopup;
