import { Trans } from '@lingui/macro';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import { getDay } from 'constants/common';
import { DateTime } from 'luxon';
import { Fragment, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';

const EnterpriseMeetingCalendar = () => {
  const [pivot, setPivot] = useState(0);
  const pivotWeek = DateTime.now().plus({ week: pivot });
  const workingDays = Array.from({ length: 5 }).map((_, index) => pivotWeek.startOf('week').plus({ day: index }));

  const monthSearch = useMemo(() => {
    return {
      meetingDateFrom: pivotWeek.startOf('month').toISO(),
      meetingDateTo: pivotWeek.endOf('month').toISO(),
    };
  }, [pivotWeek]);

  const weekSearch = useMemo(() => {
    return {
      meetingDateFrom: pivotWeek.startOf('week').toISO(),
      meetingDateTo: pivotWeek.endOf('week').toISO(),
    };
  }, [pivotWeek]);

  const { data: all } = useQuery(
    ['enterpriseService.fetchEnterpriseReports', { _id: '0', statuses: 'AGREED_TO_MEET' }],
    () => enterpriseService.fetchEnterpriseReports({ _id: '0', statuses: 'AGREED_TO_MEET' }),
    {},
  );
  const { data: monthly } = useQuery(
    ['enterpriseService.fetchEnterpriseReports', { _id: 0, statuses: 'AGREED_TO_MEET', ...monthSearch }],
    () => enterpriseService.fetchEnterpriseReports({ _id: '0', statuses: 'AGREED_TO_MEET', ...monthSearch }),
  );
  const { data: weekly } = useQuery(
    ['enterpriseService.fetchEnterpriseReports', { _id: '0', statuses: 'AGREED_TO_MEET', ...weekSearch }],
    () => enterpriseService.fetchEnterpriseReports({ _id: '0', statuses: 'AGREED_TO_MEET', ...weekSearch, size: 1000 }),
  );

  return (
    <div>
      <div className='flex justify-between'>
        <Typography variant='h4' className='mb-2'>
          <Trans>Meeting Calendar</Trans>
        </Typography>
        <Typography variant='h4' className='mb-2'>
          <Trans>Number of clients aggred to meet</Trans>:{' '}
          <span className='text-red-500'>{all?.paginate.totalRecord}</span>
        </Typography>
      </div>

      <div className='flex items-start'>
        <Grid container className='text-center'>
          <Grid item sm={2} className='relative bg-primary-light text-white border p-2'>
            <div>
              <Trans>WEEK</Trans> {pivotWeek.weekNumber}
            </div>
            <div>{pivotWeek.year}</div>
          </Grid>
          {workingDays.map((today, index) => (
            <Grid item key={index} sm={2} className='bg-primary-light text-white border p-2'>
              <div>{getDay(today.weekdayLong.toUpperCase())}</div>
              <div>{today.toFormat('dd/MM/yyyy')}</div>
            </Grid>
          ))}

          <Grid item sm={2} className='border p-2'>
            <ButtonGroup variant='contained' size='small'>
              <Button onClick={() => setPivot((v) => v - 1)}>
                <NavigateBefore />
              </Button>
              <Button disabled={pivot === 0} onClick={() => setPivot(0)}>
                <Trans>TODAY</Trans>
              </Button>
              <Button onClick={() => setPivot((v) => v + 1)}>
                <NavigateNext />
              </Button>
            </ButtonGroup>
          </Grid>
          {workingDays.map((today, index) => {
            const { data: items = [] } = weekly ?? {};
            const meetings = items.filter((item) => DateTime.fromISO(item.meetingDate).startOf('day').equals(today));
            return (
              <Grid item key={index} sm={2} className='border p-2'>
                <Typography variant='h4' className='mb-2'>
                  {meetings.length}
                </Typography>
                <Grid container>
                  {meetings.map((client, index) => (
                    <Fragment key={index}>
                      <Grid item sm={4} className='bg-gray-300 border-b p-1'>
                        {DateTime.fromISO(client.meetingDate).toFormat('hh:mm a')}
                      </Grid>
                      <Grid item sm={8} className='bg-gray-200 border-b p-1'>
                        {client.enterpriseName}
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid container className='text-center w-60 ml-2'>
          <Grid item sm={12} className='bg-primary-light text-white border p-2'>
            <Trans>NUMBER OF MEETINGS IN WEEK</Trans>
          </Grid>
          <Grid item sm={12} className='border p-2'>
            <Typography variant='h4' className='mb-2'>
              {weekly?.paginate.totalRecord ?? 0}
            </Typography>
          </Grid>
          <Grid item sm={12} className='bg-primary-light text-white border p-2'>
            <Trans>NUMBER OF MEETINGS IN MONTH</Trans>
          </Grid>
          <Grid item sm={12} className='border p-2'>
            <Typography variant='h4' className='mb-2'>
              {monthly?.paginate.totalRecord ?? 0}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EnterpriseMeetingCalendar;
