import {
  CreditScoreCreateType,
  CreditScorePaginateParams,
  CreditScorePaginateType,
  CreditScoreType,
  CreditScoreUpdateType,
  CreditScoreUserAssign,
  CriteriaCreateType,
  CriteriaUpdateType,
  CriteriaRatioUpdateType,
  ParameterCreateType,
  ParameterType,
  ParameterUpdateType,
  RatingCreateType,
  ScoreCardCreateType,
  ScoreCardUpdateType,
} from 'types/CreditScore';
import { client } from './axios';

const getCreditScoreById = ({ id }: { id: string }): Promise<CreditScoreType> =>
  client.get(`/api/v1/credit_scoring/${id}`);
const fetchCreditScores = (params?: CreditScorePaginateParams): Promise<CreditScorePaginateType> =>
  client.get(`/api/v1/credit_scoring`, { params });
const createCreditScore = (body: CreditScoreCreateType) => client.post(`/api/v1/credit_scoring`, body);
const updateCreditScore = ({ id, ...body }: CreditScoreUpdateType) => client.put(`/api/v1/credit_scoring/${id}`, body);

const removeCreditScore = ({ id }: { id: string }) => client.delete(`/api/v1/credit_scoring/${id}`);
const completeCreditScore = ({ id }: { id: string }) => client.post(`/api/v1/credit_scoring/${id}/complete`);

const assignUserCrm = (body: CreditScoreUserAssign) => client.post(`/api/v1/credit_scoring/assign-crm`, body);
const assignUserCa = (body: CreditScoreUserAssign) => client.post(`/api/v1/credit_scoring/assign-ca`, body);

const createParameter = ({ csId, ...body }: ParameterCreateType) =>
  client.post(`/api/v1/credit_scoring/${csId}/parameter`, body);
const getParameterById = ({ csId, id }: ParameterUpdateType): Promise<ParameterType> =>
  client.get(`/api/v1/credit_scoring/${csId}/parameter/${id}`);
const updateParameter = ({ csId, id, ...body }: ParameterUpdateType) =>
  client.put(`/api/v1/credit_scoring/${csId}/parameter/${id}`, body);
const removeParameter = ({ csId, id }: ParameterUpdateType) =>
  client.delete(`/api/v1/credit_scoring/${csId}/parameter/${id}`);

const createCriteria = ({ csId, prId, ...body }: CriteriaCreateType) =>
  client.post(`/api/v1/credit_scoring/${csId}/parameter/${prId}/criteria_ratio`, body);
const removeCriteria = ({ csId, prId, id }: CriteriaUpdateType) =>
  client.delete(`/api/v1/credit_scoring/${csId}/parameter/${prId}/criteria_ratio/${id}`);
const updateCriteria = ({ csId, prId, id, ...body }: CriteriaRatioUpdateType) =>
  client.put(`/api/v1/credit_scoring/${csId}/parameter/${prId}/criteria_ratio/${id}`, body);

const createScoreQualitative = ({ csId, prId, crId, ...body }: ScoreCardCreateType) =>
  client.post(`/api/v1/credit_scoring/${csId}/parameter/${prId}/criteria_ratio/${crId}/qualitative`, body);
const removeScoreQualitative = ({ csId, prId, crId, id }: ScoreCardUpdateType) =>
  client.delete(`/api/v1/credit_scoring/${csId}/parameter/${prId}/criteria_ratio/${crId}/qualitative/${id}`);

const createScoreQuantitative = ({ csId, prId, crId, ...body }: ScoreCardCreateType) =>
  client.post(`/api/v1/credit_scoring/${csId}/parameter/${prId}/criteria_ratio/${crId}/quantitative`, body);
const removeScoreQuantitative = ({ csId, prId, crId, id }: ScoreCardUpdateType) =>
  client.delete(`/api/v1/credit_scoring/${csId}/parameter/${prId}/criteria_ratio/${crId}/quantitative/${id}`);

const createRatingScore = ({ csId, ...body }: RatingCreateType) =>
  client.post(`/api/v1/credit_scoring/${csId}/rating_category`, body);
const updateRatingScore = ({ csId, id, ...body }: RatingCreateType) =>
  client.put(`/api/v1/credit_scoring/${csId}/rating_category/${id}`, body);

export default {
  getCreditScoreById,
  fetchCreditScores,
  createCreditScore,
  updateCreditScore,

  removeCreditScore,
  completeCreditScore,

  assignUserCrm,
  assignUserCa,

  getParameterById,
  createParameter,
  updateParameter,
  removeParameter,

  createCriteria,
  removeCriteria,
  updateCriteria,

  createScoreQualitative,
  removeScoreQualitative,

  createScoreQuantitative,
  removeScoreQuantitative,

  createRatingScore,
  updateRatingScore,
};
