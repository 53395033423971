import { useState } from 'react';
import { PopupController } from 'types/Common';
import {
  EnterpriseServiceFeeNoticeCreateOrGenerateParamsType,
  EnterpriseServiceFeeNoticeType,
} from 'types/EnterpriseSetup';
import {
  NoticeCreatePopupStep1,
  NoticeCreatePopupStep2,
  NoticeCreatePopupStep3,
  NoticeCreatePopupStep4,
} from 'views/Enterprise/EnterpriseServiceFee/popups';

type PopupProps = PopupController & {
  enterpriseId: string;
  stepCurrent?: number;
  dataItem?: EnterpriseServiceFeeNoticeType;
};

const NoticeCreatePopup = ({ stepCurrent, enterpriseId, dataItem, onClose }: PopupProps) => {
  const [step, setStep] = useState(stepCurrent || 1);
  const [data, setData] = useState('');
  const [noticePayload, setNoticePayload] = useState<EnterpriseServiceFeeNoticeCreateOrGenerateParamsType>();

  return (
    <>
      {step === 1 && (
        <NoticeCreatePopupStep1
          setNoticeCreate={setNoticePayload}
          setData={setData}
          enterpriseId={enterpriseId}
          onClose={onClose}
          onNext={() => setStep(2)}
        />
      )}
      {(step === 2 || stepCurrent === 2) && (
        <NoticeCreatePopupStep2 data={data} noticeCreate={noticePayload!} onBack={() => setStep(1)} onNext={onClose} />
      )}
      {(step === 3 || stepCurrent === 3) && (
        <NoticeCreatePopupStep3 enterpriseId={enterpriseId} noticeCreate={dataItem!} onNext={onClose} />
      )}
      {(step === 4 || stepCurrent === 4) && <NoticeCreatePopupStep4 noticeCreate={dataItem!} onClose={onClose} />}
    </>
  );
};

export default NoticeCreatePopup;
