import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { Upload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { privateRoute } from 'routes';
import { enterpriseSetupService, queryClient } from 'services';
import { TableEmpty } from 'components';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { formatNumber } from 'utils/common';
import { usePermission } from 'hooks';
const EnterpriseSetupFinance = () => {
  const userLocale = useActiveLocale();
  const { requreUserPermission, ENTERPRISE_HOANTHANH_CHISOTAICHINH } = usePermission();
  const { enqueueSnackbar } = useSnackbar();
  const naviagate = useNavigate();
  const { id, fnId } = useParams() as { id: string; fnId: string };
  const inputRef = useRef<HTMLInputElement>(null);
  const [step, setStep] = useState(1);

  const { data: finance } = useQuery(['enterpriseSetupService.getEnterpriseFinance', { id, fnId }], () =>
    enterpriseSetupService.getEnterpriseFinance({ id, fnId }),
  );

  const { data: { items = [], years = [] } = {} } = useQuery(
    ['enterpriseSetupService.getEnterpriseCriteria', { id, fnId }],
    () => enterpriseSetupService.getEnterpriseCriteria({ id, fnId }),
  );

  const { data: { indexes = [] } = {}, isSuccess } = useQuery(
    ['enterpriseSetupService.getEnterpriseIndexes', { id, fnId }],
    () => enterpriseSetupService.getEnterpriseIndexes({ id, fnId }),
  );

  const {
    mutate: importEnterpriseFinance,
    isLoading: isLoadingImport,
    isSuccess: isSuccessImport,
  } = useMutation(enterpriseSetupService.importEnterpriseFinance, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseCriteria');
      queryClient.invalidateQueries('enterpriseSetupService.getEnterpriseIndexes');
    },
  });

  const { mutate: saveEnterpriseFinance, isLoading: isLoadingSave } = useMutation(
    enterpriseSetupService.saveEnterpriseFinance,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseFinances');
        naviagate(privateRoute.enterpriseSetupFinance.url?.({ id })!);
      },
    },
  );

  const { mutate: completeEnterpriseFinance, isLoading: isLoadingComplete } = useMutation(
    enterpriseSetupService.completeEnterpriseFinance,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('enterpriseSetupService.fetchEnterpriseFinances');
        naviagate(privateRoute.enterpriseSetupFinance.url?.({ id })!);
      },
    },
  );

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    Array.from(event.target.files!).forEach((file) => {
      formData.append('file', file);
    });
    importEnterpriseFinance({ id, fnId, formData });
    event.target.value = '';
  };

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Enterprise Financial Information</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Stepper activeStep={step} className='max-w-4xl mx-auto mb-4'>
          <Step>
            <StepLabel>
              <Trans>Financial Information</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Financial Ratio</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        <div hidden={step === 2}>
          {finance?.status === -1 && (
            <div className='flex items-center gap-3'>
              <Typography variant='h4'>
                <Trans>Please upload financial information of Enterprise for the last 4 years</Trans>
              </Typography>

              <input ref={inputRef} type='file' hidden onChange={handleChangeFiles} />
              <LoadingButton
                variant='contained'
                size='small'
                startIcon={<Upload />}
                loading={isLoadingImport}
                onClick={() => inputRef.current?.click()}
              >
                <Trans>Upload</Trans>
              </LoadingButton>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className='w-20'>
                    <Trans>No</Trans>
                  </TableCell>
                  <TableCell>
                    <Trans>Criteria</Trans>
                  </TableCell>
                  {years.map((item, index) => (
                    <TableCell key={index}>
                      {item.year == 0 ? '' : item.year} ({item.title})
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.financeCriteria.title}</TableCell>
                    <TableCell>
                      {userLocale === 'vi-VN' ? item.financeCriteria.viContent : item.financeCriteria.enContent}
                    </TableCell>
                    <TableCell className='text-right'>{formatNumber(item.t3)}</TableCell>
                    <TableCell className='text-right'>{formatNumber(item.t2)}</TableCell>
                    <TableCell className='text-right'>{formatNumber(item.t1)}</TableCell>
                    <TableCell className='text-right'>{formatNumber(item.t)}</TableCell>
                  </TableRow>
                ))}
                <TableEmpty data={items} />
              </TableBody>
            </Table>
          </TableContainer>

          <div className='flex justify-between'>
            <LoadingButton
              variant='contained'
              className='w-32'
              onClick={() => naviagate(privateRoute.enterpriseSetupFinance.url?.({ id })!)}
            >
              <Trans>Back</Trans>
            </LoadingButton>
            <LoadingButton
              variant='contained'
              className='w-32'
              disabled={!(Number(finance?.status) >= 0 || isSuccessImport)}
              onClick={() => setStep(2)}
            >
              <Trans>Next</Trans>
            </LoadingButton>
          </div>
        </div>

        {isSuccess && (
          <div hidden={step === 1}>
            <Grid container columnSpacing={4}>
              {[0, 3]
                .map((start) => indexes.slice(start, start + 3))
                .map((array, index) => (
                  <Grid item md={6} key={index}>
                    <Grid container rowSpacing={2}>
                      {array.map((item, index) => (
                        <Grid item md={12} key={index}>
                          <Typography variant='h4'>
                            {item.groupNumber} {userLocale === 'vi-VN' ? item.viContent : item.enContent}
                          </Typography>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Trans>Criteria</Trans>
                                  </TableCell>
                                  {years.slice(1).map((item, index) => (
                                    <TableCell key={index}>
                                      {item.year} ({item.title})
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {item.items.map((item, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      {userLocale === 'vi-VN'
                                        ? item.financeIndex.viContent
                                        : item.financeIndex.enContent}
                                    </TableCell>
                                    <TableCell className='text-center'>
                                      {formatNumber(item.financeIndex.groupNumber === 5 && index != 5 ? (item.t2 ? Math.round(item.t2) : item.t2) : item.t2)} {item.financeIndex.unit}
                                    </TableCell>
                                    <TableCell className='text-center'>
                                      {formatNumber(item.financeIndex.groupNumber === 5 && index != 5 ? (item.t1 ? Math.round(item.t1) : item.t1) : item.t1)} {item.financeIndex.unit}
                                    </TableCell>
                                    <TableCell className='text-center'>
                                      {formatNumber(item.financeIndex.groupNumber === 5 && index != 5 ? (item.t ? Math.round(item.t) : item.t) : item.t)} {item.financeIndex.unit}
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableEmpty data={item.items} />
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}
            </Grid>

            <div className='flex justify-between'>
              <LoadingButton variant='contained' className='w-32' onClick={() => setStep(1)}>
                <Trans>Back</Trans>
              </LoadingButton>

              <div className='flex gap-3'>
                {finance?.status === -1 && (
                  <LoadingButton
                    variant='contained'
                    className='w-32'
                    loading={isLoadingSave}
                    onClick={() => saveEnterpriseFinance({ id, fnId })}
                  >
                    <Trans>Save</Trans>
                  </LoadingButton>
                )}
                {finance?.status === 1 && requreUserPermission([ENTERPRISE_HOANTHANH_CHISOTAICHINH]) && (
                  <LoadingButton
                    variant='contained'
                    className='w-32'
                    loading={isLoadingComplete}
                    onClick={() => completeEnterpriseFinance({ id, fnId })}
                  >
                    <Trans>Complete</Trans>
                  </LoadingButton>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default EnterpriseSetupFinance;
