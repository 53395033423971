import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { PopupController } from 'types/Common';
import { useMutation, useQuery } from 'react-query';
import PdfViewer from 'components/PdfViewer';
import enterpriseSetup from 'services/enterpriseSetup';
import { UploadFileType, UploadObjectBas64Type, ObjectBas64Type } from 'types/Object';
import ImageViewer from 'components/ImageViewer';
type PopupProps = PopupController & {
    feePaymentDocId: number;
    enterpriseId: string;
    isPdf: Boolean;
};

const ViewFileUploadPaymentPopup = ({ isPdf, feePaymentDocId, enterpriseId, onClose }: PopupProps) => {
    const { data: key, isSuccess: isSuccessLoadFile } = useQuery(
        ['enterpriseSetup.getFilePdfFeePayment', { feePaymentDocId: feePaymentDocId, enterpriseId: enterpriseId, feePaymentId: 0 }],
        () => enterpriseSetup.getFilePdfFeePayment({ enterpriseId: enterpriseId, feePaymentDocId: feePaymentDocId, feePaymentId: 0 }),

    );

    return (
        <>
            <DialogTitle>CONFIRMATION</DialogTitle>

            {isSuccessLoadFile && (isPdf ? <DialogContent><PdfViewer objectKey={key!} /></DialogContent> : <DialogContent><ImageViewer objectKey={key!} onClose={() => onClose} showFooter={false} /></DialogContent>)}

            <DialogActions>
                <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
                    Cancel
                </LoadingButton>
            </DialogActions>
        </>
    );
};

export default ViewFileUploadPaymentPopup;
