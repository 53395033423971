import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { InputNumber } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { priceSetupSerivice, queryClient } from 'services';
import { SettingUpdateType } from 'types/PriceSetup';
import { usePermission } from 'hooks';

const SettingCost = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { requireUserPCManager, requreUserPermission, GENERALSETTING_COSTANDPRICE_UPDATE } = usePermission();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });

  useQuery(['priceSetupSerivice.getSetting'], () => priceSetupSerivice.getSetting(), {
    onSuccess: (data) => {
      Object.entries(data ?? {}).forEach(([key, value]) => {
        setValue(key, value);
      });
    },
  });

  const { mutate: updateCollection, isLoading: isLoadingCollection } = useMutation(
    priceSetupSerivice.updateCollection,
    {
      onSuccess: () => {
        enqueueSnackbar(t`Successful`);
        queryClient.invalidateQueries('priceSetupSerivice.getSetting');
      },
    },
  );

  const { mutate: updateRating, isLoading: isLoadingRating } = useMutation(priceSetupSerivice.updateRating, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('priceSetupSerivice.getSetting');
    },
  });

  const handleClickUpdateCollection = () => {
    handleSubmit((values: SettingUpdateType) => {
      updateCollection(values);
    })();
  };

  const handleClickUpdateRating = () => {
    handleSubmit((values: SettingUpdateType) => {
      updateRating(values);
    })();
  };

  return (
    <div>
      <Typography variant='h4'>
        <Trans>Flooring Discount Rate by Business Credit Scoring</Trans>
      </Typography>

      <TableContainer component={Paper} variant='outlined'>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className='font-bold'>
                <Trans>Credit Rating</Trans>
              </TableCell>
              {['A', 'B', 'C', 'D', 'E'].map((item) => (
                <TableCell key={item} className='font-bold'>
                  {item}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className='font-bold whitespace-nowrap'>
                <Trans>Flooring Discount Rate (APR)</Trans>
              </TableCell>
              {['typeA', 'typeB', 'typeC', 'typeD', 'typeE'].map((item) => (
                <TableCell key={item}>
                  <Controller
                    name={item}
                    defaultValue=''
                    control={control}
                    rules={{ required: true, min: 0, max: 100 }}
                    render={({ field, fieldState: { invalid, error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={invalid}
                        InputProps={{
                          inputComponent: InputNumber as any,
                          inputProps: { maxLength: 5 },
                        }}
                      />
                    )}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {requireUserPCManager() && requreUserPermission([GENERALSETTING_COSTANDPRICE_UPDATE]) && (
        <div className='flex justify-end'>
          <LoadingButton
            variant='contained'
            className='w-32'
            loading={isLoadingCollection}
            onClick={handleClickUpdateRating}
          >
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      )}

      <Typography variant='h4' className='mt-6'>
        <Trans>Collection Cost for Employee (%)</Trans>
      </Typography>

      <div className='flex gap-8 my-4'>
        <Controller
          name='collectionCost'
          defaultValue=''
          control={control}
          rules={{ required: true, min: 0, max: 100 }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              error={invalid}
              InputProps={{
                inputComponent: InputNumber as any,
                inputProps: { maxLength: 5 },
              }}
            />
          )}
        />
        {requireUserPCManager() && requreUserPermission([GENERALSETTING_COSTANDPRICE_UPDATE]) && (
          <LoadingButton
            variant='contained'
            className='w-32'
            loading={isLoadingRating}
            onClick={handleClickUpdateCollection}
          >
            <Trans>Update</Trans>
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

export default SettingCost;
