import {
  BankFeeCreateType,
  BankFeePaginateType,
  BankFeeParamsType,
  SettingType,
  SettingUpdateType,
} from 'types/PriceSetup';
import { client } from './axios';

const getSetting = (): Promise<SettingType> => client.get(`/api/v1/setting`);
const updateCollection = (body: SettingUpdateType) => client.post(`/api/v1/setting/collection-cost`, body);
const updateRating = (body: SettingUpdateType) => client.post(`/api/v1/setting/rating`, body);

const fetchBankFees = (params: BankFeeParamsType): Promise<BankFeePaginateType> =>
  client.get(`/api/v1/setting/bank-fee`, { params });
const createBankFee = (body: BankFeeCreateType) => client.post(`/api/v1/setting/bank-fee`, body);
const updateBankFee = ({ id, ...body }: BankFeeCreateType) => client.put(`/api/v1/setting/bank-fee/${id}`, body);
const removeBankFee = ({ id }: { id: number }) => client.delete(`/api/v1/setting/bank-fee/${id}`);

export default {
  getSetting,
  updateCollection,
  updateRating,

  fetchBankFees,
  createBankFee,
  updateBankFee,
  removeBankFee,
};
