import { Trans } from '@lingui/macro';
import { Paper, Typography } from '@mui/material';
import { usePermission } from 'hooks';
import {
  EnterpriseChecklistAppraisal,
  EnterpriseChecklistHistory,
  EnterpriseChecklistLegal,
} from 'views/Enterprise/EnterpriseChecklist/components';
const EnterpriseChecklist = () => {
  const { requreUserPermission,ENTERPRISE_LEGAL,ENTERPRISE_APPRAISAL } = usePermission();
  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Assignment Checklist</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        {requreUserPermission([ENTERPRISE_LEGAL]) &&<EnterpriseChecklistLegal />}
        {requreUserPermission([ENTERPRISE_APPRAISAL]) &&<EnterpriseChecklistAppraisal />}
        {requreUserPermission([ENTERPRISE_APPRAISAL]) &&<EnterpriseChecklistHistory />}
      </div>
    </Paper>
  );
};

export default EnterpriseChecklist;
