import { Trans, t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatNumber } from 'utils/common';
import { useSearch, usePermission } from 'hooks';
import { useMutation, useQuery } from 'react-query';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { transactionService, queryClient } from 'services';
import { TransSettlementRecordType, TransType } from 'types/Transaction';
import {
  SettlementRecordStatusApproved,
  SettlementRecordStatusRequested,
  WaitSettlementStatus,
} from 'constants/transaction';
import {
  TransSettlementUpdateRecordPopup,
  TransRecordDocPopup,
} from 'views/Transaction/TransSettlement/TransSettlementView/popups';
import { LoadingButton } from '@mui/lab';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';

const TransSettlementRecordHis = ({ transaction }: { transaction?: TransType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenRecord, setOpenRecord] = useState(false);
  const [isOpenDoc, setOpenDoc] = useState(false);
  const [record, setRecord] = useState<TransSettlementRecordType>();
  const [dataSearch, onSearchChange] = useSearch({ transId: transaction?.id! });
  const { isRecordHistory, requreUserPermission, TRANSACTION_XEMTAILIEU, TRANSACTION_DUYETTATTOAN_LICHSUGHINHAN, TRANSACTION_SUA_LICHSUGHINHAN } = usePermission();
  const { data } = useQuery(
    ['transactionService.fetchTransactionSettlementRecords', dataSearch],
    () => transactionService.fetchTransactionSettlementRecords(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  const { mutate: approveRecord, isLoading } = useMutation(transactionService.approveTransactionSettlementRecord, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('transactionService.fetchTransactionSettlementRecords');
    },
  });

  const handleApproveSettlementClick = (item: TransSettlementRecordType) => {
    approveRecord({
      id: item.id!,
      transId: transaction?.id!,
    });
  };

  const renderConfirm = (item: TransSettlementRecordType) => {
    if (transaction?.status === WaitSettlementStatus || item.status === SettlementRecordStatusApproved) {
      return item.confirmedUser?.fullname;
    }
    if (item.status === SettlementRecordStatusRequested && isRecordHistory()) {
      return (
        <div className='flex items-center space-x-3'>
          {requreUserPermission([TRANSACTION_DUYETTATTOAN_LICHSUGHINHAN]) &&
            <LoadingButton
              variant='contained'
              size='small'
              className='w-36'
              loading={isLoading}
              onClick={() => handleApproveSettlementClick(item)}
            >
              <Trans>Approve Settlement</Trans>
            </LoadingButton>
          }
          {requreUserPermission([TRANSACTION_SUA_LICHSUGHINHAN]) &&
            <Button
              variant='outlined'
              size='small'
              onClick={() => {
                setRecord(item);
                setOpenRecord(true);
              }}
            >
              <Trans>Edit</Trans>
            </Button>
          }
        </div>
      );
    }
    return '';
  };

  return (
    <div className='mt-6'>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Record History</Trans>
          </Typography>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Record Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collection Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collected Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Settlement Amount (VND)</Trans>
              </TableCell>
              {/* <TableCell>
                <Trans>Bank Fee after tax (VND)</Trans>
              </TableCell> */}
              <TableCell>
                <Trans>Note</Trans>
              </TableCell>
              <TableCell>
                <Trans>Requester</Trans>
              </TableCell>
              <TableCell>
                <Trans>Confirmer</Trans>
              </TableCell>
              <TableCell>
                <Trans>Document</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center'>
                  {item.createdAt ? DateTime.fromISO(item.createdAt).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell align='center'>
                  {item.collectionDate ? DateTime.fromISO(item.collectionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectedAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectedAmount - item.bankFee)}</TableCell>
                {/* <TableCell className='text-right'>{formatNumber(item.bankFee)}</TableCell> */}
                <TableCell>{item.note}</TableCell>
                <TableCell>{item.createdUser?.fullname}</TableCell>
                <TableCell>{renderConfirm(item)}</TableCell>
                <TableCell align='center'>
                  {requreUserPermission([TRANSACTION_XEMTAILIEU]) &&
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setRecord(item);
                        setOpenDoc(true);
                      }}>
                      <Trans>View</Trans>
                    </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>
      <Dialog fullWidth maxWidth='md' open={isOpenRecord} onClose={() => setOpenRecord(false)}>
        <TransSettlementUpdateRecordPopup
          transaction={transaction!}
          record={record!}
          onClose={() => setOpenRecord(false)}
        />
      </Dialog>
      <Dialog fullWidth maxWidth='lg' open={isOpenDoc} onClose={() => setOpenDoc(false)}>
        <TransRecordDocPopup
          transactionId={transaction?.id!}
          recordId={record?.id!}
          onClose={() => setOpenDoc(false)}
        />
      </Dialog>
    </div>
  );
};

export default TransSettlementRecordHis;
