import { Trans, t } from '@lingui/macro';
import { Paper, Tab, Tabs, Typography } from '@mui/material';
import { useTabs } from 'hooks';
import { useQuery } from 'react-query';
import { transactionService } from 'services';
import {
  WaitSettlementStatus,
  RevisedStatus,
  WaitSettlementApprovalStatus,
  SettledStatus,
} from 'constants/transaction';
import { TransWaitSettlement, TransSettled } from 'views/Transaction/TransSettlement/TransSettlementList/components';

const TransSettlementList = () => {
  const { data: waitSettlement } = useQuery(
    ['transactionService.fetchTransactions', { statuses: `${WaitSettlementStatus},${RevisedStatus}` }],
    () => transactionService.fetchTransactions({ statuses: `${WaitSettlementStatus},${RevisedStatus}` }),
  );
  const { data: settled } = useQuery(
    ['transactionService.fetchTransactions', { statuses: `${WaitSettlementApprovalStatus},${SettledStatus}` }],
    () => transactionService.fetchTransactions({ statuses: `${WaitSettlementApprovalStatus},${SettledStatus}` }),
  );

  const tabs = [
    {
      code: 'waitSettlement',
      label: t`WAIT_SETTLEMENT (${waitSettlement?.paginate.totalRecord ?? 0})`,
      component: <TransWaitSettlement />,
    },
    { code: 'settled', label: t`SETTLED (${settled?.paginate.totalRecord ?? 0})`, component: <TransSettled /> },
  ];
  const [activeTab, onChangeTab] = useTabs(tabs);

  return (
    <Paper>
      <div className='bg-primary-main text-white rounded-t px-3 py-2'>
        <Typography variant='h3'>
          <Trans>Transaction Payment</Trans>
        </Typography>
      </div>
      <div className='p-3'>
        <Tabs value={activeTab} onChange={onChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <div key={tab.code} hidden={tab.code !== activeTab} className='mt-4'>
            {tab.component}
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default TransSettlementList;
