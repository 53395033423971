import { Trans, t } from '@lingui/macro';
import {
  Button,
  debounce,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  IconButton,
  Dialog
} from '@mui/material';
//import { ArrowRightIcon } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { formatNumber } from 'utils/common';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { useSnackbar } from 'notistack';
import { transactionService } from 'services';
import { WaitSettlementStatus, RevisedStatus, getTransactionStatus } from 'constants/transaction';
import { TableEmpty } from 'components';
import { TransType } from 'types/Transaction';
import {
  MultiTransSettlementAddRecordPopup,
} from 'views/Transaction/TransSettlement/TransSettlementView/popups';
import SelectPageSize from 'components/SelectPageSize';
const TransWaitSettlement = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchNDH, setSearchNDH] = useState(null as DateTime | null);
  const [dataSearch, onSearchChange] = useSearch({ statuses: `${WaitSettlementStatus},${RevisedStatus}` });
  const [searchType, setSearchType] = useState('employeeName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [dataSelect, setDataSelect] = useState<TransType[]>([]);
  const [isOpenRecord, setOpenRecord] = useState(false);
  const { data } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  useEffect(() => {
    debounceChangeValue({ [searchType]: searchText, salary_date: searchNDH?.toISO() });
  }, [searchNDH])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  function calculateSum(array: any[], property: string) {
    const total = array.reduce((accumulator, object) => {
      return accumulator + object[property];
    }, 0);

    return total;
  }

  const ghiNhanTatToan = () => {
    let strNDH = [] as string[]
    let strDN = [] as number[]
    for (let property of dataSelect) {
      if (strNDH.length == 0) {
        strNDH.push(property.salaryPaymentDate)
        strDN.push(property.enterprise.id)
      }
      else {

        if (strNDH.indexOf(property.salaryPaymentDate) == -1) {
          enqueueSnackbar("Các giao dịch lựa chọn không cùng ngày đáo hạn, vui lòng kiểm tra lại", { variant: 'warning' })
          return
        }
        else if (strDN.indexOf(property.enterprise.id) == -1) {
          enqueueSnackbar("Các giao dịch lựa chọn không cùng một doanh nghiệp, vui lòng kiểm tra lại", { variant: 'warning' })
          return
        }
      }
    }
    setOpenRecord(true)
  }

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex items-center space-x-3'>
          <Typography variant='h4'>
            <Trans>Transaction List</Trans>
          </Typography>
        </div>
        <div className='space-x-3'>
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
            }}
          >
            <MenuItem value='employeeName'>
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value='employeeErcNumber'>
              <Trans>ID Card No.</Trans>
            </MenuItem>
            <MenuItem value='transactionCode'>
              <Trans>Transaction ID</Trans>
            </MenuItem>
            <MenuItem value='enterpriseName'>
              <Trans>Enterprise Name</Trans>
            </MenuItem>
          </TextField>
          <TextField
            placeholder={t`Search...`}
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceChangeValue({ [searchType]: event.target.value });
            }}
          />
          {/* <Controller
            name='firstGrantedDate'
            defaultValue=''
            control={control}
            rules={{ validate: { isValid: (value: DateTime) => value && value.isValid } }}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <DesktopDatePicker
                value={value}
                onChange={onChange}
                renderInput={(props) => <TextField {...props} required error={invalid} />}
                label={t`Maturity Date`}
                inputFormat='dd/MM/yyyy'
              />
            )}
          /> */}
          <DesktopDatePicker
            value={searchNDH}
            onChange={setSearchNDH}
            renderInput={(props) => <TextField {...props} placeholder={t`Maturity Date`} />}
            label={t`Maturity Date`}
            inputFormat='dd/MM/yyyy'
          />
        </div>
      </div>

      <Dialog fullWidth maxWidth='lg' open={isOpenRecord} onClose={() => setOpenRecord(false)}>
        <MultiTransSettlementAddRecordPopup transaction={dataSelect} onClose={() => setOpenRecord(false)} />
      </Dialog>

      <div className='flex justify-between'>
        <div className='space-x-12'>
          {dataSelect.length > 0 && <div><Button variant='contained' style={{ float: 'left' }} onClick={ghiNhanTatToan} className='m-2 text-right'>
            <Trans>Settlement Record</Trans>
          </Button>
            <h4 style={{ fontWeight: '500', float: 'left', marginTop: 15, marginLeft: 10 }}>Tổng số tiền ghi nhận tất toán</h4>
            <IconButton style={{ marginTop: 12, marginLeft: 5, color: '#000', pointerEvents: 'none', float: 'left' }}
              size='small'
              color='info'>
              <ArrowRightIcon fontSize='small' />
            </IconButton>
            <p style={{ float: 'left', marginTop: 15, marginLeft: 5, fontWeight: '600' }}>{formatNumber(calculateSum(dataSelect, "collectionAmount"))}</p>
          </div>}
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(event, checked) => {
                    if (checked) setDataSelect(items);
                    else setDataSelect([]);
                  }}
                  checked={dataSelect.length === items.length}
                  indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                />
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Request Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Maturity Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collection Amount from Employee (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Bank</Trans>
              </TableCell>
              <TableCell>
                <Trans>Account number</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align='center' className='p-1'>
                  <Checkbox
                    checked={dataSelect.some((next) => next.id === item.id)}
                    onChange={(event, checked) => {
                      if (checked) setDataSelect((nexts) => nexts.concat(item));
                      else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                    }}
                  />
                </TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell align='center'>
                  {item.transactionDate ? DateTime.fromISO(item.transactionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.employee?.name}</TableCell>
                <TableCell>{item.enterprise?.name}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.requestAmount)}</TableCell>
                <TableCell className='text-right'>{formatNumber(item.paymentAmount)}</TableCell>
                <TableCell align='center'>
                  {item.salaryPaymentDate ? DateTime.fromISO(item.salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className='text-right'>{formatNumber(item.collectionAmount)}</TableCell>
                <TableCell>{item.designatedBank?.name}</TableCell>
                <TableCell>{item.designatedBankAccountNumber}</TableCell>
                <TableCell>{getTransactionStatus(item.status)}</TableCell>
                <TableCell align='center'>
                  <Link to={privateRoute.transactionWaitSettlementView.url?.(item)!}>
                    <Button size='small' color='info'>
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-between'>
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>
    </div>
  );
};

export default TransWaitSettlement;
