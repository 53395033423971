import { Trans, t } from '@lingui/macro';
import {
  Button, Checkbox,
  debounce, Dialog,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { formatNumber } from 'utils/common';
import { usePermission, useSearch } from 'hooks';
import { useMutation, useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { enterpriseSetupService, queryClient, transactionService } from 'services';
import { getTransactionStatus, SettledStatus, WaitSettlementApprovalStatus } from 'constants/transaction';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import CircularProgress from '@mui/material/CircularProgress';
import { TransSummaryType, TransType } from 'types/Transaction';
import { useSnackbar } from 'notistack';
import { ActionConfirmPopup } from 'views/Enterprise/EnterpriseServiceFee/popups';

const TransSettled = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [dataSearch, onSearchChange] = useSearch({
    statuses: `${WaitSettlementApprovalStatus},${SettledStatus}`,
    // sum: 1,
  });
  const [searchType, setSearchType] = useState('employeeName');
  const [searchText, setSearchText] = useState('');
  const [searchParams, setSearchParams] = useState({});

  const [dataSelect, setDataSelect] = useState<TransType[]>([]);
  const [openApproval, setOpenApproval] = useState(false);
  const { requreUserPermission, isRecordHistory, TRANSACTION_DUYETTATTOAN_LICHSUGHINHAN } = usePermission();

  const { data, isLoading, isFetching } = useQuery(
    ['transactionService.fetchTransactions', dataSearch],
    () => transactionService.fetchTransactions(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate, summary } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChangeValue = useCallback(
    debounce((value) => {
      setSearchParams(value);
    }, 300),
    [],
  );

  const {
    mutate: approvalTranHandle,
    isLoading: isLoadingApproval,
  } = useMutation(transactionService.approveAllTransactionsSettlementRecords, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      setOpenApproval(false);
      setDataSelect([]);
      debounceChangeValue({ ['force']: Math.floor(Math.random() * 10000) });
    },
  });

  const handleApprovalClickSubmit = () => {
    approvalTranHandle(dataSelect.map((item) => item.id));
  };

  const totalWaitSettleApprovalPaymentAmount = useMemo(()=>{
    return dataSelect.filter(s => s.status === WaitSettlementApprovalStatus).reduce((sum, current) => sum + current.collectionAmount, 0)
  },[dataSelect]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center space-x-3">
          <Typography variant="h4">
            <Trans>Transaction List</Trans>
          </Typography>
          {isFetching && <CircularProgress></CircularProgress>}
        </div>
        <div className="space-x-3">
          <TextField
            select
            value={searchType}
            onChange={(event) => {
              setSearchText('');
              onSearchChange({});
              setSearchType(event.target.value);
              debounceChangeValue({ [searchType]: '' });
              setDataSelect([]);
              if (event.target.value === 'statuses') {
                setSearchText(`${WaitSettlementApprovalStatus},${SettledStatus}`);
              } else {
                // onSearchChange({statuses: `${WaitSettlementApprovalStatus},${SettledStatus}`});
                debounceChangeValue({ ['statuses']: `${WaitSettlementApprovalStatus},${SettledStatus}` });
              }
            }}
            disabled={isFetching}
          >
            <MenuItem value="employeeName">
              <Trans>Employee Name</Trans>
            </MenuItem>
            <MenuItem value="employeeErcNumber">
              <Trans>ID Card No.</Trans>
            </MenuItem>
            <MenuItem value="transactionCode">
              <Trans>Transaction ID</Trans>
            </MenuItem>
            <MenuItem value="enterpriseName">
              <Trans>Enterprise Name</Trans>
            </MenuItem>
            <MenuItem value="statuses">
              <Trans>Status</Trans>
            </MenuItem>
          </TextField>
          {searchType !== 'statuses' &&
            <TextField
              placeholder={t`Search...`}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
              disabled={isFetching}
            />}
          {searchType === 'statuses' &&
            <TextField
              select
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
                debounceChangeValue({ [searchType]: event.target.value });
              }}
              disabled={isFetching}
            >
              <MenuItem value={`${WaitSettlementApprovalStatus},${SettledStatus}`}>
                <Trans>All</Trans>
              </MenuItem>
              <MenuItem value={`${WaitSettlementApprovalStatus}`}>
                <Trans>Waiting For Approval</Trans>
              </MenuItem>
              <MenuItem value={`${SettledStatus}`}>
                <Trans>Settled</Trans>
              </MenuItem>
            </TextField>}
        </div>
      </div>
      <div className="flex justify-start">
        {isRecordHistory() && requreUserPermission([TRANSACTION_DUYETTATTOAN_LICHSUGHINHAN]) && <Button
          className="mr-2"
          variant="contained"
          onClick={() => {
            if (dataSelect.length === 0) enqueueSnackbar(t`Please select at least one`, { variant: 'warning' });
            else setOpenApproval(true);
          }}
        >
          <Trans>Settlement approval</Trans>
        </Button>}
        <div className="flex items-center space-x-3">
          <Trans>Total amount waiting for settlement approval</Trans>
          <span className="mx-2">&#11208;</span>
          <strong>{formatNumber(totalWaitSettleApprovalPaymentAmount || 0)}</strong>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(event, checked) => {
                    if (checked) setDataSelect(items);
                    else setDataSelect([]);
                  }}
                  checked={dataSelect.length === items.length}
                  indeterminate={dataSelect.length > 0 && dataSelect.length < items.length}
                />
              </TableCell>
              <TableCell>
                <Trans>Transaction ID</Trans>
              </TableCell>
              <TableCell>
                <Trans>Transaction Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Employee Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Enterprise Name</Trans>
              </TableCell>
              <TableCell>
                <Trans>Request Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Payment Amount (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Maturity Date</Trans>
              </TableCell>
              <TableCell>
                <Trans>Collection Amount from Employee (VND)</Trans>
              </TableCell>
              <TableCell>
                <Trans>Status</Trans>
              </TableCell>
              <TableCell>
                <Trans>Detail</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell align="center">
                  <Checkbox
                    checked={dataSelect.some((next) => next.id === item.id)}
                    onChange={(event, checked) => {
                      if (checked) setDataSelect((nexts) => nexts.concat(item));
                      else setDataSelect((nexts) => nexts.filter((next) => next.id !== item.id));
                    }}
                  />
                </TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell align="center">
                  {item.transactionDate ? DateTime.fromISO(item.transactionDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>{item.employee?.name}</TableCell>
                <TableCell>{item.enterprise?.name}</TableCell>
                <TableCell className="text-right">{formatNumber(item.requestAmount)}</TableCell>
                <TableCell className="text-right">{formatNumber(item.paymentAmount)}</TableCell>
                <TableCell align="center">
                  {item.salaryPaymentDate ? DateTime.fromISO(item.salaryPaymentDate).toFormat('dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell className="text-right">{formatNumber(item.collectionAmount)}</TableCell>
                <TableCell>{getTransactionStatus(item.status)}</TableCell>
                <TableCell align="center">
                  <Link to={privateRoute.transactionWaitSettlementView.url?.(item)!}>
                    <Button size="small" color="info">
                      <Trans>View</Trans>
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableEmpty data={items} />
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex justify-between">
        <SelectPageSize
          size={paginate?.size ?? 10}
          onChange={(size) => {
            onSearchChange({ size: size });
          }}
        />
        <Pagination
          page={paginate?.page ?? 1}
          count={paginate?.totalPage}
          onChange={(event, value) => {
            onSearchChange({ page: value });
          }}
        />
      </div>

      <Dialog fullWidth maxWidth="sm" open={openApproval} onClose={() => setOpenApproval(false)}>
        <ActionConfirmPopup
          isLoading={isLoadingApproval}
          handleAction={handleApprovalClickSubmit}
          onClose={() => setOpenApproval(false)}
        />
      </Dialog>

    </div>
  );
};

export default TransSettled;
