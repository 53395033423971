import { Trans } from '@lingui/macro';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { CriteriaType } from 'types/CreditScore';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { formatNegativeNumber } from 'utils/common';

const CriteriaScoreViewTable = ({ criteriaScore }: { criteriaScore: CriteriaType }) => {
  const userLocale = useActiveLocale();
  const isQualitative = !criteriaScore.quantitativeScorecards;
  const items = criteriaScore.qualitativeScorecards || criteriaScore.quantitativeScorecards;

  return (
    <>
      <Typography variant='h6'>
        {userLocale === 'vi-VN' ? criteriaScore.descriptionVi : criteriaScore.descriptionEn}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Criteria</Trans>
              </TableCell>
              <TableCell>
                <Trans>Score</Trans>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item) => (
              <TableRow key={item.id}>
                <TableCell className={isQualitative ? 'text-left' : 'text-center'}>
                  {isQualitative
                    ? `${userLocale === 'vi-VN' ? item.nameVi : item.nameEn}`
                    : `${formatNegativeNumber(item.minValue)} - ${formatNegativeNumber(item.maxValue)}`}
                </TableCell>
                <TableCell className='text-center'>{item.score}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CriteriaScoreViewTable;
