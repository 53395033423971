import { Trans } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  Button,
} from '@mui/material';
import { TableEmpty } from 'components';
import { useSearch, usePermission } from 'hooks';
import { useQuery } from 'react-query';
import { enterpriseSetupService } from 'services';
import { useState } from 'react';
import { PopupController } from 'types/Common';
import { NoticeCreatePopup } from 'views/Enterprise/EnterpriseServiceFee/popups';
import { formatNumber } from 'utils/common';
import { EnterpriseServiceFeeNoticeType } from 'types/EnterpriseSetup';
import { getFeeNoticeStatusType } from 'constants/enterprise';
import SelectPageSize from 'components/SelectPageSize';
type NoticeListPopupProps = PopupController & {
  id: string;
};

const NoticeListPopup = ({ id, onClose }: NoticeListPopupProps) => {
  const { requireUserPCManager, isAccountantRoles } = usePermission();
  const [dataSearch, onSearchChange] = useSearch();
  const [openCreateNotice, setOpenCreateNotice] = useState(false);
  const [stepCurrent, setStepCurrent] = useState<number>();
  const [currentChoice, setCurrentChoice] = useState<EnterpriseServiceFeeNoticeType>();

  const { data } = useQuery(
    ['enterpriseSetupService.fetchEnterpriseFeeNotices', { enterpriseId: id, ...dataSearch }],
    () => enterpriseSetupService.fetchEnterpriseFeeNotices({ enterpriseId: id, ...dataSearch }),
    { keepPreviousData: true },
  );

  const { data: items = [], paginate } = data ?? {};

  return (
    <>
      <DialogTitle>
        <Trans>NOTICE OF SINGLE FEE PAYMENT</Trans>
      </DialogTitle>

      <DialogContent>
        <div className='flex items-center justify-between'>
          <Typography variant='h5'>
            <Trans>List of Payment Notice</Trans>
          </Typography>
          {(requireUserPCManager() || isAccountantRoles()) && <LoadingButton variant='contained' onClick={() => {
            setOpenCreateNotice(true); setCurrentChoice(undefined);
            setStepCurrent(undefined);
          }}>
            <Trans>Create Notice</Trans>
          </LoadingButton>}
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>Month</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Notice No.</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Single Service Fee before tax (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Single Service Fee after tax (VND)</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Status</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item, idx) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.month}/{item.year}
                  </TableCell>
                  <TableCell>{item.code}</TableCell>
                  <TableCell align='right'>{formatNumber(item.feeBeforeTax)}</TableCell>
                  <TableCell align='right'>{formatNumber(item.feeAfterTax)}</TableCell>
                  <TableCell>{getFeeNoticeStatusType(item.status)}</TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setOpenCreateNotice(true);
                        setCurrentChoice(item);
                        setStepCurrent(item.status === 'WAITING_FOR_SIGNING' ? 3 : 4);
                      }}
                    >
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>

      <Dialog fullWidth maxWidth='lg' open={openCreateNotice} onClose={() => setOpenCreateNotice(false)}>
        <NoticeCreatePopup
          dataItem={currentChoice!}
          stepCurrent={stepCurrent}
          enterpriseId={id}
          onClose={() => setOpenCreateNotice(false)}
        />
      </Dialog>
    </>
  );
};

export default NoticeListPopup;
