import { Trans, t } from '@lingui/macro';

import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import { employeeSetupService, queryClient } from 'services';
import { UpdateOrRemoveBlacklistType } from 'types/EmployeeSetup';
import { useMutation } from 'react-query';

type PopupProps = {
  onBack: () => void;
  onNext: () => void;
  enterpriseEmployeeId: number;
  employeeId: string;
  pasId: number;
};

const RejectPopup = ({ pasId, employeeId, enterpriseEmployeeId, onNext, onBack }: PopupProps) => {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updatePayrollAdvanceSettingEachEnterprise, isLoading } = useMutation(
    employeeSetupService.updatePayrollAdvanceSettingEachEnterprise,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('employeeSetupService.getSalaryCondition');
        onNext();
      },
    },
  );

  const handleClickSubmit = () => {
    handleSubmit(({ reason }: UpdateOrRemoveBlacklistType) => {
      updatePayrollAdvanceSettingEachEnterprise({
        employeeId: employeeId,
        enterpriseEmployeeId: enterpriseEmployeeId,
        pasId,
        reason,
        status: 'PAS_STATE_REJECT',
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Reason Reject</Trans>
      </DialogTitle>

      <DialogContent>
        <Controller
          name='reason'
          defaultValue=''
          control={control}
          rules={{ required: t`Reason is required` }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              fullWidth
              required
              label={t`Reason`}
              error={invalid}
              helperText={error?.message}
            ></TextField>
          )}
        />
      </DialogContent>

      <DialogActions>
        <div className='flex items-center justify-between w-full'>
          <LoadingButton variant='outlined' onClick={onBack}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
            <Trans>Update</Trans>
          </LoadingButton>
        </div>
      </DialogActions>
    </>
  );
};

export default RejectPopup;
