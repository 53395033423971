import { t, Trans } from '@lingui/macro';

import { LoadingButton } from '@mui/lab';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { DigitalSign } from 'components';
import { PcTypeSignType } from 'constants/common';
import { TransRevisedStatusInit } from 'constants/transaction';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { transactionService ,queryClient} from 'services';
import { PopupController } from 'types/Common';
import { SignedDataType } from 'types/DigitalSign';
import { TransRejectReviseNoticesType } from 'types/Transaction';
import { usePermission } from 'hooks';
const ReviseNoticeType = 'revise';

type PopupProps = PopupController & {
  transactionId: number;
  transactionReviseId: number;
};
const TransReviseNoticePopup = ({ transactionId, transactionReviseId, onClose }: PopupProps) => {
  const [isOpenSign, setOpenSign] = useState(false);
  const [isOpenReject, setOpenReject] = useState(false);
  const { control, handleSubmit } = useForm();
  const { requreUserPermission, TRANSACTION_KY_LICHSUDIEUCHINH,TRANSACTION_TUCHOI_LICHSUDIEUCHINH } = usePermission();
  const { data: notice, isSuccess } = useQuery(
    ['transactionService.getTransactionReviseNotice', { id: transactionReviseId, transId: transactionId }],
    () => transactionService.getTransactionReviseNotice({ id: transactionReviseId, transId: transactionId }),
  );
  const { data: transChange } = useQuery(
    ['transactionService.getTransactionRevise', { id: transactionReviseId, transId: transactionId }],
    () => transactionService.getTransactionRevise({ id: transactionReviseId, transId: transactionId }),
  );

  const { mutate: signTransactionReviseNotice, isLoading: isLoadingSign } = useMutation(
    transactionService.signTransactionReviseNotice,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('transactionService.fetchTransactionRevises');
        queryClient.invalidateQueries('transactionService.getTransactionById');
        onClose();
      },
    },
  );

  const { mutate: rejectTransactionReviseNotice, isLoading: isLoadingReject } = useMutation(
    transactionService.rejectTransactionRevise,
    {
      onSuccess: (data) => {
        onClose();
      },
    },
  );

  const handleSignSuccess = (signedObjects: SignedDataType[]) => {
    let objId: number = 0;
    Array.from(signedObjects).forEach((o) => {
      if (o.key === ReviseNoticeType) {
        objId = o.objectId;
      }
    });
    signTransactionReviseNotice({ transId: transactionId, objectId: objId, id: transactionReviseId });
  };

  const handleClickReject = () => {
    handleSubmit((values: TransRejectReviseNoticesType) => {
      rejectTransactionReviseNotice({
        ...values,
        transId: transactionId,
        id: transactionReviseId,
      });
    })();
  };

  function renderNoticeSign() {
    if (transChange?.change?.status === TransRevisedStatusInit) {
      return (
        <>
          { requreUserPermission([TRANSACTION_TUCHOI_LICHSUDIEUCHINH]) &&
          <LoadingButton variant='outlined' loading={isLoadingReject} onClick={() => setOpenReject(true)}>
            <Trans>Reject</Trans>
          </LoadingButton>
          }
          { requreUserPermission([TRANSACTION_KY_LICHSUDIEUCHINH]) &&
          <LoadingButton variant='contained' loading={isLoadingSign} onClick={() => setOpenSign(true)}>
            <Trans>Sign</Trans>
          </LoadingButton>
          }
        </>
      );
    }
    return null;
  }

  return (
    <>
      <DialogTitle>
        <Trans>Revision Notice</Trans>
      </DialogTitle>

      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item sm={12}>
            <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
              {isSuccess && (
                <object
                  data={'data:application/pdf;base64,' + notice?.data!}
                  type='application/pdf'
                  className='w-full h-full'
                ></object>
              )}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
        {renderNoticeSign()}
      </DialogActions>

      <Dialog fullWidth maxWidth='md' open={isOpenSign} onClose={() => setOpenSign(false)}>
        <DigitalSign
          onClose={() => setOpenSign(false)}
          documents={[{ ...notice!, key: ReviseNoticeType }]}
          signType={PcTypeSignType}
          onSigned={(signedObjects) => handleSignSuccess(signedObjects)}
        />
      </Dialog>

      <Dialog fullWidth maxWidth='md' open={isOpenReject} onClose={() => setOpenReject(false)}>
        <DialogTitle>
          <Trans>Reject Transaction Revision</Trans>
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item sm={12}>
              <Controller
                name='reason'
                defaultValue=''
                control={control}
                rules={{
                  required: t`Field is required`,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    multiline
                    minRows={2}
                    label={t`Reject Reason`}
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton variant='outlined' onClick={() => setOpenReject(false)}>
            <Trans>Cancel</Trans>
          </LoadingButton>
          <LoadingButton variant='contained' onClick={handleClickReject} loading={isLoadingReject}>
            <Trans>Reject</Trans>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransReviseNoticePopup;
