import { t, Trans } from '@lingui/macro';
import { CheckCircleOutline } from '@mui/icons-material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import {
  Button,
  CardMedia,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Rating,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import { InfiniteSlider } from 'components';
import { EpayzLayout } from 'layouts';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profile';
import { epayzRoute, privateRoute } from 'routes';

const HomeEpayz = () => {
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector(profileSelector);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(privateRoute.home.path, { replace: true });
    }
  }, [navigate, isLoggedIn]);

  const [step, setStep] = useState(1);
  const [activeTab, setActiveTab] = useState('1');

  return (
    <EpayzLayout>
      <div
        style={{
          background: `url(${require('assets/epayz/slide_background.png')}) no-repeat center / cover`,
          backgroundPositionY: 'bottom 40px',
          marginBottom: 60,
        }}
      >
        <InfiniteSlider autoplay dots arrows={false}>
          <div className='flex justify-center items-center relative'>
            <img src={require('assets/epayz/slide_1_user_mid.png')} />
            <img
              src={require('assets/epayz/slide_1_cloud_1.png')}
              className='absolute left-[15%] top-[100px]'
              data-aos='zoom-in'
            />
            <img
              src={require('assets/epayz/slide_1_cloud_2.png')}
              className='absolute left-[7%] top-[460px]'
              data-aos='zoom-in'
            />
            <CardMedia
              image={require('assets/epayz/slide_1_cloud_txt.png')}
              className='absolute w-[600px] h-[400px] right-[5%] top-[10%]'
              data-aos='zoom-in'
            >
              <Typography className='font-bold text-2xl text-center text-red-700 mt-[130px] leading-10'>
                <Trans>Out of money..!</Trans>
                <br />
                <Trans>When receiving SALARY ???</Trans>
              </Typography>
            </CardMedia>
          </div>
          <div>
            <div className='flex justify-center items-center relative'>
              <img src={require('assets/epayz/slide_2_user_all.png')} />
              <CardMedia
                image={require('assets/epayz/slide_2_cloud_txt.png')}
                className='absolute w-[540px] h-[220px] left-[15%] top-[15%]'
                data-aos='zoom-in'
              >
                <Typography className='font-bold text-2xl text-center text-red-700 mt-[70px] leading-10 pr-[100px]'>
                  <Trans>Download IFZ app now to</Trans>
                  <br />
                  <Trans>get 5G speed of salary</Trans>
                </Typography>
                <div className='flex justify-center gap-6 mt-[120px] pr-[100px]'>
                  <a href='https://apps.apple.com/vn/app/epayz/id1627999955' target='_blank' rel='noreferrer'>
                    <img
                      src={require('assets/icons/btn-app-store-black.jpg')}
                      className='cursor-pointer hover:opacity-70 w-[160px] rounded-[7px]'
                    />
                  </a>
                  <img
                    src={require('assets/icons/btn-google-play-black.jpg')}
                    className='cursor-pointer hover:opacity-70 w-[160px] rounded-[7px]'
                  />
                </div>
              </CardMedia>
            </div>
          </div>
          <Container className='flex justify-center items-center'>
            <img src={require('assets/epayz/slide_3_user_left.png')} />
            <div data-aos='fade-left'>
              <Typography className='font-bold text-5xl text-red-700 leading-[80px] mb-6'>
                <Trans>PAYMENT TO ACCOUNT FREE SHOPPING</Trans>
              </Typography>
              <Button
                variant='contained'
                size='large'
                className='w-60 rounded-full bg-red-700 hover:opacity-90'
                onClick={() => {
                  document.getElementById('register-div')?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                <Trans>Register now!</Trans>
              </Button>
            </div>
          </Container>
        </InfiniteSlider>
      </div>

      <Container className='py-20'>
        <Typography className='font-bold text-5xl text-center mb-10'>
          <Trans>What we offer?</Trans>
        </Typography>
        <Grid container spacing={10}>
          {[
            {
              icon: require('assets/epayz/offer_image_1.png'),
              title: t`Brokerage Advance`,
              description: `Early payment of commissions for individual brokers working in Securities, Real Estate and Insurance. Fast approval time, get payment within 3 hours after application on app.`,
              url: epayzRoute.brokerageAdvance.path,
            },
            {
              icon: require('assets/epayz/offer_image_2.png'),
              title: `Salary Advance`,
              description: `Salary will be paid to employee's personal bank account after only a few minutes of applying on app. Ensure employee welfare and attract talents for businesses.`,
              url: epayzRoute.salaryAdvance.path,
            },
            {
              icon: require('assets/epayz/offer_image_3.png'),
              title: t`Financial Investment`,
              description: t`Much diversified and safe investment channels. Higher rate than savings at bank`,
              url: epayzRoute.partners.path,
            },
          ].map((item, index) => (
            <Grid item key={index} md={4}>
              <Paper elevation={3} className='rounded-xl p-6 opacity-70 hover:opacity-100'>
                <img src={item.icon} className='mx-auto h-[180px]' />
                <Typography variant='h3' className='text-center my-6'>
                  {item.title}
                </Typography>
                <Typography className='min-h-[120px]'>{item.description}</Typography>
                <div className='flex justify-center'>
                  <Link to={item.url}>
                    <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90'>
                      <Trans>More</Trans>
                    </Button>
                  </Link>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>

      <div className='bg-black/90'>
        <Container className='py-20 flex items-center'>
          <img src={require('assets/epayz/gift_image.png')} className='mr-[80px] w-[45%]' />
          <div className='text-white'>
            <Typography className='font-bold text-5xl mb-10'>
              <Trans>Why choosing IFZ?</Trans>
            </Typography>
            <Typography className='text-xl leading-10 mb-10'>
              <Trans>
                Since Salary comes like 2G speed and goes at 4G speed, we're here to help you upgrade your salary speed
              </Trans>
            </Typography>
            <Typography className='text-xl text-red-300 mb-3'>
              <Trans>Why we only receive salary at the end of month?</Trans>
            </Typography>
            <Typography className='font-bold text-xl text-red-300'>
              <Trans>WITH IFZ, EVERYDAY IS SALARY DAY</Trans>
            </Typography>
          </div>
        </Container>
      </div>

      <Container className='py-20 flex items-center gap-20'>
        <div className='mb-60' data-aos='fade-right'>
          <Typography className='text-xl'>
            <Trans>Everyday is</Trans>
          </Typography>
          <Typography className='font-bold text-4xl mb-6 text-indigo-400'>
            <Trans>Salary day</Trans>
          </Typography>
          <Typography>
            <Trans>Get paid early for earned working days, even</Trans> <strong>{t`not-yet-earned working day`}</strong>
            {' - '}
            <Trans>no need to wait for payday</Trans>
            <br />
            <Trans>With IFZ, you are fully financial independent for your personal consumption needs</Trans>
          </Typography>
          <Link to={epayzRoute.salaryAdvance.path}>
            <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90 mt-12'>
              <Trans>More</Trans>
            </Button>
          </Link>
        </div>
        <img src={require('assets/epayz/feature_image_1.png')} className='h-[580px] ml-10' data-aos='zoom-in-up' />
      </Container>

      <Container className='py-20 flex items-center gap-20'>
        <img src={require('assets/epayz/feature_image_2.png')} data-aos='zoom-in-up' />
        <div data-aos='fade-left'>
          <Typography className='text-xl'>
            <Trans>Get money to your account</Trans>
          </Typography>
          <Typography className='font-bold text-4xl mb-6 text-yellow-500'>
            <Trans>Fast in 5 minutes</Trans>
          </Typography>
          <Typography>
            <Trans>
              Just do a few simple steps on IFZ app, you could receive money to your personal bank account almost
              immediately
            </Trans>
          </Typography>
          <div className='flex flex-col gap-3 mt-6'>
            <div className='flex items-center gap-2'>
              <CheckCircleOutline className='text-green-300' /> <Trans>NO credit appraisal</Trans>
            </div>
            <div className='flex items-center gap-2'>
              <CheckCircleOutline className='text-green-300' /> <Trans>Transparent Fee</Trans>
            </div>
            <div className='flex items-center gap-2'>
              <CheckCircleOutline className='text-green-300' /> <Trans>100% online trading</Trans>
            </div>
          </div>
          <Link to={epayzRoute.brokerageAdvance.path}>
            <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90 mt-12'>
              <Trans>More</Trans>
            </Button>
          </Link>
        </div>
      </Container>

      <Container className='py-20 flex items-center gap-20 relative'>
        <div data-aos='fade-right'>
          <Typography className='text-xl'>
            <Trans>Follow up salary</Trans>
          </Typography>
          <Typography className='font-bold text-4xl mb-6 text-cyan-400'>
            <Trans>Simple and Convenient</Trans>
          </Typography>
          <Typography>
            <Trans>
              You can always keep track of your working days and salary amount that can be advanced at any time on IFZ
              app, so you can actively apply according to your needs
            </Trans>
          </Typography>
          <Link to={epayzRoute.salaryAdvance.path}>
            <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90 mt-12'>
              <Trans>More</Trans>
            </Button>
          </Link>
        </div>

        <img src={require('assets/epayz/feature_image_3.png')} data-aos='zoom-in-up' />
        <Paper elevation={3} className='rounded-xl p-6 absolute right-0 w-[420px]' data-aos='zoom-in-up'>
          <Typography>
            <Trans>HẠN MỨC GIAO DỊCH</Trans>
          </Typography>

          <Typography className='text-red-700 text-right pr-[80px] my-2'>10.125.000</Typography>
          <LinearProgress
            variant='determinate'
            value={70}
            classes={{
              root: 'h-3 rounded-[6px]',
              bar: 'rounded-[6px] bg-red-700',
            }}
          />
          <Typography className='text-right my-2'>15.000.000</Typography>

          <div className='flex justify-center'>
            <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90'>
              <Trans>Giao dịch ngay</Trans>
            </Button>
          </div>
        </Paper>
      </Container>

      <div className='bg-black/90'>
        <Container className='py-20'>
          <Typography className='font-bold text-5xl text-center text-white mb-10'>
            <Trans>What our user said</Trans>
          </Typography>
          <Grid container spacing={5}>
            {[
              {
                star: 5,
                label: 'Perfect',
                comment: 'The application is very convenient, easy to use and receive money fast',
                author: 'Phương Linh Đặng',
              },
              {
                star: 5,
                label: 'Easy to use',
                comment:
                  'My transaction was confirmed after only 5 second and my account received money immediately, it only took about 3 minutes',
                author: 'Nam Nguyen',
              },
              {
                star: 5,
                label: 'Excellent',
                comment:
                  "Money comes to my account very quickly, now I don't have to wait until the payday anymore. Thank IFZ",
                author: 'Hoang Long Nguyen',
              },
              {
                star: 5,
                label: 'Amazing features',
                comment:
                  'I tried it because my company has a new welfare policy, quite surprised because this app is very simple and I got money very quick',
                author: 'Trang Phạm',
              },
            ].map((item, index) => (
              <Grid item key={index} md={3}>
                <Paper className='bg-neutral-800 text-white p-6' data-aos='fade-up' data-aos-delay={index * 200}>
                  <Rating value={item.star} readOnly classes={{ icon: 'text-pink-500' }} />
                  <Typography className='text-lg my-4'>{item.label}</Typography>
                  <Typography className='text-white/90 mb-10 h-[100px]'>{item.comment}</Typography>
                  <Typography className='text-right'>{item.author}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>

      <div className='bg-black/90'>
        <Container className='py-20 flex'>
          <div>
            <Typography className='font-bold text-5xl text-white mb-10'>
              <Trans>How our app works</Trans>
            </Typography>
            <Typography className='text-white/80 mb-16'>
              <Trans>
                We're here to work with you in order to estimate your earned salary and allow users to even apply for
                unearned salary by doing some simple steps as follows:
              </Trans>
            </Typography>

            <Timeline position='right' className='text-white'>
              {[
                {
                  step: 1,
                  content: t`Download IFZ app on Google Play or App Store. Then login with your registered username and password`,
                  image: require('assets/epayz/step_image_1.png'),
                },
                {
                  step: 2,
                  content: t`Implement eKYC to verify your account in the first time`,
                  image: require('assets/epayz/step_image_2.png'),
                },
                {
                  step: 3,
                  content: t`Verify your receiving bank account and apply for salary advance on IFZ app`,
                  image: require('assets/epayz/step_image_3.png'),
                },
              ].map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent className='hidden' />
                  <TimelineSeparator>
                    <TimelineDot
                      className={classNames(
                        'flex justify-center items-center w-10 h-10 cursor-pointer hover:bg-red-400',
                        { 'bg-red-700 text-white': step === item.step, 'text-black': step !== item.step },
                      )}
                      onClick={() => setStep(item.step)}
                    >
                      {item.step}
                    </TimelineDot>
                    {index < 2 && <TimelineConnector className='h-[100px]' />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography className='min-h-[48px] flex items-center'>{item.content}</Typography>
                    {index === 0 && (
                      <div className='flex gap-4 my-6'>
                        <a href='https://apps.apple.com/vn/app/epayz/id1627999955' target='_blank' rel='noreferrer'>
                          <img
                            src={require('assets/icons/btn-app-store-white.jpg')}
                            className='cursor-pointer hover:opacity-70 w-[160px] rounded-[7px]'
                          />
                        </a>
                        <img
                          src={require('assets/icons/btn-google-play-white.jpg')}
                          className='cursor-pointer hover:opacity-70 w-[160px] rounded-[7px]'
                        />
                      </div>
                    )}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </div>
          <img src={require(`assets/epayz/step_image_${step}.png`)} className='h-[660px] mx-[100px]' />
        </Container>
      </div>

      <div
        id='register-div'
        style={{ background: `url(${require('assets/epayz/form_image_1.png')}) no-repeat left bottom` }}
      >
        <Container className='flex justify-between gap-20'>
          <div className='pt-40'>
            <Typography className='font-bold text-5xl text-center mb-10'>
              <Trans>REGISTER FOR CONSULTANT</Trans>
            </Typography>
            <img src={require('assets/epayz/form_image_2.png')} className='mx-[120px]' />
          </div>
          <Paper className='flex-1 bg-black/5 rounded-xl mt-20 mb-10 p-6 max-w-[480px]'>
            <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
              <Tab label={t`Person`} value='1' />
              <Tab label={t`Business`} value='2' />
            </Tabs>
            <div className='mt-4'>
              <Typography>
                <Trans>Leave your information here, we will contact you as soon as possible</Trans>
              </Typography>
              <div className='flex flex-col gap-6 mt-6'>
                {activeTab === '2' && <TextField label='Company' />}
                <TextField label={t`Full name`} />
                <TextField label={t`Email`} />
                <TextField label={t`Phone`} />
                <div className='flex justify-center'>
                  <Button size='large' className='w-40 rounded-full bg-black text-white hover:opacity-90'>
                    <Trans>Send</Trans>
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </Container>
      </div>
    </EpayzLayout>
  );
};

export default HomeEpayz;
