import { t, Trans } from '@lingui/macro';
import { SearchOutlined } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/lab';
import {
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { TableEmpty } from 'components';
import SelectPageSize from 'components/SelectPageSize';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { enterpriseService } from 'services';
import { PaymentSalaryParamsType } from 'types/Enterprise';
import { formatNumber } from 'utils/common';

const PaymentSchedule = () => {
  const { control, handleSubmit } = useForm();
  const [dataSearch, onSearchChange] = useSearch();
  const [searchParams, setSearchParams] = useState({});

  const { data } = useQuery(
    ['transactionService.fetchEnterprisePaymentSalaryAndFeeDetails', dataSearch],
    () => enterpriseService.fetchEnterprisePaymentSalaryAndFeeDetails(dataSearch),
    { keepPreviousData: true },
  );
  const { data: items = [], paginate } = data ?? {};

  useEffect(() => {
    onSearchChange({ ...searchParams });
  }, [onSearchChange, searchParams]);

  const handleClickSearch = () => {
    handleSubmit((values: PaymentSalaryParamsType) => {
      type ValuesType = PaymentSalaryParamsType & {
        dateFrom: DateTime;
        dateTo: DateTime;
      };
      const { dateFrom, dateTo, ...others } = values as ValuesType;
      setSearchParams({
        ...others,
        dateFrom: dateFrom ? dateFrom.toISO() : '',
        dateTo: dateTo ? dateTo.toISO() : '',
      });
    })();
  };
  return (
    <div className='border rounded-lg mt-2 p-4'>
      <Grid container columnSpacing={2}>
        <Grid item sm={6}>
          <div className='mt-4 space-x-4 flex items-center'>
            <Controller
              name='dateFrom'
              defaultValue=''
              control={control}
              rules={{
                validate: { isValid: (value: DateTime) => !value || value.isValid },
              }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={DateTime.now().plus({ week: -1 })}
                  onChange={onChange}
                  renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                  label={t`From`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
            <Controller
              name='dateTo'
              defaultValue=''
              control={control}
              rules={{
                validate: { isValid: (value: DateTime) => !value || value.isValid },
              }}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
                <DesktopDatePicker
                  value={DateTime.now()}
                  onChange={onChange}
                  renderInput={(props) => <TextField fullWidth {...props} error={invalid} />}
                  label={t`To`}
                  inputFormat='dd/MM/yyyy'
                />
              )}
            />
            <IconButton
              color='primary'
              onClick={() => {
                handleClickSearch();
              }}
            >
              <SearchOutlined fontSize='medium' />
            </IconButton>
          </div>
        </Grid>
        <Grid item sm={6} className='flex space-x-4'>
          <div className='border rounded-lg p-4 flex flex-col justify-center items-center'>
            <Typography variant='subtitle2'>
              <Trans>Total salary payable for the week (VND)</Trans>
            </Typography>
            <Typography>50</Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item sm={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableCell>
                  <Trans>No</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Date</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Type</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Object</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Payable Amount (VND)</Trans>
                </TableCell>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className='text-center'>{index + 1}</TableCell>
                    <TableCell className='text-center'>
                      {item.paymentDate ? DateTime.fromISO(item.paymentDate).toFormat('dd/MM/yyyy') : ''}
                    </TableCell>
                    <TableCell>{item.type === 'SALARY' ? t`Salary` : t`Service Fee`}</TableCell>
                    <TableCell>{item.object === 'FULL' ? t`Employee` : item.pcName}</TableCell>
                    <TableCell>{formatNumber(item.amount)}</TableCell>
                  </TableRow>
                ))}
                <TableEmpty data={items} />
              </TableBody>
            </Table>
          </TableContainer>
          <div className='flex justify-between'>
            <SelectPageSize
              size={paginate?.size ?? 10}
              onChange={(size) => {
                onSearchChange({ ...dataSearch, size: size });
              }}
            />
            <Pagination
              page={paginate?.page ?? 1}
              count={paginate?.totalPage}
              onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentSchedule;
