import { t, Trans } from '@lingui/macro';

import { Button, Grid, TextField, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { EnterpriseType } from 'types/Enterprise';
import { usePermission } from 'hooks';
const EnterpriseInfo = ({ enterprise }: { enterprise?: EnterpriseType }) => {
  const {  requreUserPermission , ENTERPRISE_THONGTINTAICHINH } = usePermission();
  return (
    <div>
      <div className='flex items-center justify-between mb-4'>
        <Typography variant='h4'>
          <Trans>Enterprise Information</Trans>
        </Typography>
        {requreUserPermission([ENTERPRISE_THONGTINTAICHINH]) && <Link to={privateRoute.enterpriseSetupFinance.url?.({ id: enterprise?.id })!}>
          <Button variant='contained'>
            <Trans>Financial Information</Trans>
          </Button>
        </Link>}
      </div>

      <Grid container columnSpacing={4} rowSpacing={3}>
        <Grid item sm={3}>
          <TextField
            fullWidth
            label={t`Enterprise Name`}
            value={enterprise?.name ?? ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField fullWidth label={t`Tax Code`} value={enterprise?.taxCode ?? ''} InputProps={{ readOnly: true }} />
        </Grid>
      </Grid>
    </div>
  );
};

export default EnterpriseInfo;
