import { LoadingButton } from '@mui/lab';
import { Trans } from '@lingui/macro';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog
} from '@mui/material';
import { useSearch } from 'hooks';
import { useQuery } from 'react-query';
import enterpriseSetup from 'services/enterpriseSetup';
import { PopupController } from 'types/Common';
import { TableEmpty } from 'components';
import { useState } from 'react';
import ViewFileUploadPaymentPopup from './ViewFileUploadPaymentPopUp';
import SelectPageSize from 'components/SelectPageSize';

type PopupProps = PopupController & {
  feePaymentId: number;
  enterpriseId: string;
};

const PaymentSlipPopup = ({ feePaymentId, enterpriseId, onClose }: PopupProps) => {
  const [isOpen, setOpen] = useState(false);
  const [dataSearch, onSearchChange] = useSearch();
  const [paymentDocId, setPaymentDocId] = useState(0);
  const [isPdf, setIsPdf] = useState(false);
  const { data } = useQuery(['enterpriseSetup.fetchEnterpriseFeePaymentDocs', dataSearch], () =>
    enterpriseSetup.fetchEnterpriseFeePaymentDocs({ enterpriseId, feePaymentId, ...dataSearch }),
  );

  const { data: items = [], paginate } = data ?? {};

  return (
    <>
      <DialogTitle>
        <Trans>PAYMENT SLIP</Trans>
      </DialogTitle>

      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans>STT</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Payment Slip Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans>Uploader</Trans>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, idx) => (
                <TableRow key={item.id}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{item.object.originalName}</TableCell>
                  <TableCell>{item.createdUser.fullname}</TableCell>
                  <TableCell>
                    <Button size='small' color='info' onClick={() => { setOpen(true); setPaymentDocId(item.id); setIsPdf(item.object.originalName.includes(".pdf") ? true : false) }}>
                      <Trans>View</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableEmpty data={items} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-between'>
          <SelectPageSize
            size={paginate?.size ?? 10}
            onChange={(size) => {
              onSearchChange({ ...dataSearch, size: size });
            }}
          />
          <Pagination
            page={paginate?.page ?? 1}
            count={paginate?.totalPage}
            onChange={(event, value) => onSearchChange({ ...dataSearch, page: value })}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          <Trans>Cancel</Trans>
        </LoadingButton>
      </DialogActions>
      <Dialog fullWidth maxWidth='lg' open={isOpen}>
        <ViewFileUploadPaymentPopup isPdf={isPdf} feePaymentDocId={paymentDocId} enterpriseId={enterpriseId} onClose={() => setOpen(false)}></ViewFileUploadPaymentPopup>
      </Dialog>
    </>
  );
};

export default PaymentSlipPopup;
