import { Trans } from '@lingui/macro';
import { Container, Typography, Button, Dialog } from '@mui/material';
import { EpayzLayout } from 'layouts';
import { useActiveLocale } from 'hooks/useActiveLocale';
import { Fragment, useState } from 'react';
import ContactSupportPopup from './ContactSupportPopup';

const BrokerageAdvance = () => {
  const userLocale = useActiveLocale();
  const [cauHoi1, setCauHoi1] = useState(true);
  const [cauHoi2, setCauHoi2] = useState(false);
  const [cauHoi3, setCauHoi3] = useState(false);
  const [cauHoi4, setCauHoi4] = useState(false);
  const [cauHoi5, setCauHoi5] = useState(false);
  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [bankCode, setBankCode] = useState('');
  function submitContact() {
    console.log("ok");
    setOpenSelectBank(true)
  }
  return (
    <EpayzLayout>
      <div data-aos="fade-up" style={{ backgroundImage: `url(/backgroundmoigioi.png)`, position: 'relative', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Container className='py-20' style={{ display: 'flex', position: 'relative' }}>
          <div style={{ width: '20%' }}>
            <img style={{ width: "100%" }} src={require('assets/epayz/Picture1_remove.png')} />
          </div>
          <div style={{ width: '80%', marginLeft: '30px', alignSelf: 'center' }}>
            <div style={{ marginBottom: '10px', fontWeight: 600 }}><Trans>You are a broker in Real Estate? Insurance?</Trans></div>
            <div style={{ marginBottom: '10px', fontWeight: 600 }}><Trans>Securities?</Trans></div>
            <div style={{ marginBottom: '25px', fontWeight: 600, display: 'flex' }}>
              <div style={{ width: '50%' }}><Trans>You already reach the target but have not received<br /> your commission yet?</Trans></div>
              <div style={{ width: '40%', position: 'relative' }}><img style={{ width: "235px", position: 'absolute', left: '0px', bottom: '0px' }} src={require('assets/epayz/profile1.png')} /></div>
            </div>
            <div style={{ fontSize: '30px', color: '#C00000', fontWeight: 600, display: 'flex' }}>
              <div style={{ width: '80%' }}><Trans>EPZ is proud to be the first company to offer<br /> brokerage advance solution for individuals</Trans></div>
              <div style={{ width: '15%', position: 'relative' }}><img style={{ width: "235px", position: 'absolute', left: '0px', bottom: '0px' }} src={require('assets/epayz/profile2.png')} /></div>
            </div>
            <div style={{ marginTop: 15, display: 'flex' }}>
              <div style={{ width: '60%' }}><Button onClick={submitContact} variant="contained" color="primary" style={{ backgroundColor: "black !important", color: 'white', borderRadius: '20px' }}><Trans>Contact Us</Trans></Button></div>
              <div style={{ width: '25%', position: 'relative' }}><img style={{ width: "235px", position: 'absolute', left: '0px', top: '0px' }} src={require('assets/epayz/profile3.png')} /></div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container className='py-20' style={{ position: 'relative' }}>
          <div style={{ fontSize: '25px', marginBottom: '20px' }}><Trans>New solutions to help brokers</Trans></div>
          <div style={{ position: 'relative', height: '100px' }}><div style={{ fontSize: '45px', fontWeight: 600, marginBottom: '25px', position: 'absolute', zIndex: 10 }}><Trans>get Brokerage Commission before maturity</Trans></div></div>
          <div style={{ marginBottom: '50px', fontSize: '15px' }}><Trans>No more waiting, EPZ gives you the solution to receive your commission<br /> right after completing your projects / orders as your target.</Trans></div>
          <div><Trans>Download EPZ now</Trans></div>
          <div style={{ display: "flex", marginTop: "15px" }}>
            <a style={{ marginRight: "20px" }} href='https://apps.apple.com/vn/app/epayz/id1627999955' target='_blank' rel='noreferrer'>
              <img
                src={require('assets/icons/btn-app-store-white.jpg')}
                className='cursor-pointer hover:opacity-70 ' style={{ width: "100px" }}
              />
            </a>
            <img
              src={require('assets/icons/btn-google-play-white.jpg')}
              className='cursor-pointer hover:opacity-70' style={{ width: "100px" }}
            />
          </div>
          <div style={{ height: '50px' }}></div>
          <img style={{ width: "50%", position: 'absolute', bottom: '0px', right: '0px', zIndex: 1 }} src={require('assets/epayz/11671767_13131.jpg')} />
        </Container>
        <div style={{ width: '100%', height: '100px', backgroundColor: '#959BA3', marginTop: '30px' }}></div>
      </div>
      <div style={{ backgroundColor: '#E7E6E6',position:'relative',zIndex:30 }}>
        <Container className='py-20' >
          <div style={{ fontSize: '40px', fontWeight: 600, textAlign: 'center', marginBottom: '30px' }}><Trans>Working steps</Trans></div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '70%' }}>
              <div style={{ display: 'flex', height: '270px', marginRight: '30px', justifyContent: 'space-around' }}>
                <div style={{ width: '28%', backgroundColor: 'white', borderRadius: '15px' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '180px', paddingBottom: '20px', alignItems: 'flex-end' }}><img style={{ width: "160px", height: '107px' }} src={require('assets/epayz/18407463_5995729.jpg')} /></div>
                  <div style={{ fontSize: '15px', textAlign: 'center' }}><Trans>Company updates brokerage<br /> commission on platform</Trans></div>
                </div>
                {/* <div style={{width:'1%'}}></div> */}
                <div style={{ width: '50px', alignSelf: 'center' }}>
                  <img style={{ width: "50px", height: '50px', borderRadius: '50px' }} src={require('assets/epayz/arrowright.png')} />
                </div>
                {/* <div style={{width:'1%'}}></div> */}
                <div style={{ width: '28%', backgroundColor: 'white', borderRadius: '15px' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '180px' }}><img style={{ width: "160px", height: '160px' }} src={require('assets/epayz/19245710_6101100.jpg')} /></div>
                  <div style={{ fontSize: '15px', textAlign: 'center' }}><Trans>Login and select the<br /> brokerage commission you<br /> want to advance</Trans></div>
                </div>
                {/* <div style={{width:'1%'}}></div> */}
                <div style={{ width: '50px', alignSelf: 'center' }}>
                  <img style={{ width: "50px", height: '50px', borderRadius: '50px' }} src={require('assets/epayz/arrowright.png')} />
                </div>
                {/* <div style={{width:'1%'}}></div> */}
                <div style={{ width: '28%', backgroundColor: 'white', borderRadius: '15px' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '180px' }}><img style={{ width: "160px", height: '160px' }} src={require('assets/epayz/13416089_5243321.jpg')} /></div>
                  <div style={{ fontSize: '15px', textAlign: 'center' }}><Trans>Make personal digital sign to<br /> confirm the advance<br /> transaction on platform</Trans></div>
                </div>
              </div>
              <div style={{ display: 'flex', height: '270px', marginTop: '30px', marginRight: '30px', justifyContent: 'space-around' }}>
                <div style={{ width: '50px' }}></div>
                <div style={{ width: '28%', backgroundColor: 'white', borderRadius: '15px' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '180px', paddingBottom: '20px', alignItems: 'flex-end' }}><img style={{ width: "160px", height: '107px' }} src={require('assets/epayz/20134681_6242692.jpg')} /></div>
                  <div style={{ fontSize: '15px', textAlign: 'center' }}><Trans>Company pays the commission<br /> to employees at maturity, EPZ<br /> auto collects money back</Trans></div>
                </div>
                <div style={{ width: '50px', alignSelf: 'center' }}>
                  <img style={{ width: "50px", height: '50px', borderRadius: '50px', transform: 'rotate(180deg)' }} src={require('assets/epayz/arrowright.png')} />
                </div>
                <div style={{ width: '28%', backgroundColor: 'white', borderRadius: '15px' }}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '180px' }}><img style={{ width: "160px", height: '160px' }} src={require('assets/epayz/13283987_5209022.jpg')} /></div>
                  <div style={{ fontSize: '15px', textAlign: 'center' }}><Trans>Receive money to personal<br /> bank account within 3 working<br /> hours</Trans></div>
                </div>
                <div style={{ width: '50px' }}>
                  <img style={{ width: "50px", height: '50px', borderRadius: '50px', transform: 'rotate(90deg)' }} src={require('assets/epayz/arrowright.png')} />
                </div>
              </div>
            </div>
            <div style={{ width: '28%' }}>
              <div style={{ padding: '25px 40px', color: 'white', backgroundColor: '#DF7F7F', borderRadius: '0px 50px 0px 50px', height: '270px' }}>
                <div style={{ fontSize: '27px', fontWeight: 600, marginBottom: '15px' }}>{userLocale === 'vi-VN' ? <Trans>Doanh nghiệp</Trans> : <Trans>Company</Trans>}</div>
                <ul style={{ listStyleType: 'disc', marginLeft: '20px', lineHeight: '26px', fontSize: '13px' }}>
                  <li><Trans>Signing Coordination Agreement with EPZ.</Trans></li>
                  <li><Trans>Updating the information of brokers and commissions by orders/projects.</Trans></li>
                  <li><Trans>Receiving the notice and controlling the advance transaction of brokers.</Trans></li>
                  <li><Trans>Fast working on website.</Trans></li>
                </ul>
              </div>
              <div style={{ padding: '25px 40px', color: 'white', backgroundColor: '#DF7F7F', borderRadius: '50px 0px 50px 0px', marginTop: '30px', height: '270px' }}>
                <div style={{ fontSize: '27px', fontWeight: 600, marginBottom: '15px' }}>{userLocale === 'vi-VN' ? <Trans>Nhân viên môi giới</Trans> : <Trans>Brokers</Trans>}</div>
                <ul style={{ listStyleType: 'disc', marginLeft: '20px', lineHeight: '26px', fontSize: '13px' }}>
                  <li><Trans>Signing Master Contract with EPZ.</Trans></li>
                  <li><Trans>Register the personal digital signature (EPZ support register new account with VNPT).</Trans></li>
                  <li><Trans>Confirm the account receiving commission.</Trans></li>
                  <li><Trans>Easy access on app / website.</Trans></li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: 'white', display: 'flex', position: 'relative', height: '804px' }}>
        <Container className='py-20'>
          <div style={{ fontSize: '35px', fontWeight: 600 }}><Trans>What makes EPZ different?</Trans></div>
          <div style={{ display: 'flex', width: '60%', marginTop: '40px' }}>
            <div style={{ width: '10%', fontSize: '30px', fontWeight: 600, color: '#DF7F7F' }}>01</div>
            <div style={{ width: '90%', fontSize: '30px', fontWeight: 600, color: '#C00000' }}><Trans>Digital Contract</Trans></div>
          </div>
          <div style={{ display: 'flex', width: '60%', marginTop: '20px' }}>
            <div style={{ width: '10%', fontSize: '30px', fontWeight: 600, color: '#DF7F7F' }}></div>
            <div style={{ width: '90%', color: '#908C8C' }}><Trans>No more hardcopies and delivery time, all contracts on EPZ platform are converted to sign<br /> by personal digital signature (Smart CA).</Trans></div>
          </div>
          <div style={{ display: 'flex', width: '60%', marginTop: '40px' }}>
            <div style={{ width: '10%', fontSize: '30px', fontWeight: 600, color: '#DF7F7F' }}>02</div>
            <div style={{ width: '90%', fontSize: '30px', fontWeight: 600, color: '#C00000' }}><Trans>Simple Process</Trans></div>
          </div>
          <div style={{ display: 'flex', width: '60%', marginTop: '20px' }}>
            <div style={{ width: '10%', fontSize: '30px', fontWeight: 600, color: '#DF7F7F' }}></div>
            <div style={{ width: '90%', color: '#908C8C' }}>
              <div><Trans>NO personal assessment</Trans></div>
              <div><Trans>NO CIC assessment and record</Trans></div>
              <div><Trans>NO collateral / personal guarantee</Trans></div>
            </div>
          </div>
          <div style={{ display: 'flex', width: '60%', marginTop: '40px' }}>
            <div style={{ width: '10%', fontSize: '30px', fontWeight: 600, color: '#DF7F7F' }}>03</div>
            <div style={{ width: '90%', fontSize: '30px', fontWeight: 600, color: '#C00000' }}><Trans>Immediate Payment</Trans></div>
          </div>
          <div style={{ display: 'flex', width: '60%', marginTop: '20px' }}>
            <div style={{ width: '10%', fontSize: '30px', fontWeight: 600, color: '#DF7F7F' }}></div>
            <div style={{ width: '90%', color: '#908C8C' }}>
              <Trans>Paying directly to the personal bank account within 24 working hours and the payment has<br /> been up to 90% of project / order’s commission.</Trans>
            </div>
          </div>
        </Container>
        <div style={{ width: '40%', backgroundImage: `url(/background1.png)`, backgroundSize: 'cover', position: 'absolute', right: '0px', bottom: '0px' }}>
          <Container className='py-20'>
            <img style={{ width: "100%" }} src={require('assets/epayz/7066796_3517064-PhotoRoom.png-PhotoRoom.png')} />
          </Container>
        </div>
      </div>
      <div style={{ background: '#18181C' }}>
        <Container className='py-20'>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%', display: 'flex' }}>
              <div style={{ width: "10px", background: 'white', borderRadius: '2px', marginRight: '15px' }}></div>
              <div style={{ width: '90%', background: 'white', padding: '20px', borderRadius: '5px' }}>
                <div style={{ fontSize: '25px' }}><Trans>BENEFITS OF <b>COMPANY</b></Trans></div>
                <ul style={{ listStyleType: 'disc', marginLeft: '20px', fontSize: '15px', lineHeight: '25px', marginTop: '15px' }}>
                  <li><Trans>Retaining your brokers in competitive hiring times.</Trans></li>
                  <li><Trans>Reducing the effort of handling advance request of HR & Accounting Department.</Trans></li>
                  <li><Trans>Improving the working productivity and target of Broker Department.</Trans></li>
                  <li><Trans>Enhancing the business’s position and reputation.</Trans></li>
                </ul>
              </div>
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
              <img style={{ width: "200px" }} src={require('assets/epayz/Picture1-removebg-preview.png')} />
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: '30px' }}>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
              <img style={{ width: "310px" }} src={require('assets/epayz/9176945_6685-removebg-preview.png')} />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}>
              <div style={{ width: '90%', background: '#767171', padding: '20px', borderRadius: '5px', color: 'white' }}>
                <div style={{ fontSize: '25px', textAlign: 'end' }}><Trans>BENEFITS OF <b>BROKERS</b></Trans></div>
                <ul style={{ listStyleType: 'disc', marginLeft: '20px', fontSize: '15px', lineHeight: '25px', marginTop: '15px' }}>
                  <li><Trans>High limit and Competitive Discount Rate.</Trans></li>
                  <li><Trans>Receiving the commission actively right after closing the projects / orders.</Trans></li>
                  <li><Trans>Quick disbursement in 24 working hours & 100% digital access.</Trans></li>
                  <li><Trans>Applying for many brokerage service such as real estate, insurance, securities, …</Trans></li>
                </ul>
              </div>
              <div style={{ width: "10px", background: '#767171', borderRadius: '2px', marginLeft: '15px' }}></div>
            </div>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: 'white', position: 'relative' }}>
        <Container className='py-20'>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '30%' }}><img style={{ width: '100%' }} src={require('assets/epayz/30576696_7720441.jpg')} /></div>
            <div style={{ width: '70%' }}>
              <div style={{ fontSize: '30px', fontWeight: 600, marginBottom: '30px' }}><Trans>FAQs</Trans></div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(true); setCauHoi2(false); setCauHoi3(false); setCauHoi4(false); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi1 === true ? '#C00000' : 'black' }}>{cauHoi1 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>What is EPZ’s brokerage advance service?</Trans></div>
                {cauHoi1 === true ? <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>EPZ provides a commission advance service for brokers who are working in the fields of real estate, insurance, securities … able to get brokerage commission right after finalizing a deal without having to wait until the payment date of brokerage company.</Trans></div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(true); setCauHoi3(false); setCauHoi4(false); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi2 === true ? '#C00000' : 'black' }}>{cauHoi2 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>Do I have to pay any fees to get early commission?</Trans></div>
                {cauHoi2 === true ? <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>Yes. We will calculate the discounted value based on the remaining days until commssion payment’s due date and the fee for the secured transaction registration. The discount rate and transaction cost will be determined upon the assessment and rating of brokerage company by EPZ.</Trans></div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(false); setCauHoi3(true); setCauHoi4(false); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi3 === true ? '#C00000' : 'black' }}>{cauHoi3 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>Which information do I need to provide?</Trans></div>
                {cauHoi3 === true ?
                  <div>
                    {/* <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>After signing cooperation with EPZ, your company only need to support EPZ to:</Trans></div> */}
                    <div style={{ fontSize: '15px' }}><Trans>After registering transaction account on EPZ, you need to do electronic identification eKYC and confirm salary / commission bank account with working brokerage company.</Trans></div>
                    <div style={{ fontSize: '15px' }}><Trans>In addition, to apply for brokerage advance transaction on EPZ, you need to register a personal digital signature (now, EPZ supports you to register with VNPT) for signing contract and transaction 100%.</Trans></div>
                  </div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(false); setCauHoi3(false); setCauHoi4(true); setCauHoi5(false); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi4 === true ? '#C00000' : 'black' }}>{cauHoi4 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>What does company do with advanced brokerage fees?</Trans></div>
                {cauHoi4 === true ? <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>At payment date, company can choose 1 of 2 options: (1) pay 100% of commission to broker’s bank account, the designated bank will collect advanced amount to EPZ automatically; (2) pay the advanced amount to EPZ and remaining amount to brokers.</Trans></div> : <div></div>}
              </div>
              <div style={{ margin: '20px' }}>
                <div onClick={() => { setCauHoi1(false); setCauHoi2(false); setCauHoi3(false); setCauHoi4(false); setCauHoi5(true); }} style={{ fontSize: '20px', fontWeight: 600, cursor: 'pointer', color: cauHoi5 === true ? '#C00000' : 'black' }}>{cauHoi5 === false ? <span style={{ paddingRight: '15px' }}>+</span> : <span style={{ paddingRight: '15px' }}>-</span>}<Trans>Can the company control the brokerage advance transaction?</Trans></div>
                {cauHoi5 === true ?
                  <div>
                    <div style={{ fontSize: '15px', margin: '10px 0px' }}><Trans>Yes. After each transaction, EPZ always sends the notice to Company on platform. In use, Company can totally check the brokerage advance transaction on platform via account granted free by EPZ after cooperation.</Trans></div>
                  </div> : <div></div>}
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Dialog fullWidth maxWidth='xl' open={openSelectBank} onClose={() => setOpenSelectBank(false)}>
        <ContactSupportPopup
          onNext={(code: string) => {
            setBankCode(code);
            setOpenExport(true);
          }}
          onClose={() => {
            setOpenSelectBank(false);
          }}
        />
      </Dialog>
    </EpayzLayout>
  );
};

export default BrokerageAdvance;
