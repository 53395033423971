import { t, Trans } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import {
  TextField,
  MenuItem,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { employeeService, queryClient } from 'services';
import { PopupController } from 'types/Common';
import { ContractCreateType, ContractType } from 'types/EmployeeContract';

type PopupProps = PopupController & {
  contract: ContractType;
  employeeId: string;
};

const ContractCommitCreatePopup = ({ contract, employeeId, onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const [enterpriseId, setEnterpriseId] = useState<number | string>('');
  const [isCreate, setIsCreate] = useState(false);

  const { data } = useQuery(
    ['employeeService.fetchEmployeeWorkings', { _id: employeeId }],
    () => employeeService.fetchEmployeeWorkings({ _id: employeeId, size: 100 }),
    {
      onSuccess: ({ data: items }) => {
        if (items[0]) {
          setEnterpriseId(items[0].enterprise.id);
        }
      },
    },
  );
  const { data: enterprises = [] } = data ?? {};

  const { data: dataFile, isSuccess: isSuccessLoadFile } = useQuery(
    ['employeeService.generateEmployeeContract', { id: employeeId, type: contract?.type, enterpriseId: enterpriseId }],
    () =>
      employeeService.generateEmployeeContract({ id: employeeId, type: contract?.type, enterpriseId: enterpriseId }),
    { enabled: isCreate },
  );

  const { mutate: createContract, isLoading: isLoadingCreate } = useMutation(employeeService.createEmployeeContract, {
    onSuccess: () => {
      enqueueSnackbar(t`Successful`);
      queryClient.invalidateQueries('employeeService.getEmployeeLatest');
      queryClient.invalidateQueries('employeeService.fetchEmployeeContract');
      onClose();
    },
  });

  const handleClickRequest = () => {
    handleSubmit((values: ContractCreateType) => {
      createContract({
        ...values,
        _id: employeeId,
        enterpriseId: enterpriseId!,
        type: contract.type!,
      });
    })();
  };

  return (
    <>
      <DialogTitle>
        <Trans>Commitment</Trans>
      </DialogTitle>

      <DialogContent>
        <Stepper activeStep={0}>
          <Step>
            <StepLabel>
              <Trans>Creation</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>View</Trans>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <Trans>Completion</Trans>
            </StepLabel>
          </Step>
        </Stepper>

        {!isCreate && (
          <div>
            <Typography align='center' className='mb-2'>
              <Trans>Please select the Enterprise before pressing "Create" button</Trans>
            </Typography>
            <Grid container columnSpacing={4} rowSpacing={3} className='mt-2'>
              <Grid className='text-right mt-1' item sm={4}>
                <Trans> Select Enterprise</Trans>
              </Grid>
              <Grid item sm={8}>
                <TextField
                  select
                  value={enterpriseId}
                  fullWidth
                  onChange={(event) => setEnterpriseId(event.target.value)}
                >
                  {enterprises.map((item) => (
                    <MenuItem key={item.id} value={item.enterprise.id}>
                      {item.enterprise.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>
        )}
        {isCreate && (
          <div className='border' style={{ height: 'calc(-180px + 80vh)' }}>
            {isSuccessLoadFile && (
              <object
                data={'data:application/pdf;base64,' + dataFile?.data!}
                type='application/pdf'
                className='w-full h-full'
              ></object>
            )}
          </div>
        )}
      </DialogContent>

      <DialogActions className='relative'>
        <LoadingButton variant='outlined' onClick={onClose}>
          <Trans>Close</Trans>
        </LoadingButton>

        {isCreate && (
          <Controller
            name='digital'
            defaultValue={true}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl className='flex-row justify-center w-full'>
                {[
                  { label: t`Digital Commitment`, value: true },
                  { label: t`Manual Commitment`, value: false },
                ].map((choice, index) => (
                  <FormControlLabel
                    key={index}
                    label={choice.label}
                    control={
                      <Checkbox
                        color='info'
                        checked={choice.value === value}
                        onChange={(event, checked) => {
                          if (checked) onChange({ target: { value: choice.value } });
                        }}
                      />
                    }
                  />
                ))}
              </FormControl>
            )}
          />
        )}

        {isCreate ? (
          <LoadingButton variant='contained' className='w-28' loading={isLoadingCreate} onClick={handleClickRequest}>
            <Trans>Request</Trans>
          </LoadingButton>
        ) : (
          <LoadingButton variant='contained' className='w-28' onClick={() => setIsCreate(true)}>
            <Trans>Create</Trans>
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export default ContractCommitCreatePopup;
